import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import { useToasts } from "react-toast-notifications";
import { postMethod, getMethod } from "../../services/httpServices";
import { MOBILE_ONLY_COUNTRY } from "../../appConfig";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { checkName } from "services/util";

const codes = [
  23225, 23230, 23231, 23232, 23233, 23234, 23244, 23275, 23276, 23277, 23278,
  23279, 23280, 23288, 23299,
];

const initialValue = {
  firstName: "",
  lastName: "",
  mobileCode: "",
  phone: "",
  mobWithoutCC: "",
  email: "",
  userRole: "",
  gender: "",
};

const User = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const { addToast } = useToasts();

  React.useEffect(() => {
    fetchRoles();
  }, []);

  React.useEffect(() => {
    if (roles && roles.length) {
      setValues({ ...values });
    }
  }, [roles]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const spaceRegex = /^\s*$/;
    if (!spaceRegex.test(value) || !value.length) {
      if (name === "phone") {
        if (checkMobValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name == "firstName" || name == "lastName") {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values,
        phone: value,
        mobileCode: data.dialCode,
        mobWithoutCC: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const closeModal = () => {
    props.closeModal();
  };

  const validate = (inputs) => {
    let errors = {};
    let emailRegEx = /\S+@\S+\.\S+/;
    if (!inputs.firstName) {
      errors.firstName = "First Name is Required";
    }
    if (!inputs.lastName) {
      errors.lastName = "Last Name is Required";
    }
    if (!inputs.email) {
      errors.email = "Email is Required";
    } else if (!emailRegEx.test(inputs.email)) {
      errors.email = "Enter valid email";
    }
    if (!inputs.mobileCode) {
      errors.mobileCode = "Please select mobile code";
    }
    if (!inputs.phone) {
      errors.phone = "Phone Number is Required";
    } else if (!inputs.mobWithoutCC) {
      errors.phone = "Phone Number is Required";
    }
    if (!inputs.userRole) {
      errors.userRole = "User Role is Required";
    }
    if (!inputs.gender) {
      errors.gender = "Gender is Required";
    }
    return errors;
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      var data = new FormData();
      data.append("firstName", values.firstName.trim());
      data.append("lastName", values.lastName.trim());
      data.append("gender", values.gender);
      data.append("email", values.email);
      data.append("MobileCodeId", values.mobileCode);
      data.append("MobileNumber", values.mobWithoutCC);
      data.append("roleId", values.userRole);
      if (image) {
        data.append("ProfileImageAttachment", image);
      }
      let errorMsg = "";
      setLoading(true);
      postMethod("/api/User/Save", data, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfuly");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  const imageChanger = (event) => {
    setImage(event.target.files[0]);
  };

  const fetchRoles = async () => {
    let errorMsg = "";
    getMethod("/api/User/roles", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setRoles(
              res.data.result.filter((i) => i.normalizedName !== "POSAgent")
            );
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching roles";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">User Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="First Name"
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleInputChange}
                          />
                          {errors.firstName && (
                            <div className="text-left text-danger">
                              <small>* {errors.firstName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-lname"
                          >
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lname"
                            placeholder="Last Name"
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleInputChange}
                          />
                          {errors.lastName && (
                            <div className="text-left text-danger">
                              <small>* {errors.lastName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-mobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-mobileCode"
                            className="form-control-alternative"
                            name="mobileCode"
                            value={values.mobileCode}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleInputChange}
                          />
                          {errors.phone && <div className="text-left text-danger">
                            <small>* {errors.phone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                       */}
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={"sl"}
                            name="phone"
                            value={values.phone}
                            // onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            onChange={phoneChange}
                          />
                          {errors.phone && (
                            <div className="text-left text-danger">
                              <small>* {errors.phone}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleInputChange}
                          />
                          {errors.email && (
                            <div className="text-left text-danger">
                              <small>* {errors.email}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-role"
                            className="form-control-label"
                          >
                            Role
                          </label>
                          <Input
                            type="select"
                            id="input-role"
                            className="form-control-alternative"
                            name="userRole"
                            value={values.userRole}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              ------- Select Role -------
                            </option>
                            {roles.map((c) => (
                              <option key={c.id} value={c.id}>
                                {c.name}
                              </option>
                            ))}
                          </Input>
                          {errors.userRole && (
                            <div className="text-left text-danger">
                              <small>* {errors.userRole}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-gender"
                            className="form-control-label"
                          >
                            Gender
                          </label>
                          <Input
                            type="select"
                            id="input-gender"
                            className="form-control-alternative"
                            name="gender"
                            value={values.gender}
                            onChange={handleInputChange}
                          >
                            {/* {roles.map(c => <option key={c.key}>{c.value}</option>)} */}
                            <option key="" value="">
                              ------- Select Gender -------
                            </option>
                            <option key="Male" value="Male">
                              Male
                            </option>
                            <option key="Female" value="Female">
                              Female
                            </option>
                          </Input>
                          {errors.gender && (
                            <div className="text-left text-danger">
                              <small>* {errors.gender}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" for="image">
                            Profile Image
                          </label>
                          <Input
                            className="form-control-file"
                            type="file"
                            name="image"
                            id="image"
                            accept="image/*"
                            onChange={imageChanger}
                          />
                          {errors.attachFile && (
                            <div className="text-left text-danger">
                              <small>* {errors.attachFile}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <div>
                          <Button
                            className="btn float-right margin-top-cls"
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            Submit{" "}
                            {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default User;
