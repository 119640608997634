export const setPosTerminalvalues = (props) => {
  const initialValue = {
    make: props.posMake ? props.posMake : '',
    model: props.posModel ? props.posModel : '',
    manufactureYear: props.posManufactureYear ? props.posManufactureYear : '',
    macAddress: props.posMacAddress ? props.posMacAddress : '',
    serialNo: props.posSerialNumber ? props.posSerialNumber : '',
    nfc: props.isNFCEnabled ? "Enable" : 'Disable',
    chip: props.isChipEnabled ? "Enable" : 'Disable',
    magStrip: props.isMagStripeEnabled   ? "Enable" : 'Disable',
    deviceName: props.posDeviceName ? props.posDeviceName : '',
    ...props
  };

  return initialValue;
}

export default {
  setPosTerminalvalues
};