import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useToasts } from "react-toast-notifications";
import { checkSpace, postMethod, getUserId } from "services/httpServices";
import { useHistory } from "react-router";
import swal from "sweetalert";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { getUserEmail } from "services/httpServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MOBILE_ONLY_COUNTRY } from "appConfig";
import { getAmt } from "services/util";
import { getLoginType } from "services/httpServices";
import { getSuperAgentId } from "services/httpServices";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import LapoLogo from "../../assets/img/brand/lapologo.PNG";
import NacsaLogo from "../../assets/img/brand/nacsaLogo.png";
import WellbodiLogo from "../../assets/img/brand/wellbodi-logo.jpg";
import SpayLogo from "../../assets/img/brand/project_logo.png";
import ApexLogo from "../../assets/img/brand/Apex-logo.jpeg";
import LinkLogo from "../../assets/img/brand/link-logo.png";
import { getUserName } from "services/httpServices";

const initialValue = {
  toMobWithCC: "",
  toMobWithoutCC: "",
  toMobCC: "",
  amount: "",
  pin: "",
  otp: "",
};
const STR = `Please enter the mobile number whom you want to transfer`;

const paymentToOptions = [
  {
    name: "Lapo",
    value: "Lapo",
    logo: LapoLogo,
  },
  {
    name: "NaCSA",
    value: "Nacsa",
    logo: NacsaLogo,
  },
  {
    name: "Wellbodi",
    value: "wellbodi",
    logo: WellbodiLogo,
  },
  {
    name: "SmartPay",
    value: "SmartPay",
    logo: SpayLogo,
  },
  {
    name: "Apex",
    value: "Apex",
    logo: ApexLogo,
  },
  {
    name: "Link",
    value: "Link",
    logo: LinkLogo,
  },
];

const CashoutSubAgent = (props) => {
  const [values, setValues] = useState(initialValue);
  const [subsInfo, setSubsInfo] = React.useState({});
  const [SubscriberDetails, setSubscriberDetails] = React.useState({});
  const [toUserInfo, setToUserInfo] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [use1FA, set1FA] = React.useState(false);
  const [use2FA, set2FA] = React.useState(false);
  const [showPswd, setShowPswd] = React.useState(false);
  const [form, setForm] = React.useState(1);
  const [resendLoader, setResendLoader] = React.useState(false);
  const [paymentTo, setPaymentTo] = useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);

  const { addToast } = useToasts();
  const history = useHistory();
  const routeName = useLocation();
  let loginType = getLoginType();
  // console.log(routeName.pathname);

  /**======================== React Hooks ==========================*/

  useEffect(() => {
    if (paymentTo === "SmartPay") {
      fetchSubs();
    }
  }, [paymentTo]);

  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons: {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: "sweet-btn",
        },
      },
    }).then((confirm) => {
      setSpinner(false);
      if (confirm) {
        setForm(3);
      } else {
        setForm(3);
      }
    });
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values,
        toMobWithCC: value,
        toMobCC: data.dialCode,
        toMobWithoutCC: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const goBack1 = () => {
    setValues(initialValue);
    setToUserInfo({});
    setForm(1);
  };

  const goBack2 = () => {
    setValues({ ...values, otp: "", pin: "" });
    setForm(2);
  };

  const resendOtp = (e) => {
    e.preventDefault();
    setValues({ ...values, otp: "" });
    setResendLoader(true);
    genOtp();
  };

  const checkForm = () => {
    if (form == 2 || form == 3) {
      return true;
    } else {
      return false;
    }
  };

  /**======================== Form Validation function ==========================*/

  const handleSubmit1 = (e) => {
    e?.preventDefault();
    const validationErrors = validate1(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      setOpenDelModal(true);
      //   handleCashout();
    }
  };

  const validate1 = (inputs) => {
    var errors = {};
    if (!inputs.toMobWithCC) {
      errors.toMobWithCC = "Phone number is required";
    } else if (!inputs.toMobWithoutCC) {
      errors.toMobWithCC = "Phone number is required";
    }

    if (!inputs.amount) {
      errors.amount = "amount is required";
    }

    return errors;
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const validationErrors = validate2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      verifyPinV2();
    }
  };

  const validate2 = (inputs) => {
    var errors = {};
    if (!inputs.amount) {
      errors.amount = "Amount is required";
    }
    if (!inputs.pin) {
      errors.pin = "Pin is required";
    }
    return errors;
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();
    const validationErrors = validate3(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      transferMoney();
    }
  };

  const validate3 = (inputs) => {
    var errors = {};
    if (!inputs.amount) {
      errors.amount = "Amount is required";
    }
    if (!inputs.pin) {
      errors.pin = "Pin is required";
    }
    if (use2FA && !inputs.otp) {
      errors.otp = "OTP is required";
    }
    if (!toUserInfo || !toUserInfo.userId) {
      errors.toUserId = "To userid is required";
    }
    return errors;
  };

  const handleInputChange = (ev) => {
    var { name, value } = ev.target;
    value = value.trim();
    if (name === "amount") {
      if (value == "") {
        setValues({ ...values, [name]: value });
      } else {
        if (checkNumberOnly(value)) {
          setValues({ ...values, [name]: value });
        } else {
          setValues({ ...values, [name]: "" });
        }
      }
    } else if (name === "password") {
      if (pinValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === "otp") {
      if (checkOTP(value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkNumberOnly = (value) => {
    // let digiRegex = /^[0-9]*$/;
    let digiRegex = /^[+-]?([1-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  /**======================== API Calls ==========================*/

  const fetchSubs = () => {
    let errorMsg = "";
    let id = getUserId();

    let url = "";
    if (loginType === "Super Agent") {
      url = "/api/agent/GetSuperAgent/" + id;
    } else if (loginType === "Master Agent") {
      url = "/api/Agent/GetMasterAgentById/" + id;
    } else if (loginType === "Agent") {
      setForm(2);
      checkToMob();
      return;
    } else {
      url = "/api/Subscriber/GetByUserId/" + id;
    }
    setLoading(true);
    postMethod(url, null, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setSubsInfo(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching subscriber info";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching subscriber info";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const checkToMob = () => {
    let errorMsg = "";
    setSpinner(true);

    let url = "";
    if (loginType === "Super Agent") {
      url = "/api/Transactions/validateSubAgent/";
    } else if (loginType === "Master Agent") {
      url = "/api/Transactions/validateSuperAgent/";
    } else if (loginType === "Agent") {
      url = "/api/Agent/GetSuperAgent/" + getSuperAgentId();
    } else {
      if (routeName.pathname.includes("cash-out") && loginType === "Agent") {
        // url = "/api/Transactions/validateSubAgent-Subscriber/";
        url = "/api/Transactions/CashOutToOtherPlatform";
      } else {
        url = "/api/Transactions/validateSubscriber/";
      }
    }
    postMethod(url + values.toMobWithCC, {}, "post")
      .then((res) => {
        setSpinner(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setToUserInfo({
              ...res.data.result,
              userId:
                res.data.result?.userId || res.data?.result?.userProfileId,
            });
            setForm(2);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching user info";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching user info";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setSpinner(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const verifyPinV2 = () => {
    var errorMsg = "";
    // var data = {
    //   "PaymentAmount": Number(values.amount),
    //   "Password": values.pin,
    //   "Email": getUserEmail().trim(),
    //   "PaymentType": "BalanceTransfer"
    // };

    var data = {
      PaymentAmount: Number(values.amount),
      Password: values.pin,
      Email: getUserEmail().trim(),
      PaymentType: "BalanceTransfer",
    };
    let url = "";

    if (loginType === "Super Agent") {
      url = "/api/agent/GetSubAgentDetails";
      data.Email = subsInfo.mobNumber;
    } else if (loginType === "Master Agent") {
      url = "/api/Agent/GetSuperAgentDetails";
      data.Email = subsInfo.mobNumber;
    } else if (loginType === "Agent") {
      url = "/api/Agent/GetSuperAgent/" + getSuperAgentId();
    } else {
      if (routeName.pathname.includes("cash-out") && loginType === "Agent") {
        url = "/api/Subscriber/GetSubscriberDetailsForCashout/";
      } else {
        url = "/api/Subscriber/GetSubscriberDetails/";
      }
    }
    setSpinner(true);
    // let url = '/api/pos/PaymentPinVerification';
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        if (res.data) {
          if (res.data.responseCode === "200") {
            let result = res.data.result;
            setSubscriberDetails(result);
            if (result.authFactorType == "1FA") {
              set1FA(true);
              set2FA(false);
            } else if (result.authFactorType == "2FA") {
              set1FA(false);
              set2FA(true);
            } else {
              set1FA(false);
              set2FA(false);
            }
            verifyPinHandle(result);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in validating pin";
            notifyFailed(errorMsg);
            setSpinner(false);
          } else {
            errorMsg = "Unknown error in validating pin";
            notifyFailed(errorMsg);
            setSpinner(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setSpinner(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setSpinner(false);
      });
  };

  const verifyPinHandle = (result) => {
    if (result.authFactorType == "2FA") {
      genOtp();
    } else {
      setSpinner(false);
      setForm(3);
    }
  };

  const genOtp = () => {
    var errorMsg = "";
    var data = JSON.stringify({
      UserId: getUserId(),
      OtpType: "BalanceTransfer",
    });
    let url = "/api/Transactions/Sendotp";
    postMethod(url, data, "post")
      .then((res) => {
        setSpinner(false);
        setResendLoader(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || "Otp sent";
            // notifySuccess(msg);
            openModal(msg);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in generating otp";
            notifyFailed(errorMsg);
            setSpinner(false);
          } else {
            errorMsg = "Unknown error in generating otp";
            notifyFailed(errorMsg);
            setSpinner(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setSpinner(false);
        }
      })
      .catch((err) => {
        setSpinner(false);
        setResendLoader(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  const transferMoney = () => {
    var errorMsg = "";
    var data = {
      ToUserId: toUserInfo.userId,
      Amount: Number(values.amount),
      PIN: values.pin,
    };
    if (use2FA) {
      data["Otp"] = values.otp;
    }
    setSpinner(true);

    let url = "";
    if (loginType === "Super Agent") {
      url = "/api/Transactions/walletBalanceTransferToSubAgent";
    } else if (loginType === "Master Agent") {
      url = "/api/Transactions/walletBalanceTransferToSuperAgent";
    } else if (loginType === "Agent") {
      url = "/api/Transactions/walletBalanceTransferToSuperAgentReverse";
      data.ToUserId = getSuperAgentId();
    } else {
      if (routeName.pathname.includes("cash-out") && loginType === "Agent") {
        url = "/api/Transactions/walletBalanceTransferToSubAgentFromSubscriber";
      } else {
        url = "/api/Transactions/walletBalanceTransfer";
      }
    }
    postMethod(url, data, "post")
      .then((res) => {
        setSpinner(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || "Amount Transferred";
            notifySuccess(msg);
            history.push("/index");
          } else if (res.data.responseCode === "400") {
            errorMsg =
              res.data.responseMessage || "Error in transferring amount";
            notifyFailed(errorMsg);
          } else {
            errorMsg = "Unknown error in transferring amount";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setSpinner(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  const handleCashout = () => {
    var errorMsg = "";
    var data = {
      walletOrAccount: values.toMobWithoutCC,
      subAgentName: getUserName(),
      amount: Number(values.amount),
      platform: paymentTo === "Nacsa" ? "NACSA" : paymentTo,
    };

    setSpinner(true);

    let url = "";
    if (loginType === "Super Agent") {
      url = "/api/Transactions/walletBalanceTransferToSubAgent";
    } else if (loginType === "Master Agent") {
      url = "/api/Transactions/walletBalanceTransferToSuperAgent";
    } else if (loginType === "Agent") {
      //   url = "/api/Transactions/walletBalanceTransferToSuperAgentReverse";
      //   data.ToUserId = getSuperAgentId();
      url = "/api/Transactions/CashOutToOtherPlatform";
    } else {
      //   if (routeName.pathname.includes("cash-out") && loginType === "Agent") {
      //     url = "/api/Transactions/walletBalanceTransferToSubAgentFromSubscriber";
      //   } else {
      url = "/api/Transactions/walletBalanceTransfer";
      //   }
    }
    postMethod(url, data, "post")
      .then((res) => {
        setSpinner(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || "Amount Transferred";
            notifySuccess(msg);
            history.push("/index");
          } else if (res.data.responseCode === "400") {
            errorMsg =
              res.data.responseMessage || "Error in transferring amount";
            notifyFailed(errorMsg);
          } else {
            errorMsg = "Unknown error in transferring amount";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setSpinner(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  return (
    <>
      <Modal isOpen={openDelModal} className="modal-sm">
        <ModalHeader className="bg-white border-0">
          <h3 className="mb-0">Confirm Cashout</h3>
        </ModalHeader>

        <ModalBody>
          <div>
            <div>
              <p className="text-left">
                are you sure want to cashout Amount <b>Le. {values.amount}</b>
              </p>
              <div className="text-right">
                <Button
                  color="primary"
                  onClick={handleCashout}
                  disabled={spinner}
                >
                  Yes {spinner && <i class="fa fa-spinner fa-spin"></i>}
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => setOpenDelModal(false)}
                  disabled={spinner}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Header />
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="8" md="8">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Cash Out</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                {loading && <FullPageLoader />}
                {paymentTo === null && (
                  <Row
                    className="d-flex w-100 justify-content-between align-items-center pl-4"
                    style={{ gap: "24px" }}
                  >
                    {paymentToOptions.map((item) => (
                      <Col
                        key={item.name}
                        style={{ cursor: "pointer" }}
                        className=""
                      >
                        <Card
                          onClick={() => setPaymentTo(item.value)}
                          className="h-25 w-100 flex shadow--hover bg-white-original justify-content-center align-items-center p-4 border pointer-event"
                        >
                          <img
                            src={item.logo}
                            alt={item.name}
                            height={80}
                            style={{
                              objectFit: "contain",
                            }}
                            width={80}
                          />
                          <h5 className="mt-4">{item.name}</h5>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
                {!loading && (
                  <>
                    {form == 1 &&
                      loginType === "Agent" &&
                      (paymentTo === "Lapo" ||
                        paymentTo === "Nacsa" ||
                        paymentTo === "wellbodi" ||
                        paymentTo === "Apex" ||
                        paymentTo === "Link") && (
                        <Form role="form" onSubmit={handleSubmit1}>
                          <Row className="mt-4">
                            <Col>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-amount"
                                >
                                  {paymentTo} Wallet Id
                                </label>
                                <PhoneInput
                                  className="form-control-alternative"
                                  country={"sl"}
                                  value={values.toPhone}
                                  enableSearch
                                  //   onlyCountries={MOBILE_ONLY_COUNTRY}
                                  countryCodeEditable={false}
                                  onChange={phoneChange}
                                />

                                {errors.toMobWithCC && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.toMobWithCC}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-amount"
                                >
                                  Amount
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-amount"
                                  placeholder="Enter Amount"
                                  type="text"
                                  name="amount"
                                  value={values.amount}
                                  onChange={handleInputChange}
                                  disabled={spinner}
                                />
                                {errors.amount && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.amount}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <Button
                                  className="btn "
                                  color="primary"
                                  type="button"
                                  disabled={spinner}
                                  onClick={() => {
                                    setPaymentTo(null);
                                    setForm(1);
                                  }}
                                >
                                  Back{" "}
                                </Button>
                                <Button
                                  className="btn "
                                  color="primary"
                                  type="submit"
                                  disabled={spinner}
                                >
                                  Submit{" "}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}

                    {checkForm() && (
                      <Row>
                        <Col lg="12">
                          <strong>User Details</strong>
                          <div className="mt-2">
                            <div>
                              <small>
                                Transfer to -{" "}
                                {toUserInfo.subscriberName ||
                                  toUserInfo?.fullName}
                              </small>
                            </div>
                            <div>
                              <small className="mt-2">
                                Mobile Number -{" "}
                                {`(${
                                  toUserInfo.mobileCodeId ||
                                  toUserInfo?.mobileCode
                                }) - ${
                                  loginType === "Agent"
                                    ? toUserInfo?.mobileNumber
                                    : toUserInfo?.mobNumber
                                }`}
                              </small>
                            </div>
                            <div>
                              <small className="mt-2">
                                Email - {toUserInfo.email}
                              </small>
                            </div>
                            {form == 3 && (
                              <div>
                                <small className="mt-2">
                                  Amount - &nbsp;
                                  <strong>Le</strong> &nbsp;
                                  {getAmt(values.amount)}
                                </small>
                              </div>
                            )}
                            <div>
                              {routeName.pathname.includes("cash-out") ? (
                                <small>
                                  Fees - <b>Le</b> {SubscriberDetails.fees}
                                  &nbsp;(Sub Agent Commission:{" "}
                                  <b>
                                    {SubscriberDetails.commissionWillCredit}
                                  </b>{" "}
                                  & Subscriber Commission:{" "}
                                  <b>{SubscriberDetails.actualCommission})</b>
                                </small>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    {form == 2 && (
                      <Form role="form" onSubmit={handleSubmit2}>
                        <Row className="mt-2">
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-amount"
                              >
                                Amount
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-amount"
                                placeholder="Enter Amount"
                                type="text"
                                name="amount"
                                value={values.amount}
                                onChange={handleInputChange}
                                disabled={spinner}
                              />
                              {errors.amount && (
                                <div className="text-left text-danger">
                                  <small>* {errors.amount}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pswd"
                              >
                                PIN
                              </label>

                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  id="input-pswd"
                                  type={showPswd ? "text" : "password"}
                                  name="pin"
                                  value={values.pin}
                                  placeholder="Enter 4-digit PIN"
                                  onChange={handleInputChange}
                                  disabled={spinner}
                                />
                                {!showPswd && (
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                      <i
                                        className="fa fa-eye"
                                        onClick={() => setShowPswd(true)}
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                )}

                                {showPswd && (
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                      <i
                                        className="fa fa-eye-slash"
                                        onClick={() => setShowPswd(false)}
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                )}
                              </InputGroup>
                              {errors.pin && (
                                <div className="text-left text-danger">
                                  <small>* {errors.pin}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          {loginType !== "Agent" && (
                            <Col lg="6">
                              <div>
                                <Button
                                  className="btn float-left"
                                  color="primary"
                                  disabled={spinner}
                                  onClick={goBack1}
                                >
                                  <i
                                    class="fas fa-farrow-alt-circle-left"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                  &nbsp; Back
                                </Button>
                              </div>
                            </Col>
                          )}

                          <Col lg="12">
                            <div className="w-100 d-flex justify-content-between align-items-center">
                              <Button
                                className="btn "
                                color="primary"
                                type="button"
                                disabled={spinner}
                                onClick={() => {
                                  setPaymentTo(null);
                                  setForm(1);
                                }}
                              >
                                Back{" "}
                                {spinner && (
                                  <i class="fa fa-spinner fa-spin"></i>
                                )}
                              </Button>
                              <Button
                                className="btn "
                                color="primary"
                                type="submit"
                                disabled={spinner}
                              >
                                Submit{" "}
                                {spinner && (
                                  <i class="fa fa-spinner fa-spin"></i>
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    {form == 3 && (
                      <Form role="form" onSubmit={handleSubmit3}>
                        {use2FA && (
                          <Row className="mt-3">
                            <Col>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-otp"
                                >
                                  OTP
                                </label>

                                <div className="float-right text-small">
                                  <small>
                                    <a href="#pablo" onClick={resendOtp}>
                                      Resend
                                    </a>{" "}
                                    &nbsp; &nbsp;
                                    {resendLoader && (
                                      <div
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                      >
                                        <span class="sr-only"></span>
                                      </div>
                                    )}
                                  </small>
                                </div>

                                <Input
                                  className="form-control-alternative"
                                  id="input-otp"
                                  placeholder="Enter OTP"
                                  type="text"
                                  name="otp"
                                  value={values.otp}
                                  onChange={handleInputChange}
                                />
                                <div className="text-left text-muted">
                                  <small>
                                    <i style={{ fontSize: "12px" }}>
                                      * we have sent you an OTP on your
                                      registered mobile number / email
                                    </i>
                                  </small>
                                </div>
                                {errors.otp && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.otp}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}

                        <Row className="mt-4">
                          <Col lg="6">
                            <div>
                              <Button
                                className="btn float-left"
                                color="primary"
                                onClick={goBack2}
                              >
                                &nbsp; Back
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div>
                              <Button
                                className="btn float-right"
                                color="primary"
                                type="submit"
                                disabled={spinner}
                              >
                                Submit{" "}
                                {spinner && (
                                  <i class="fa fa-spinner fa-spin"></i>
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CashoutSubAgent;
