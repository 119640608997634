import { 
  FETCH_SETTLEMENT_BANK, SETTLEMENT_BANK_ERROR,
  SET_SETTLEMENT_BANK 
} from "./constant";

export const settlementBankIs = {
  loading: false,
  list: [],
  error: null,
  errorMsg: null
};

export const settlementReducer = (state = settlementBankIs, action) => {
  switch(action.type) {
    case FETCH_SETTLEMENT_BANK : 
      return {...state, loading: true};
    case SET_SETTLEMENT_BANK : 
      return {...state, loading: false, list: action.payload};
    case SETTLEMENT_BANK_ERROR : 
      return {...state, loading: false, error: true, errorMsg: action.payload};
    default : 
      return {...settlementBankIs};
  }
}