/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Col,
  ModalHeader,
  Label,
  Input,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import {
  getMethod,
  getUserName,
  postMethod,
} from "../../services/httpServices";
import { useToasts } from "react-toast-notifications";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { btnCss, iconCss } from "../../appConfig";
import jsPDF from "jspdf";
import { getAmt } from "services/util";
import ReactPaginate from "react-paginate";
import SearchableSelect from "components/dropdown/SearchDropdown";

const MerchantSettlementTransactionHistoryList = () => {
  const [loading, setLoading] = React.useState(false);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  const [totalInfo, setTotalInfo] = React.useState(null);
  const [values, setValues] = React.useState({
    paymentType: "",
    totalAmount: "",
  });
  const [errors, setErrors] = React.useState([]);
  const [allMerchants, setAllMerchants] = React.useState([]);
  const [selectedMerchant, setSelectedMerchant] = React.useState(null);
  const [metaInfo, setMetaInfo] = React.useState(null);

  useEffect(() => {
    fetchAllMerchant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const fetchAllMerchant = () => {
    if (allMerchants.length !== 0) return;
    setLoading(true);
    getMethod("/api/MerchantProfile/all", "get")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setAllMerchants(res.data.result);
        }
      })
      .catch(() => {
        notifyFailed("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const openDelete = (mmo) => {
    setDeleteObj(mmo);
    setOpenDelModal(true);
  };

  const fetchSettlementsList = async () => {
    let errorMsg = "";
    console.log(selectedMerchant)
    if(selectedMerchant === null) {
      setErrors({selectedMerchant: "Please select"})
      return
    } else {
      setErrors({})
    }
    setLoading(true);
    postMethod(
      "/api/Transactions/V2/CollectedAndSettlementAmountListMerchantWise?merchantId=" +
        selectedMerchant,
      {},
      "post"
    )
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setMetaInfo(res.data?.result);
            setFilteredList(res.data?.result?.serttlementList);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const closeDeleteModal = () => {
    setDeleteObj(null);
    setOpenDelModal(false);
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Saved successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <div className="p-4 flex flex-items-center j">
                <Row>
                  <Col lg="4" className="flex mt--2">
                    <FormGroup>
                      <label for="input-roleId" className="form-control-label">
                        Merchant
                      </label>
                      <SearchableSelect
                        options={allMerchants?.map((i) => ({
                          key: i?.id,
                          value: i?.name
                        }))}
                        onSelect={(e) => setSelectedMerchant(e.key)}
                      />
                      {errors.selectedMerchant && (
                        <div className="text-left text-danger">
                          <small>* {errors.selectedMerchant}</small>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <Button
                      color="primary"
                      onClick={fetchSettlementsList}
                      className="mt-4"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
              {totalInfo && (
                <div className="pl-4 text-sm mb-2">
                  <h5>
                    <b>Total Pending Settlement Account(TPSA):</b> &nbsp;&nbsp;
                    Le {totalInfo?.settlementPendingAmount}
                  </h5>
                  <h5>
                    <b>Gross Collection Account Balance(GCAB):</b> &nbsp;&nbsp;
                    Le {totalInfo?.collectionAccountBalance}
                  </h5>
                </div>
              )}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Settled By</th>
                    <th scope="col">Settled Type</th>
                    <th scope="col">Settled Amount</th>
                    <th scope="col">Remaining Amount</th>
                    <th scope="col">Settlement Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList
                    .slice(currentPage * 10, (currentPage + 1) * 10)

                    // .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                    .map((mmo, i) => {
                      return (
                        <tr>
                          <th scope="row">
                            {new Date(mmo.createdDate).toLocaleString() ?? "-"}
                          </th>
                          <td>{mmo.fromName ?? "-"}</td>
                          <td>{mmo.toName ?? "-"}</td>
                          <td>{mmo.settledBy ?? "-"}</td>
                          <td>{mmo.settlementType ?? "-"}</td>
                          <td>{mmo.settledAmount ?? "-"}</td>
                          <td>{mmo.remainingAmount ?? "-"}</td>
                          <td>{mmo.settlementStatus ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
                {/* {loading && <FullPageLoader label={"Fetching..."} />} */}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
                {filteredList.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>Total Records : {filteredList.length}</strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6">
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(filteredList.length / 10)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      </nav>
                    </Col>
                  </Row>
                )}
              </CardFooter>{" "}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MerchantSettlementTransactionHistoryList;
