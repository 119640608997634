import React from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";

const LapoLoanRepaymentTab = ({
  values5,
  handleSubmit5,
  handleInputChange5,
  formState5,
  errors5,
  userResponse5,
  setValues5,
  initialValue5,
  loading5,
  setFormState5,
}) => {
  return (
    <div className="mt-2">
      <Form onSubmit={handleSubmit5} autoComplete="off">
        <div className="pl-lg-4">
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-accNo">
                  Loan Account Number
                </label>
                <Input
                  className="form-control-alternative"
                  // country={"sl"}
                  id="input-accNo"
                  name="accNo"
                  value={values5.accNo}
                  // onlyCountries={MOBILE_ONLY_COUNTRY}
                  // countryCodeEditable={false}
                  onChange={handleInputChange5}
                  placeholder="Account number"
                  required
                  disabled={formState5 === 2}
                />
                {errors5.accNo && (
                  <div className="text-left text-danger">
                    <small>* {errors5.accNo}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          {formState5 === 2 ? (
            <>
              <Row
                style={{
                  borderBottom: "1px solid #eee",
                  borderTop: "1px solid #eee",
                  padding: "12px 0px",
                  marginBottom: "12px",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <h4>Loan Details</h4>
                  <h5
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    Account holder name:{" "}
                    {userResponse5?.client?.personal?.firstName}{" "}
                    {userResponse5?.client?.personal?.lastName}
                  </h5>
                  <h5
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    Total loan amount: {userResponse5?.loanAccount?.loanAmount}
                  </h5>
                  <h5
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    Maximum repayable as of today:{" "}
                    {userResponse5?.loanAccount?.maximumRepayableAsOfToday}
                  </h5>
                  <h5
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    Interest rate: {userResponse5?.loanAccount?.interestRate}
                  </h5>
                  <h5
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    User defined interest rate:{" "}
                    {userResponse5?.loanAccount?.userDefinedInterestRate}
                  </h5>
                  <h5
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    Balance:{" "}
                    {userResponse5?.transactions?.length
                      ? userResponse5?.transactions[0]?.balance
                      : userResponse5?.loanAccount?.loanAmount}
                  </h5>
                </Col>
              </Row>
              {formState5 === 2 &&
              userResponse5?.loanAccount?.loanAmount > 0 ? (
                <>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-amount"
                        >
                          Amount
                        </label>
                        <Input
                          className="form-control-alternative"
                          // country={"sl"}
                          id="input-amount"
                          name="amount"
                          value={values5.amount}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          // countryCodeEditable={false}
                          onChange={handleInputChange5}
                          placeholder="Amount"
                          maxLength={50}
                          type="number"
                          max={userResponse5?.loanAccount?.loanAmount}
                          min={0}
                          disabled={formState5 !== 2}
                        />
                        {errors5.amount && (
                          <div className="text-left text-danger">
                            <small>* {errors5.amount}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-Commission"
                        >
                          Cash Commission Earned
                        </label>
                        <Input
                          className="form-control-alternative"
                          // country={"sl"}
                          id="input-Commission"
                          name="Commission"
                          value={values5.amount * (2 / 100)}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          // countryCodeEditable={false}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-pin"
                        >
                          PIN
                        </label>
                        <Input
                          className="form-control-alternative"
                          // country={"sl"}
                          id="input-pin"
                          name="pin"
                          type="tel"
                          value={values5.pin}
                          onChange={handleInputChange5}
                          placeholder="PIN"
                          required
                          maxLength={10}
                        />
                        {errors5.pin && (
                          <div className="text-left text-danger">
                            <small>* {errors5.pin}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          ) : null}
          <Row>
            {formState5 === 2 && (
              <Col>
                <div className="float-left">
                  <Button
                    className="btn float-right"
                    color="primary"
                    type="button"
                    disabled={loading5}
                    onClick={() => {
                      setValues5(initialValue5);
                      setFormState5(1);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            )}
            <Col>
              {/* {formState5 !== 2 ? ( */}
              <div>
                <Button
                  className="btn float-right"
                  color="primary"
                  type="submit"
                  disabled={loading5}
                >
                  Submit {loading5 && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </div>
              {/* ) : null} */}
              {/* {formState5 === 2
                ? userResponse5?.transactions?.length !== 0 &&
                  parseInt(userResponse5?.transactions[0]?.balance) > 0 && (
                    <div>
                      <Button
                        className="btn float-right"
                        color="primary"
                        type="submit"
                        disabled={loading5}
                      >
                        Submit{" "}
                        {loading5 && <i class="fa fa-spinner fa-spin"></i>}
                      </Button>
                    </div>
                  )
                : null} */}
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default LapoLoanRepaymentTab;
