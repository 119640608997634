/* eslint-disable react/no-unescaped-entities */
import UserFlowImg from "../../assets/kb/UserRolesFlow.jpg";
import AccessRightsImg from "../../assets/kb/AccessRights.png";
const overviewPageListItems = [
  {
    heading: "Super Admin",
    list: [
      "Manages the entire application and its user roles.",
      "Creates and supervises Master Agents, Super Agents, Sub Agents, Merchants, Subscribers, and Bank Users.",
      "Gains insight into all transactions occurring between the various roles.",
      "Controls and adjusts commissions related to financial activities.",
    ],
  },
  {
    heading: "Master Agent",
    list: [
      "Initiates wallet top-ups, verified through the integrated bank user system.",
      "Ensures that verified top-up amounts are accurately reflected in the wallet balance.",
      "Facilitates transfers of wallet balance to Super Agents.",
    ],
  },
  {
    heading: "Super Agent",
    list: [
      "Transfers wallet balance to Sub Agents to facilitate their financial operations.",
      "Holds the authority to create, modify, and view Sub Agent profiles.",
      "Monitors and reviews transactional records attributed to their activities.",
    ],
  },
  {
    heading: "Sub Agent",
    list: [
      "Manages wallet balance and enables fund transfers to Subscribers.",
      "Facilitates payments for third-party services, contributing to a seamless financial ecosystem.",
      "Accesses a comprehensive transaction history to stay informed about their financial interactions.",
    ],
  },
  {
    heading: "Merchant",
    list: [
      "Facilitates the cashing out of funds to Super Agents, strengthening the liquidity of the system.",
      "Reviews an extensive log of transactional data, ensuring transparency in their financial operations.",
      "Tracks incoming payments for services provided to Subscribers and Sub Agents.",
    ],
  },
  {
    heading: "Bank User",
    list: [
      "Divided into two types: Collection Bank and Settlement Bank.",
      "Collection Bank verifies the authenticity of Subscribers and Master Agents, ensuring secure access to the system.",
      "Settlement Bank holds the authority to approve payments for Merchants, while also monitoring commission structures.",
    ],
  },
  {
    heading: "Subscriber",
    list: [
      "Empowered to make payments for Merchant bills and third-party services.",
      "Enables easy wallet top-ups using various methods, including MMO, VISA (Credit and Debit) cards and Agents.",
      "Facilitates the transfer of wallet balance to other Subscribers, promoting peer-to-peer transactions.",
      "Offers the flexibility to cash out funds to Sub Agents, enhancing the financial flow within the system.",
    ],
  },
];
const Overview = () => {
  return (
    <>
      <div>
        <h2>
          Smart Pay Application Overview
        </h2>
        <p>
          Smart Pay is a comprehensive financial management application designed
          to streamline and simplify transactions across various user roles.
          With a user-friendly interface, administrators can efficiently create
          and manage a hierarchy of roles, including Master Agents, Super
          Agents, Sub Agents, Merchants, Subscribers, and Bank Users. This
          powerful platform empowers administrators to oversee and analyze
          transactional data between these roles, while also offering the
          flexibility to manage commissions associated with fund transfers.
        </p>
        <p>
          In addition to its core functionalities, Smart Pay offers a seamless
          integration option for other platforms through an extensive set of
          APIs. This integration capability allows external platforms to
          incorporate Smart Pay as a payment option, enhancing the overall
          versatility of the financial ecosystem. Smart Pay's robust API
          endpoints provide third-party developers with the means to seamlessly
          integrate payment functionalities, ensuring a consistent and efficient
          user experience across platforms.
        </p>
      </div>
      <h4>
        Key Features and Responsibilities of Each Role
      </h4>
      <div className="my-4">
        <h4>User Roles</h4>
        <div className="d-flex justify-content-center">
          <img src={UserFlowImg} alt="user flow" className="w-100" />
        </div>
        {overviewPageListItems.map(({ heading, list }) => (
          <div key={heading}>
            <h4 className="font-bold">{heading}</h4>
            <ul className="list-disc w-full my-2">
              {list.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="my-4">
        <h4 className="font-bold md:text-xl">Access permission Chart</h4>
        <div className="d-flex justify-content-center overflow-hidden">
          <img
            src={AccessRightsImg}
            alt="user flow"
            className="w-100"
          />
        </div>
      </div>
      <h2 className="font-bold text-bluePrimary md:text-xl mb-3">Smart Pay</h2>
      <p>
        Serves as a centralized platform that revolutionizes financial
        interactions among Master Agents, Super Agents, Sub Agents, Merchants,
        Subscribers, and Bank Users. With its robust capabilities, Smart Pay
        ensures secure, efficient, and transparent financial management for all
        users involved. Furthermore, its extensive API endpoints enable seamless
        integration for third-party platforms, expanding the reach and impact of
        Smart Pay's payment ecosystem.
      </p>
    </>
  );
};

export default Overview;
