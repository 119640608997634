import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import { NFC_OPTION, GET_YEAR_LIST } from "appConfig";
import { setPosTerminalvalues } from "services/pos-terminal-service";

const NFC = NFC_OPTION && NFC_OPTION.length ? NFC_OPTION : [];

const ViewPOS = (props) => {

  const initialValue = setPosTerminalvalues(props);

  const closeModal = () => {
    props.closeModal();
  }

  return (
    <>
      <div className="p-0">
        <Row >
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">POS Terminal</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: 'pointer', fontSize: '25px' }}
                    >
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>

                  <div className="pl-lg-4">
                    <Row>
                    <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-make"
                          >
                            Device Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-make"
                            placeholder="Make"
                            type="text"
                            name="make"
                            value={initialValue?.deviceName}
                            disabled
                          />
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-make"
                          >
                            Make
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-make"
                            placeholder="Make"
                            type="text"
                            name="make"
                            value={initialValue?.make}
                            disabled
                          />
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-model"
                          >
                            Model
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-model"
                            placeholder="Model"
                            type="text"
                            name="model"
                            value={initialValue?.model}
                            disabled
                          />
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-manufacture-year"
                          >
                            Manufacture Year
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-manufacture-year"
                            placeholder="Manufacture Year"
                            type="select"
                            name="manufactureYear"
                            value={initialValue?.manufactureYear}
                            disabled
                          >
                            {
                              GET_YEAR_LIST()
                            }
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-serialNo"
                          >
                            Serial No
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-serialNo"
                            placeholder="Serial No"
                            type="text"
                            name="serialNo"
                            value={initialValue?.serialNo}
                            disabled
                          />
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-macAddress"
                          >
                            MAC Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-macAddress"
                            placeholder="eg- 00:0a:95:9d:68:16"
                            type="text"
                            name="macAddress"
                            value={initialValue?.macAddress}
                            disabled
                          />
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-nfc"
                            className="form-control-label">
                            NFC
                          </label>
                          <Input
                            type="select"
                            id="input-nfc"
                            className="form-control-alternative"
                            name="nfc"
                            value={initialValue?.nfc}
                            disabled
                          >
                            <option key="" value="">-----Select NFC Option -------</option>
                            {NFC.map(c => <option key={c.key}>{c.value}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-nfc"
                            className="form-control-label">
                            Chip
                          </label>
                          <Input
                            type="select"
                            id="input-nfc"
                            className="form-control-alternative"
                            name="nfc"
                            value={initialValue?.chip}
                            disabled
                          >
                            <option key="" value="">-----Select NFC Option -------</option>
                            {NFC.map(c => <option key={c.key}>{c.value}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-nfc"
                            className="form-control-label">
                            Mag Strip
                          </label>
                          <Input
                            type="select"
                            id="input-nfc"
                            className="form-control-alternative"
                            name="nfc"
                            value={initialValue?.magStrip}
                            disabled
                          >
                            <option key="" value="">-----Select NFC Option -------</option>
                            {NFC.map(c => <option key={c.key}>{c.value}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );

}

export default ViewPOS;
