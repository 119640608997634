import { combineReducers } from "redux";
import rootBankReducer from "./bank/rootBankReducer";
import rootFinanceReducer from "./finance/rootFinanceReducer";
import rootLoginReducer from "./login/rootLoginReducer";
import rootSubsReducer from "./subscriber/rootSubsReducer";
export default combineReducers({
  bank: rootBankReducer,
  subscriber: rootSubsReducer,
  finance: rootFinanceReducer,
  login: rootLoginReducer
});