
export const DEFAULT_ACTION = 'app/PaymentList/DEFAULT_ACTION';
// payment list constant
export const PAYMENT_FECTH_LIST = 'app/PaymentList/PAYMENT_FECTH_LIST';
export const PAYMENT_SET_LIST = 'app/PaymentList/PAYMENT_SET_LIST';
export const PAYMENT_FETCH_ERROR = 'app/PaymentList/PAYMENT_FETCH_ERROR';
export const RESET_PAYMENT_LIST = 'app/PaymentList/RESET_PAYMENT_LIST';
export const COLLECTION_BANK_LIST = 'app/Bank/COLLECTION_BANK_LIST';
export const COLLECTION_BANK_SET_LIST = 'app/Bank/COLLECTION_BANK_SET_LIST';
export const COLLECTION_BANK_ERROR = 'app/Bank/COLLECTION_BANK_ERROR';
export const RESET_COLLECTION_BANK_LIST = 'app/Bank/RESET_COLLECTION_BANK_LIST';


