import { data } from "jquery";
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { postMethod, postMethodDefaultToken } from "../services/httpServices";
import { useToasts } from "react-toast-notifications";
import { AUTH_MODE, AMOUNT_HINT } from "appConfig";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MODES = AUTH_MODE;
const HINT = AMOUNT_HINT;

const codes = [
  23225, 23230, 23231, 23232, 23233, 23234, 23244, 23275, 23276, 23277, 23278,
  23279, 23280, 23288, 23299,
];

const mobileMoney = [];

const banks = [];

const Subscriber = (props) => {
  const [values, setValues] = React.useState(props);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (checkMobValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === "bban") {
      if (checkBbanValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
    // const validationErrors = validate(values);
    // setErrors(validationErrors);
  };

  const checkBbanValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 18) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 5) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const closeModal = () => {
    props.closeModal();
  };

  const reviewModal = () => {
    props.reviewModal();
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const validate = (inputs) => {
    let emailRegEx = /\S+@\S+\.\S+/;
    let errors = {};

    if (!inputs.subscriberType) {
      errors.subscriberType = "Please select subscriber type";
    }
    if (inputs.subscriberType == "Individual") {
      if (!inputs.firstName) {
        errors.firstName = "First Name is Required";
      }
      if (!inputs.lastName) {
        errors.lastName = "Last Name is Required";
      }
    }
    // else if (inputs.subscriberType == 'Organisation') {
    //   if (!inputs.organisationName) {
    //     errors.organisationName = 'Organsation Name is Required';
    //   }
    // }
    // if (!inputs.firstName) {
    //   errors.firstName = 'First Name is required';
    // }
    // if (!inputs.lastName) {
    //   errors.lastName = "Last Name is required";
    // }
    if (!inputs.mobileCode) {
      errors.mobileCode = "Please select mobile code";
    }
    if (!inputs.phone) {
      errors.phone = "Phone Number is Required";
    }
    // else if (inputs.phone.length !== 6) {
    //   errors.phone = 'Phone Number should have 6 digits';
    // }
    if (inputs.email && !emailRegEx.test(inputs.email)) {
      errors.email = "Enter valid email";
    }
    if (!inputs.password) {
      errors.password = "Password is required";
    }
    // if (!inputs.mobileMoney) {
    //   errors.mobileMoney = 'Mobile Money is required';
    // }
    // if (!inputs.mobileMoneyAccNo) {
    //   errors.mobileMoneyAccNo = 'Mobile Money account number is required';
    // }
    if (!inputs.bank) {
      errors.bank = "Bank is required";
    }
    // if (!inputs.bban) {
    //   errors.bban = 'BBAN is required';
    // }
    // if (!inputs.bankLinkedMobileCode) {
    //   errors.bankLinkedMobileCode = 'Mobile Code is required';
    // }
    // if (!inputs.bankLinkedPhone) {
    //   errors.bankLinkedPhone = 'Phone number is required';
    // }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    let url = "/api/Subscriber/save";
    if (isNoError) {
      var data = new FormData();
      if (props.subscriberType === "Individual") {
        data.append("FirstName", values.firstName);
        data.append("LastName", values.lastName);
        data.append("SubscriberType", props.subscriberType);
      } else if (
        props.subscriberType === "Organisation" &&
        props?.registerType !== "sub_agent"
      ) {
        data.append("SubscriberType", "Organisation");
        data.append("OrganizationName", values.organisationName);
      } else {
        data.append("FirstName", values.firstName);
        data.append("LastName", values.lastName);
        data.append("SubscriberType", props.subscriberType);
      }
      data.append("MobileCode", values.mobileCode);
      data.append("MobileNumber", values.mobWithoutCC);
      data.append("Email", values.email);
      data.append("BBAN", values.bban);
      // data.append('BBANLinkMobileNumber', Number(values.bankMobWithoutCC));
      // data.append('BBANLinkMobileCode', Number(values.bankLinkedMobileCode));
      data.append("BBANLinkMobileNumber", Number(0));
      data.append("BBANLinkMobileCode", Number(0));
      if (props.selfRegistered) {
        data.append("AccountCreatedBy", "Self");
        data.append("IsVerifiedByAdmin", false);
      } else {
        data.append("AccountCreatedBy", "Admin");
        data.append("IsVerifiedByAdmin", true);
      }
      data.append("Password", values.password);
      if (values.mobileMoney) {
        data.append("MMOId", values.mobileMoney);
      } else {
        data.append("MMOId", 0);
      }
      if (values.mobileMoneyAccNo) {
        data.append("MMOAccountNumber", values.mobileMoneyAccNo);
      } else {
        data.append("MMOAccountNumber", "");
      }
      data.append("BankProfileId", values.bank);
      data.append("AuthFactorType", values.authMode);
      data.append(
        "ExcidedPaymentAmount",
        values.authMode == "1FA" ? values.oneFAAmt : 0
      );
      data.append("Pin", values.pin);
      data.append("BankAccountNumber", values.bankAccNo);

      if (props.registerType === "super_agent") {
        url = "/api/Agent/SaveSuperAgent";
        data.delete("SubscriberType");
      }
      if (props.registerType === "sub_agent") {
        url = "/api/Agent/SaveSubAgent";
        data.append("Type", Object.fromEntries(data)?.SubscriberType);
        data.append("TopUpCommissionId", props.cashTopupCommId);
        data.delete("SubscriberType");
      }
      if (props.registerType === "master_agent") {
        url = "/api/Agent/SaveMasterAgent";
        data.delete("SubscriberType");
      }
      let errorMsg = "";
      setLoading(true);
      if (props.selfRegistered) {
        SubscriberSelfReg(data);
      } else {
        postMethod(url, data, "post")
          .then((res) => {
            setLoading(false);
            if (res && res.data) {
              if (res.data.responseCode === "200") {
                notifySuccess("Saved Successfully");
                closeModal();
              } else if (res.data.responseCode === "400") {
                // Error while saving
                errorMsg = res.data.responseMessage || "Error while saving";
                notifyFailed(errorMsg);
              } else {
                // Unknown Error
                errorMsg = "Unknown Error in saving";
                notifyFailed(errorMsg);
              }
            } else {
              // Not valid response from server
              errorMsg = "Unknown Error";
              notifyFailed(errorMsg);
            }
          })
          .catch((err) => {
            setLoading(false);
            errorMsg = "Something went wrong!, Please call 3737";
            notifyFailed(errorMsg);
          });
      }
    }
  };

  const SubscriberSelfReg = (data) => {
    let errorMsg = "";
    let url = "/api/Subscriber/SelfRegistration";
    postMethodDefaultToken(url, data, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess("Saved Successfuly");
            closeModal();
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  const getHeaderCls = () => {
    if (props.selfRegistered) {
      return " border-0";
    } else {
      return "bg-white border-0";
    }
  };

  const getBtnCls = () => {
    if (props.selfRegistered) {
      return "sign-up-btn float-right";
    } else {
      return "btn float-right";
    }
  };
  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className={getHeaderCls()}>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {props?.modalName || "Subscriber Information"}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={reviewModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Profile Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      {/* {values.subscriberType == "Individual" && */}
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-fname"
                            >
                              First Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-fname"
                              placeholder="First Name"
                              type="text"
                              name="firstName"
                              value={values.firstName}
                              disabled={true}
                              onChange={handleInputChange}
                            />
                            {errors.firstName && (
                              <div className="text-left text-danger">
                                <small>* {errors.firstName}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-fname"
                            >
                              Last Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-lname"
                              placeholder="Last Name"
                              type="text"
                              name="lastName"
                              value={values.lastName}
                              disabled={true}
                              onChange={handleInputChange}
                            />
                            {errors.lastName && (
                              <div className="text-left text-danger">
                                <small>* {errors.lastName}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </>
                      {/* } */}

                      {/* {values.subscriberType == "Organisation" &&
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-oname"
                            >
                              Organisation Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-oname"
                              placeholder="Organisation Name"
                              type="text"
                              name="organisationName"
                              value={values.organisationName}
                              disabled={true}
                              onChange={handleInputChange}
                            />
                            {errors.organisationName && <div className="text-left text-danger">
                              <small>* {errors.organisationName}</small>
                            </div>}
                          </FormGroup>
                        </Col>} */}
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-mobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-mobileCode"
                            className="form-control-alternative"
                            name="mobileCode"
                            value={values.mobileCode}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            value={values.phone}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.phone && <div className="text-left text-danger">
                            <small>* {errors.phone}</small>
                          </div>}
                        </FormGroup>
                      </Col> */}
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            value={values.phone}
                            disabled
                            // onChange={phoneChange}
                          />
                          {errors.phone && (
                            <div className="text-left text-danger">
                              <small>* {errors.phone}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="email"
                            name="email"
                            value={values.email}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.email && (
                            <div className="text-left text-danger">
                              <small>* {errors.email}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-pswd"
                          >
                            Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-pswd"
                            type="password"
                            name="password"
                            value={values.password}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.password && (
                            <div className="text-left text-danger">
                              <small>* {errors.password}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-pswd"
                          >
                            PIN
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-pin"
                            type="password"
                            name="pin"
                            value={values.pin}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.pin && (
                            <div className="text-left text-danger">
                              <small>* {errors.pin}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="input-authmode"
                            className="form-control-label"
                          >
                            Authentication Mode
                          </label>
                          <Input
                            type="select"
                            id="input-authmode"
                            className="form-control-alternative"
                            name="authMode"
                            value={values.authMode}
                            onChange={handleInputChange}
                            disabled={true}
                          >
                            {MODES.map((c) => (
                              <option key={c.key}>{c.value}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      {values.authMode == "1FA" && (
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-1FA"
                            >
                              Enter amount for using 1FA
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-1FA"
                              placeholder="Amount"
                              type="text"
                              name="oneFAAmt"
                              value={values.oneFAAmt}
                              onChange={handleInputChange}
                              disabled={true}
                            />

                            <div className="text-left">
                              <i>
                                <small>* {HINT}</small>
                              </i>
                            </div>
                            {errors.oneFAAmt && (
                              <div className="text-left text-danger">
                                <small>* {errors.oneFAAmt}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </div>
                  {/* <hr className="my-4" /> */}
                  {/* Address */}
                  {/* <h6 className="heading-small text-muted mb-4">
                    Mobile Money Details
                  </h6> */}
                  {/* <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-mobilemoney"
                            className="form-control-label">
                            Mobile Money (optional)
                          </label>
                          <Input
                            type="select"
                            id="input-mobilemoney"
                            className="form-control-alternative"
                            name="mobilemoney"
                            value={values.mobileMoney}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">------Select MMO-------</option>
                            {values.mobileMoneyList && values.mobileMoneyList.map(c =>
                              <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      {values.mobileMoney.length > 0 && <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mmaccno"
                          >
                            MM Account Number (optional)
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-mmaccno"
                            placeholder="Acount Number"
                            type="text"
                            name="mobileMoneyAccNo"
                            value={values.mobileMoneyAccNo}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.mobileMoneyAccNo && <div className="text-left text-danger">
                            <small>* {errors.mobileMoneyAccNo}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      }
                    </Row>
                  </div> */}
                  <hr className="my-4" />
                  {/* Address */}
                  {props.cashTopupCommId !== null && (
                    <>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                htmlFor="input-cashTopupCommId"
                                className="form-control-label"
                              >
                                Cash Topup Commission
                              </label>
                              <Input
                                type="select"
                                id="input-cashTopupCommId"
                                className="form-control-alternative"
                                name="cashTopupCommId"
                                value={values.cashTopupCommId}
                                onChange={handleInputChange}
                                disabled
                              >
                                {values.cashTopUpCommList &&
                                  values.cashTopUpCommList.map((c) => (
                                    <option key={c.key} value={c.key}>
                                      {c.value}
                                    </option>
                                  ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                    </>
                  )}

                  <h6 className="heading-small text-muted mb-4">
                    Bank Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="input-bank"
                            className="form-control-label"
                          >
                            FSP
                          </label>
                          <Input
                            type="select"
                            id="input-bank"
                            className="form-control-alternative"
                            name="mobilemoney"
                            value={values.bank}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {values.bankList &&
                              values.bankList.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {c.fspName}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bban"
                          >
                            BBAN Number (optional)
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bban"
                            placeholder="BBAN Number (optional)"
                            type="text"
                            name="bban"
                            value={values.bban}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.bban && <div className="text-left text-danger">
                            <small>* {errors.bban}</small>
                          </div>}
                        </FormGroup>
                      </Col> */}

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bank-accno"
                          >
                            Bank Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bank-accno"
                            placeholder="Bank Account Number"
                            type="text"
                            name="bankAccNo"
                            value={values.bankAccNo}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.bankAccNo && (
                            <div className="text-left text-danger">
                              <small>* {errors.bankAccNo}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      {/* 
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Bank Linked Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            value={values.bankLinkedPhone}
                            disabled
                          // onChange={phoneChange}
                          />
                          {errors.bankLinkedPhone && <div className="text-left text-danger">
                            <small>* {errors.bankLinkedPhone}</small>
                          </div>}
                        </FormGroup>
                      </Col> */}

                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-bankLinkedMobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-bankLinkedMobileCode"
                            className="form-control-alternative"
                            name="bankLinkedMobileCode"
                            value={values.bankLinkedMobileCode}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankLinkedPhone"
                          >
                            Bank Linked Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bankLinkedPhone"
                            placeholder="Phone Number"
                            type="text"
                            name="bankLinkedPhone"
                            value={values.bankLinkedPhone}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.bankLinkedPhone && <div className="text-left text-danger">
                            <small>* {errors.bankLinkedPhone}</small>
                          </div>}
                        </FormGroup>
                      </Col> */}
                    </Row>
                  </div>
                  <Row>
                    <Col lg-12>
                      <div>
                        <Button
                          className={getBtnCls()}
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Submit{" "}
                          {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Subscriber;
