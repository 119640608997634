import { 
  FETCH_SUBSCRIBER_INFO,
  SET_SUBSCRIBER_INFO,
  SET_ERROR,
  ADD_VOUCHER,
  ADD_VOUCHER_SUCCESS,
  ADD_VOUCHER_FAILED,
  RESET_VOUCHER,
  FETCH_MERCHANT,
  FETCH_MERCHANT_ERROR,
  SET_MERCHANT,
  RESET_MERCHANT,
  FETCH_PAYABLE_AMT,
  PAYABLE_AMT_ERROR,
  SET_PAYABLE_AMT,
  RESET_PAYABLE_AMT
} from './constant';

export const SubscriberIS = {
  loading: false,
  data: {},
  error: false,
  errorMsg: null
};

export const VoucherIS = {
  loading: false,
  data: {},
  error: false,
  errorMsg: null,
  successMsg: null,
  success: false
}

export const makePaymentIS = {
  loading: false,
  list: [],
  error: false,
  errorMsg: null,
  successMsg: null,
  success: false
}

export const payableAmtIS = {
  loading: false,
  error: false,
  errorMsg: null,
  successMsg: null,
  success: false,
  data: {}
}

export const topUpReducer = (state = SubscriberIS, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIBER_INFO:
      return { ...state, loading: true}
    case SET_SUBSCRIBER_INFO:
      return { ...state, data: action.payload, loading: false}
    case SET_ERROR: 
      return {...state, errorMsg: action.payload, error: true}
    default:
      return { ...state };
  }
};

export const voucherReducer = (state = VoucherIS, action) => {
  switch (action.type) {
    case ADD_VOUCHER:
      return { ...state, loading: true}
    case ADD_VOUCHER_FAILED:
      return { ...state, error: true, errorMsg: action.payload, loading: false}
    case ADD_VOUCHER_SUCCESS: 
      return {...state, success: true, successMsg: 'Top Up is added successfully'}
    case RESET_VOUCHER: 
      return {...VoucherIS}
    default:
      return { ...state };
  }
}

export const makePaymentReducer = (state = makePaymentIS, action) => {
  switch (action.type) {
    case FETCH_MERCHANT :
      return { ...state, loading: true}
    case FETCH_MERCHANT_ERROR : 
      return { ...state, error: true, errorMsg: action.payload, loading: false}
    case SET_MERCHANT : 
      return {...state, success: true, error: false, loading: false, list: action.payload}
    case RESET_MERCHANT : 
      return {...makePaymentIS}
    default:
      return { ...state };
  }
}

export const payableAmtReducer = (state = payableAmtIS, action) => {
  switch (action.type) {
    case FETCH_PAYABLE_AMT :
      return { ...state, loading: true}
    case PAYABLE_AMT_ERROR : 
      return { ...state, error: true, errorMsg: action.payload, loading: false,
        success: false}
    case SET_PAYABLE_AMT : 
      return {
        ...state, success: true, error: false,
        loading: false, data: action.payload
      }
    case RESET_PAYABLE_AMT : 
      return {...makePaymentIS}
    default:
      return { ...state };
  }
}