import FullPageLoader from "components/FullPageLoader/fullpageloader";
import React, { useEffect, useState } from "react";
import { Doughnut, Line, Pie } from "react-chartjs-2";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { postMethod } from "services/httpServices";

const options = {
  plugins: {
    datalabels: {
      formatter: (value) => `${value}%`,
      color: "#fff",
      font: {
        weight: "bold",
        size: 14,
      },
    },
  },
};

const chartOptions = {
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day",
        tooltipFormat: "ll",
      },
      title: {
        display: true,
        text: "Date",
      },
    },
    y: {
      beginAtZero: true,
      max: 100,
      ticks: {
        stepSize: 10,
      },
      title: {
        display: true,
        text: "Success Rate (%)",
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `${context.dataset.label}: ${context.raw}%`;
        },
      },
    },
  },
};

const Insights = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    fspCategoryData: [],
    fspCustomerVerificationRate: [],
    customerRegistrationCount: {},
  });

  const [pieData, setPieData] = useState();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    setLoading(true);
    postMethod("/api/Dashboard/AdminDataInsight", {}, "post")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setData(res.data.result);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setPieData({
      labels: data.fspCategoryData.map((i) => i.fspCategory),
      datasets: [
        {
          data: data.fspCategoryData.map((i) => i.percentage),
          backgroundColor: ["#FFD700", "#FFA500"],
          hoverBackgroundColor: ["#FFD700", "#FFA500"],
        },
      ],
    });
  }, [data.fspCategoryData, data.fspCategoryData.length]);

  useEffect(() => {
    setChartData({
      labels: data.fspCustomerVerificationRate?.map((item) =>
        new Date(item.date).toLocaleDateString()
      ),
      datasets: [
        {
          label: "Success Rate",
          data: data.fspCustomerVerificationRate?.map(
            (item) => item.successRate
          ),
          fill: false,
          backgroundColor: "#FFD700",
          borderColor: "#FFD700",
        },
      ],
    });
  }, [
    data.fspCustomerVerificationRate,
    data.fspCustomerVerificationRate.length,
  ]);

  const getChangeDirection = (type) => {
    if (type === "up") {
      return <i class="fas fa-arrow-up text-green"></i>;
    } else if (type === "down") {
      return <i class="fas fa-arrow-down text-red"></i>;
    } else {
      return <i class="fas fa-minus text-yellow"></i>;
    }
  };

  return (
    <Container fluid className="w-100">
      <Row>
        <Col lg="6" xl="6" md="6">
          <Card className="card-stats mb-4 shadow mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    <strong>Customer Whitelist</strong>
                  </CardTitle>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                    <i class="fas fa-address-book"></i>{" "}
                  </div>
                </Col>
              </Row>
              <p className="mb-0 text-muted text-sm">
                {loading ? (
                  <div className="stage ml-4 mt-2 mb-3">
                    <div className="dot-flashing"></div>
                  </div>
                ) : (
                  <span className="h2 font-weight-bold mb-0">
                    {data.customerRegistrationCount?.totalRegistrations ?? 0}{" "}
                    {getChangeDirection(
                      data.customerRegistrationCount?.changeDirection
                    )}
                  </span>
                )}
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" xl="6" md="6">
          <Card className="card-stats mb-4 shadow mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    <strong>Customer Whitelisting Process Avg Time</strong>
                  </CardTitle>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                    <i class="fas fa-hourglass-half"></i>{" "}
                  </div>
                </Col>
              </Row>
              <p className="mb-0 text-muted text-sm">
                {loading ? (
                  <div className="stage ml-4 mt-2 mb-3">
                    <div className="dot-flashing"></div>
                  </div>
                ) : (
                  <span className="h2 font-weight-bold mb-0">
                    {data.customerWhitelistingProcessAvgTime?.averageTime ?? 0}
                  </span>
                )}
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6" lg="6" className="mt-4">
          <Card className="shadow chart-card">
            <CardBody>
              <h2>FSP Category Distribution</h2>
              <Pie data={pieData} options={options} />
            </CardBody>
          </Card>
        </Col>
        <Col md="6" lg="6" className="mt-4">
          <Card className="shadow chart-card">
            <CardBody>
              <h2>Customer Registration and Verification Success Rate</h2>
              <Line data={chartData} options={chartOptions} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Insights;
