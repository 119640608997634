import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { fetchSubs } from "./action";
import PhoneInput from "react-phone-input-2";
import { MOBILE_ONLY_COUNTRY } from "appConfig";
import { postMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";

const initialState = {
  walletType: "own",
  wallet: "",
  amount: "",
  walletFrom: "",
  sessionStartTime: new Date().toISOString(),
  smartPayCheckoutSessionId: "",
  metadata: {
    phone: "0",
    fromphone: "0",
  },
};

const TopUpWallet = (props) => {
  const [values, setValues] = useState(initialState);
  const [subsInfo, setSubsInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { addToast } = useToasts();

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    props.fetchSubsInfo();
  }, []);

  useEffect(() => {
    setSubsInfo(props.subscriber.subsInfo.data);
  }, [props.subscriber.subsInfo.data]);

  const validatePhone = async (wallet) => {
    try {
      setLoading(true);
      let res = await postMethod(
        `/api/Transactions/validateSubscriber/${wallet}`,
        {
          LoggedInUserId: localStorage.getItem("userId"),
        },
        "post"
      );
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
    }
  };

  const initiatePayment = async (data) => {
    try {
      setLoading(true);

      const res = await postMethod(
        "/api/Transactions/InitiatePayment",
        data,
        "post"
      );
      if (res.data.responseCode === "200") {
        if (!res?.data?.result?.result?.checkoutUrl) {
          notifyFailed(
            "Top up services are temporary down for MMO, Kindly try again after some time"
          );
        } else {
          window.open(res.data.result.result.checkoutUrl, "_self");
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        notifyFailed(
          "Top up services are temporary down for MMO, Kindly try again after some time"
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { mobileNumber } = subsInfo;
    if (values.walletType === "own") {
      values.wallet = mobileNumber;
      values.metadata.phone = values.wallet;
    } else {
      if (values.wallet === mobileNumber || values.wallet.length < 10) {
        setErrors({ phone: "Phone number required" });
        return;
      }
      values.metadata.phone = values.wallet;
    }
    values.walletFrom = mobileNumber;
    values.metadata.fromphone = mobileNumber;

    if (values.walletType === "other") {
      let isPhoneValid = await validatePhone(values.wallet);
      if (isPhoneValid?.data?.responseCode !== "200") {
        notifyFailed(isPhoneValid?.data.responseMessage);
        return;
      }
    }

    const { walletType, ...rest } = values;
    rest.amount = Number(rest.amount);
    rest.smartPayCheckoutSessionId = uuidv4();
    await initiatePayment({
      ...rest,
      LoggedInUserId: localStorage.getItem("userId"),
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Top Up</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody
                className="px-lg-5 py-lg-4"
                style={{ minHeight: "300px" }}
              >
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup tag="fieldset">
                    <Row>
                      <Col lg="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="walletType"
                              value="own"
                              checked={values.walletType === "own"}
                              onChange={handleInputChange}
                            />{" "}
                            Own Wallet
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="walletType"
                              value="other"
                              checked={values.walletType === "other"}
                              onChange={handleInputChange}
                            />{" "}
                            Other Wallet
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  {values.walletType === "own" ? (
                    <>
                      <Row>
                        <Col>
                          <label>
                            <strong>Wallet Number: </strong>
                            {subsInfo?.mobileNumber}
                          </label>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone Number
                            </label>
                            <PhoneInput
                              className="form-control-alternative"
                              country={"sl"}
                              name="wallet"
                              //onlyCountries={MOBILE_ONLY_COUNTRY}
                              countryCodeEditable={false}
                              onChange={(e) => {
                                setValues({ ...values, wallet: e });
                              }}
                            />
                            {errors.phone && (
                              <div className="text-left text-danger">
                                <small>* {errors.phone}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col>
                      <label htmlFor="amount" className="form-control-label">
                        Amount
                      </label>
                      <Input
                        type="number"
                        name="amount"
                        className="form-control-alternative"
                        value={values.amount}
                        onChange={handleInputChange}
                        required
                        min={0}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg-12>
                      <div>
                        <Button
                          className="btn float-right"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Submit
                          {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubsInfo: () => dispatch(fetchSubs()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopUpWallet);
