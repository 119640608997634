import { MOBILE_ONLY_COUNTRY } from "appConfig";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { postMethod } from "services/httpServices";
import { getCurrentDateTime } from "services/util";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import SuccessImg from "assets/img/brand/right-checkmark-removebg-preview.png";
import { useToasts } from "react-toast-notifications";
import { getAmt } from "services/util";

const universities = [
  {
    name: "SLOAN",
  },
  {
    name: "Other",
  },
];

const feeTypes = [
  {
    name: "Application processing fee",
  },
];

const initialState = {
  receiptNumber: `R-${getCurrentDateTime()}-${
    Math.floor(Math.random() * 10) + 1
  }`,
  transactionDate: `${new Date().toLocaleDateString()}`,
  pinCost: "",
  accountNumber: "",
  applicantName: "",
  applicantPhone: "",
  applicantEmail: "",
  universityName: "",
  feeType: "",
  pin: "",
  studentFeeAmount: "",
};

const PayUniversityFees = () => {
  const [pinTypes, setPinTypes] = useState([]);
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [feeTypes1, setFeeTypes1] = useState([]);
  const [studentFeeDetails, setStudentFeeDetails] = useState(null);
  const { addToast } = useToasts();

  const getPinTypes = () => {
    setLoading(true);
    postMethod("/api/Transactions/GetPinTypes", null, "get")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);

          setPinTypes(JSON.parse(res.data.result.result)?.Data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getOtherPinTypes = () => {
    setLoading(true);
    postMethod("/api/Transactions/GetPinTypesForOther", null, "get")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPinTypes(JSON.parse(res.data.result.result)?.Data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const changeLabel = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value.trim() });
    if (name === "universityName" && value === "") {
      setPinTypes([]);
      setValues({ ...values, feeType: "" });
    } else if (name === "universityName" && value === "Other") {
      getFeeTypes();
      setValues({
        ...initialState,
        universityName: value,
        receiptNumber: `R-${getCurrentDateTime()}-${
          Math.floor(Math.random() * 10) + 1
        }`,
      });
    } else if (name === "universityName" && value === "SLOAN") {
      setValues({
        ...initialState,
        universityName: value,
        receiptNumber: `R-${getCurrentDateTime()}-${
          Math.floor(Math.random() * 10) + 1
        }`,
      });
      setPinTypes([]);
    }
    if (name === "feeType" && value === "Application processing fee") {
      getPinTypes();
    } else if (
      name === "feeType" &&
      (value === "Tuition Fees" || value === "Graduation Fees")
    ) {
      setStudentFeeDetails(null);
    } else if (name === "feeType" && value === "Applicant Fees") {
      setPinTypes([]);
      getOtherPinTypes();
      setStudentFeeDetails(null);
    }
  };

  const getFeeTypes = () => {
    setLoading(true);
    postMethod("/api/Transactions/GetFeeTypes", null, "post")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setFeeTypes1(JSON.parse(res.data.result)?.Data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const validate = (inputs) => {
    let errors = {};
    if (inputs?.applicantPhone === "" || inputs?.applicantPhone?.length < 10) {
      errors.applicantPhone = "Phone number is required";
    }
    if (inputs.pin === "" || inputs.pin.length < 4) {
      errors.pin = "Enter valid pin";
    }
    return errors;
  };

  const notifyFailed = (text, type = "error") => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: type,
      autoDismiss: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    if (
      values.universityName === "Other" &&
      values.feeType !== "Applicant Fees"
    ) {
      onMakeFeePayment();
      return;
    }
    let [pinCost, accountNumber] = values.pinCost.split("-");
    let {
      applicantEmail,
      applicantName,
      applicantPhone,
      receiptNumber,
      transactionDate,
      pin,
    } = values;

    let body = {
      pinCost,
      accountNumber,
      applicantEmail,
      applicantName,
      applicantPhone,
      receiptNumber,
      transactionDate,
      pin,
      otp: "",
    };
    setLoading(true);
    let url =
      values.universityName === "Other"
        ? "/api/Transactions/SubmitPinSaleForOther"
        : "/api/Transactions/SubmitPinSale";
    postMethod(url, body, "post")
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setPaymentInfo(res.data?.value?.result);
          setLoading(false);
          notifyFailed("Success", "success");
          if (
            res?.data?.value?.responseMessage &&
            res?.data?.value?.responseCode === "400"
          ) {
            notifyFailed(
              res?.data?.result ||
                res?.data?.responseMessage ||
                res?.data?.value?.responseMessage ||
                "Something went wrong!"
            );
          }
        } else {
          setLoading(false);
          notifyFailed(
            res?.data?.result ||
              res?.data?.responseMessage ||
              res?.data?.value?.responseMessage ||
              "Something went wrong!"
          );
        }
      })
      .catch((err) => {
        notifyFailed(err?.message || "Something went wrong!");
        setLoading(false);
      });
  };

  const resetForm = () => {
    setValues(initialState);
    setErrors({});
    setPaymentInfo({});
    setPinTypes([]);
  };

  const getStudentInfo = () => {
    if (values.studentId === studentFeeDetails?.sid) {
      notifyFailed("Student details already fetched for this Id");
      return;
    }
    if (!values.studentId || !values.feeType) {
      notifyFailed("StudentId, FeeType required!");
      return;
    }
    const body = {
      studentID: values.studentId,
      feeType: values.feeType,
    };
    setLoading(true);
    postMethod("/api/Transactions/GetStudentInfo", body, "post")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setStudentFeeDetails(res.data?.data);
          setValues({
            ...values,
            receiptNumber: `R-${getCurrentDateTime()}-${
              Math.floor(Math.random() * 10) + 1
            }`,
          });
          if (res.data?.message && !res.data?.data) {
            notifyFailed(res.data?.message);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onMakeFeePayment = () => {
    const body = {
      studentID: studentFeeDetails?.sid,
      feeType: values.feeType,
      amount: Number(values.studentFeeAmount),
      reference: values.receiptNumber,
      payerName: studentFeeDetails?.sName,
      payerPhone: values.applicantPhone,
      accountNumber: studentFeeDetails?.accountNumber || values?.accountNumber,
      pin: values.pin,
      otp: "",
    };
    setLoading(true);
    postMethod("/api/Transactions/CommitPayment", body, "post")
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          notifyFailed("Success", "success");
          setPaymentInfo(res.data?.value?.result);
          setValues({
            ...initialState,
            receiptNumber: `R-${getCurrentDateTime()}-${
              Math.floor(Math.random() * 10) + 1
            }`,
          });
          if (
            res?.data?.value?.responseMessage &&
            res?.data?.value?.responseCode === "400"
          ) {
            notifyFailed(
              res?.data?.result ||
                res?.data?.responseMessage ||
                res?.data?.value?.responseMessage ||
                "Something went wrong!"
            );
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed(err.message, "error");
      });
  };

  const checkUniversityType = (name) => {
    if (name === "SLOAN") {
      return (
        <>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label for="input-paymentTo" className="form-control-label">
                  Fee Category
                </label>
                <Input
                  type="select"
                  id="input-paymentTo"
                  className="form-control-alternative"
                  name="feeType"
                  value={values.feeType}
                  onChange={changeLabel}
                  disabled={values.universityName === ""}
                >
                  <option key="" value="" disabled>
                    ----- Select Fee Category ------
                  </option>
                  {feeTypes &&
                    feeTypes.map((c) => (
                      <option key={c.name} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                </Input>
                {errors.universityName && (
                  <div className="text-left text-danger">
                    <small>* {errors.universityName}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          {pinTypes?.length !== 0 && (
            <>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label for="input-paymentTo" className="form-control-label">
                      Pin Category
                    </label>
                    <Input
                      type="select"
                      id="input-paymentTo"
                      className="form-control-alternative"
                      name="pinCost"
                      value={values.pinCost}
                      onChange={changeLabel}
                    >
                      <option key="" value="">
                        ----- Select Category ------
                      </option>
                      {pinTypes &&
                        pinTypes?.map((c) => (
                          <option
                            key={c.PinCost}
                            value={`${c.PinCost}-${c.AccountNumber}`}
                          >
                            {c.PinCategory}
                          </option>
                        ))}
                    </Input>
                    {errors.pinCost && (
                      <div className="text-left text-danger">
                        <small>* {errors.pinCost}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {values.pinCost && (
                <>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Pin Cost
                        </label>
                        <Input
                          type="number"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="pinCost"
                          value={values.pinCost.split("-")[0]}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Account Number
                        </label>
                        <Input
                          type="number"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="accountNumber"
                          value={values.pinCost.split("-")[1]}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Applicant Name
                        </label>
                        <Input
                          type="text"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="applicantName"
                          value={values.applicantName}
                          onChange={changeLabel}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Applicant Email
                        </label>
                        <Input
                          type="email"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="applicantEmail"
                          value={values.applicantEmail}
                          onChange={changeLabel}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          Phone Number
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="applicantPhone"
                          value={values.applicantPhone}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          onChange={(e) =>
                            setValues({ ...values, applicantPhone: e.trim() })
                          }
                          required
                        />
                        {errors.applicantPhone && (
                          <div className="text-left text-danger">
                            <small>* {errors.applicantPhone}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Receipt Number
                        </label>
                        <Input
                          type="text"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="receiptNumber"
                          value={values.receiptNumber}
                          onChange={changeLabel}
                          required
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Transaction date
                        </label>
                        <Input
                          type="text"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="transactionDate"
                          value={values.transactionDate}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Pin
                        </label>
                        <Input
                          type="pin"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="pin"
                          value={values.pin}
                          onChange={changeLabel}
                          required
                          placeholder="Enter your pin"
                        />
                        {errors.pin && (
                          <div className="text-left text-danger">
                            <small>* {errors.pin}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg-12>
                      <div>
                        <Button
                          className="btn float-right"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Validate & Pay{" "}
                          {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      );
    } else if (name === "Other") {
      return (
        <>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label for="input-paymentTo" className="form-control-label">
                  Fee Category
                </label>
                <Input
                  type="select"
                  id="input-paymentTo"
                  className="form-control-alternative"
                  name="feeType"
                  value={values.feeType}
                  onChange={changeLabel}
                  disabled={values.universityName === ""}
                >
                  <option key="" value="" disabled>
                    ----- Select Fee Category ------
                  </option>
                  {feeTypes1?.length !== 0 &&
                    feeTypes1?.map((c) => (
                      <option key={c.FeesTypeValue} value={c.FeesTypeValue}>
                        {c.FeesTypeTitle}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          {pinTypes?.length !== 0 && values.feeType === "Applicant Fees" && (
            <>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label for="input-paymentTo" className="form-control-label">
                      Pin Category
                    </label>
                    <Input
                      type="select"
                      id="input-paymentTo"
                      className="form-control-alternative"
                      name="pinCost"
                      value={values.pinCost}
                      onChange={changeLabel}
                    >
                      <option key="" value="">
                        ----- Select Category ------
                      </option>
                      {pinTypes &&
                        pinTypes.map((c) => (
                          <option
                            key={c.PinCost}
                            value={`${c.PinCost}-${c.AccountNumber}`}
                          >
                            {c.PinCategory}
                          </option>
                        ))}
                    </Input>
                    {errors.pinCost && (
                      <div className="text-left text-danger">
                        <small>* {errors.pinCost}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {values.pinCost && (
                <>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Pin Cost
                        </label>
                        <Input
                          type="number"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="pinCost"
                          value={values.pinCost.split("-")[0]}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Account Number
                        </label>
                        <Input
                          type="number"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="accountNumber"
                          value={values.pinCost.split("-")[1]}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Applicant Name
                        </label>
                        <Input
                          type="text"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="applicantName"
                          value={values.applicantName}
                          onChange={changeLabel}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Applicant Email
                        </label>
                        <Input
                          type="email"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="applicantEmail"
                          value={values.applicantEmail}
                          onChange={changeLabel}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          Phone Number
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="applicantPhone"
                          value={values.applicantPhone}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          onChange={(e) =>
                            setValues({ ...values, applicantPhone: e.trim() })
                          }
                          required
                        />
                        {errors.applicantPhone && (
                          <div className="text-left text-danger">
                            <small>* {errors.applicantPhone}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Receipt Number
                        </label>
                        <Input
                          type="text"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="receiptNumber"
                          value={values.receiptNumber}
                          onChange={changeLabel}
                          required
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Transaction date
                        </label>
                        <Input
                          type="text"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="transactionDate"
                          value={values.transactionDate}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          for="input-paymentTo"
                          className="form-control-label"
                        >
                          Pin
                        </label>
                        <Input
                          type="pin"
                          id="input-paymentTo"
                          className="form-control-alternative"
                          name="pin"
                          value={values.pin}
                          onChange={changeLabel}
                          required
                          placeholder="Enter your pin"
                        />
                        {errors.pin && (
                          <div className="text-left text-danger">
                            <small>* {errors.pin}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg-12>
                      <div>
                        <Button
                          className="btn float-center w-100"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Make payment{" "}
                          {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          {values.universityName === "Other" &&
            values.feeType.length !== 0 &&
            values.feeType !== "Applicant Fees" && (
              <>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        for="input-paymentTo"
                        className="form-control-label"
                      >
                        Student Id
                      </label>
                      <Input
                        type="text"
                        id="input-paymentTo"
                        className="form-control-alternative"
                        name="studentId"
                        value={values.studentId}
                        onChange={changeLabel}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg-12>
                    <div>
                      <Button
                        className="btn float-right"
                        color="primary"
                        type="button"
                        disabled={loading}
                        onClick={getStudentInfo}
                      >
                        Get Details{" "}
                        {loading && <i class="fa fa-spinner fa-spin"></i>}
                      </Button>
                    </div>
                  </Col>
                </Row>
                {studentFeeDetails?.sid ? (
                  <div>
                    <p className="mb-1">Student Information</p>
                    {studentFeeDetails?.sName && (
                      <p className="text-sm font-weight-600 mb-0">
                        Student Name: {studentFeeDetails?.sName}
                      </p>
                    )}
                    {studentFeeDetails?.collegeName && (
                      <p className="text-sm font-weight-600 mb-0">
                        College Name: {studentFeeDetails?.collegeName}
                      </p>
                    )}
                    {studentFeeDetails?.cName && (
                      <p className="text-sm font-weight-600 mb-0">
                        Course Name: {studentFeeDetails?.cName}
                      </p>
                    )}
                    {studentFeeDetails?.sem && (
                      <p className="text-sm font-weight-600 mb-0">
                        Sem: {studentFeeDetails?.sem}
                      </p>
                    )}
                    {studentFeeDetails?.feesAmount && (
                      <p className="text-sm font-weight-600 mb-0">
                        Fee Amount: {getAmt(studentFeeDetails?.feesAmount)}
                      </p>
                    )}
                    {studentFeeDetails?.accountName && (
                      <p className="text-sm font-weight-600 mb-0">
                        Account Name: {studentFeeDetails?.accountName}
                      </p>
                    )}
                    {studentFeeDetails?.accountNumber && (
                      <p className="text-sm font-weight-600 mb-0">
                        Account Number: {studentFeeDetails?.accountNumber}
                      </p>
                    )}
                    <hr className="m-2" />
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={"sl"}
                            name="applicantPhone"
                            value={values.applicantPhone}
                            onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            onChange={(e) =>
                              setValues({ ...values, applicantPhone: e.trim() })
                            }
                            required
                          />
                          {errors.applicantPhone && (
                            <div className="text-left text-danger">
                              <small>* {errors.applicantPhone}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {!studentFeeDetails?.accountNumber && (
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              for="input-accountNumber"
                              className="form-control-label"
                            >
                              Account Number
                            </label>
                            <Input
                              type="text"
                              id="input-accountNumber"
                              className="form-control-alternative"
                              name="accountNumber"
                              value={values.accountNumber}
                              onChange={changeLabel}
                              placeholder="Enter Account Number"
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            for="input-amount"
                            className="form-control-label"
                          >
                            Amount
                          </label>
                          <Input
                            type="text"
                            id="input-amount"
                            className="form-control-alternative"
                            name="studentFeeAmount"
                            value={values.studentFeeAmount}
                            onChange={changeLabel}
                            placeholder="Enter Amount"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label for="input-pin" className="form-control-label">
                            PIN
                          </label>
                          <Input
                            type="text"
                            id="input-pin"
                            className="form-control-alternative"
                            name="pin"
                            value={values.pin}
                            onChange={changeLabel}
                            placeholder="Enter your pin"
                            required
                          />
                          {errors.pin && (
                            <div className="text-left text-danger">
                              <small>* {errors.pin}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg-12>
                        <div>
                          <Button
                            className="btn float-center w-100"
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            Make payment{" "}
                            {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </>
            )}
        </>
      );
    }
  };

  return (
    <div className="px-lg-5 py-lg-4 mt-4 mb-4" style={{ minHeight: "340px" }}>
      <>{loading && <FullPageLoader />}</>
      {!loading && !Object.entries(paymentInfo).length && (
        <Form role="form" onSubmit={handleSubmit}>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label for="input-paymentTo" className="form-control-label">
                  Payment To
                </label>
                <Input
                  type="select"
                  id="input-paymentTo"
                  className="form-control-alternative"
                  name="universityName"
                  value={values.universityName}
                  onChange={changeLabel}
                >
                  <option key="" value="" disabled>
                    ----- Select an option ------
                  </option>
                  {universities &&
                    universities.map((c) => (
                      <option key={c.name} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                </Input>
                {errors.universityName && (
                  <div className="text-left text-danger">
                    <small>* {errors.universityName}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          {checkUniversityType(values.universityName)}
        </Form>
      )}
      {!loading && Object.entries(paymentInfo).length !== 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div>
              {Object.entries(paymentInfo).map(([key, value], idx) => (
                <Row key={idx}>
                  <Col lg="12">
                    <p>
                      <strong>
                        {key?.charAt(0).toLocaleUpperCase() +
                          key?.substring(1, key.length)}
                        :
                      </strong>{" "}
                      {value}
                    </p>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col lg="12">
                  <p>
                    {paymentInfo?.message && (
                      <>
                        <strong>Message:</strong>{" "}
                        <span>{paymentInfo?.message}</span>
                      </>
                    )}
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <img
                src={SuccessImg}
                alt="success"
                style={{ height: 120, margin: 12 }}
              />
            </div>
          </div>

          <Row className="ml-2">
            <Button className="mt-4 ml--2" onClick={resetForm}>
              Go Back
            </Button>
          </Row>
        </>
      )}
    </div>
  );
};

export default PayUniversityFees;
