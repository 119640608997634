import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
} from "reactstrap";

import { getFspCategories } from "appConfig";
import PhoneInput from "react-phone-input-2";
import { getConfirmOptions } from "appConfig";

const initialValue = {
  id: 0,
  fspCategory: "",
  fspName: "",
  regulatoryLicenseNo: "",
  officialEmail: "",
  officialMobileNo: "",
  adminContactEmail: "",
  adminContactMobileNo: "",
  ictContactEmail: "",
  ictContactMobileNo: "",
  posGateway: false,
  onlineGateway: false,
  agentOperations: false,
  apiurl: "",
  userName: "",
  password: "",
  privateKey: "",
  token: "",
  maxSingleTransactionValue: 0,
  extraSecurityDetails: "",
  inboundTransfersFeeTable: "",
  inboundTransfersFSPPays: false,
  inboundTransfersSmartPayPays: false,
  outboundTransfersFeeTable: "",
  outboundTransfersFSPPays: false,
  outboundTransfersSmartPayPays: false,
  agencyServicesFeeTable: "",
  agencyServicesFSPPays: false,
  agencyServicesSmartPayPays: false,
  createBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  isDeleted: false,
  isBlocked: false,
};

const AddPOS = (props) => {
  const [values, setValues] = React.useState(props.values || initialValue);
  const [showPin, setShowPin] = React.useState(false);

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add FSP</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div>
                <h4 className="mb-2">Profile Information</h4>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-fsp-category"
                        >
                          FSP Category
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-fsp-category"
                          placeholder="FSP Category"
                          type="select"
                          name="fspCategory"
                          disabled
                          value={values.fspCategory}
                        >
                          {getFspCategories()}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-fspName"
                        >
                          FSP Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-fspName"
                          placeholder="FSP Name"
                          type="text"
                          name="fspName"
                          disabled
                          value={values.fspName}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-regulatoryLicenseNo"
                        >
                          Regulatory License No
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-regulatoryLicenseNo"
                          placeholder="Regulatory License No"
                          type="text"
                          name="regulatoryLicenseNo"
                          disabled
                          value={values.regulatoryLicenseNo}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-officialEmail"
                        >
                          Official Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-officialEmail"
                          placeholder="jesse@example.com"
                          type="email"
                          name="officialEmail"
                          disabled
                          value={values.officialEmail}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              officialEmail: e.target.value,
                              userName: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-officialMobileNo"
                        >
                          Official Mobile No
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="officialMobileNo"
                          value={values.officialMobileNo}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          disabled
                          onChange={(e) => {
                            setValues({ ...values, officialMobileNo: e });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-adminContactEmail"
                        >
                          Admin Contact Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-adminContactEmail"
                          disabled
                          placeholder="jesse@example.com"
                          type="email"
                          name="adminContactEmail"
                          value={values.adminContactEmail}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-adminContactMobileNo"
                        >
                          Admin Contact Mobile No
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="adminContactMobileNo"
                          value={values.adminContactMobileNo}
                          disabled
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          onChange={(e) => {
                            setValues({ ...values, adminContactMobileNo: e });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-ictContactEmail"
                        >
                          ICT Contact Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-ictContactEmail"
                          placeholder="jesse@example.com"
                          type="email"
                          disabled
                          name="ictContactEmail"
                          value={values.ictContactEmail}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-ictContactMobileNo"
                        >
                          ICT Contact Mobile No
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="ictContactMobileNo"
                          value={values.ictContactMobileNo}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          onChange={(e) => {
                            setValues({ ...values, ictContactMobileNo: e });
                          }}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <div>
                  <h4 className="mb-2">
                    Services Information (Select As Applicable)
                  </h4>
                  <Row>
                    <Col lg="6">
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="posGateway"
                          checked={values.posGateway}
                          disabled
                          onChange={(e) =>
                            setValues({
                              ...values,
                              posGateway: e.target.checked,
                            })
                          }
                        />
                        <Label check>POS Gateway</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="onlineGateway"
                          disabled
                          checked={values.onlineGateway}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              onlineGateway: e.target.checked,
                            })
                          }
                        />
                        <Label check>Online Gateway</Label>
                      </FormGroup>
                      {/* <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="agentOperations"
                          checked={values.agentOperations}
                          disabled
                          onChange={(e) =>
                            setValues({
                              ...values,
                              agentOperations: e.target.checked,
                            })
                          }
                        />
                        <Label check>Agent Operations</Label>
                      </FormGroup> */}
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <div>
                  <h4 className="mb-2">Integration and Security Parameters</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-apiurl"
                          >
                            Api Url
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-apiurl"
                            placeholder="Api Integration Url"
                            type="text"
                            disabled
                            name="apiUrl"
                            value={values.apiurl}
                            onChange={(e) =>
                              setValues({ ...values, apiurl: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-privateKey"
                          >
                            Private Key
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-privateKey"
                            placeholder="Private key"
                            type="text"
                            name="privateKey"
                            disabled
                            value={values.privateKey}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                privateKey: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-token"
                          >
                            Token
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-token"
                            type="text"
                            name="token"
                            placeholder="Token"
                            disabled
                            value={values.token}
                            onChange={(e) =>
                              setValues({ ...values, token: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-maxSingleTransactionValue"
                          >
                            Maximum Single Transaction Value
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-maxSingleTransactionValue"
                            type="number"
                            disabled
                            placeholder="Enter maxSingleTransactionValue"
                            name="maxSingleTransactionValue"
                            value={values.maxSingleTransactionValue}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                maxSingleTransactionValue: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-extraSecurityDetails"
                          >
                            Anything else to add here for extra security?
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-extraSecurityDetails"
                            type="text"
                            placeholder="Enter Extra Security Details"
                            name="extraSecurityDetails"
                            disabled
                            value={values.extraSecurityDetails}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                extraSecurityDetails: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-userName"
                          >
                            User Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-userName"
                            type="email"
                            placeholder="Enter Username"
                            name="basicAuthUName"
                            value={values.userName}
                            disabled
                            onChange={(e) =>
                              setValues({
                                ...values,
                                userName: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >
                            Password
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-password"
                              type={showPin ? "password" : "text"}
                              name="basicAuthpassword"
                              placeholder="Enter Password"
                              value={values.password}
                              disabled
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  password: e.target.value,
                                })
                              }
                              autoComplete="off"
                            />
                            {!showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye-slash"
                                    onClick={() => setShowPin(true)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}

                            {showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() => setShowPin(false)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
                <hr className="my-4" />
                <div>
                  <h4 className="mb-2">Commercials</h4>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Transaction Type</th>
                        <th>Applicable Fees Table</th>
                        <th>FSP Pays (Y/N)</th>
                        <th>Smart Pay Pays (Y/N)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Inbound Transfers</td>

                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-inboundTransfersFeeTable"
                            type="text"
                            disabled
                            placeholder="Enter Inbound Transfers Fee"
                            name="inboundTransfersFeeTable"
                            value={values.inboundTransfersFeeTable}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                inboundTransfersFeeTable: e.target.value,
                              })
                            }
                          />
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-inboundTransfersFSPPays"
                            placeholder="FSP Pays"
                            type="select"
                            name="inboundTransfersFSPPays"
                            disabled
                            value={
                              values.inboundTransfersFSPPays ? "Yes" : "No"
                            }
                          >
                            {getConfirmOptions()}
                          </Input>
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-inboundTransfersSmartPayPays"
                            placeholder="SmartyPay pays"
                            type="select"
                            name="inboundTransfersSmartPayPays"
                            disabled
                            value={
                              values.inboundTransfersSmartPayPays ? "Yes" : "No"
                            }
                          >
                            {getConfirmOptions()}
                          </Input>
                        </td>
                      </tr>
                      <tr>
                        <td>Outbound Transfers</td>
                        <td>
                          {" "}
                          <Input
                            className="form-control-alternative"
                            id="input-outboundTransfersFeeTable"
                            type="text"
                            disabled
                            placeholder="Enter Outbound Transfers Fee"
                            name="outboundTransfersFeeTable"
                            value={values.outboundTransfersFeeTable}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                outboundTransfersFeeTable: e.target.value,
                              })
                            }
                          />
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-outboundTransfersFSPPays"
                            placeholder="FSP Pays"
                            type="select"
                            name="outboundTransfersFSPPays"
                            disabled
                            value={
                              values.outboundTransfersFSPPays ? "Yes" : "No"
                            }
                          >
                            {getConfirmOptions()}
                          </Input>
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-outboundTransfersSmartPayPays"
                            placeholder="SmartyPay pays"
                            type="select"
                            name="outboundTransfersSmartPayPays"
                            disabled
                            value={
                              values.outboundTransfersSmartPayPays
                                ? "Yes"
                                : "No"
                            }
                          >
                            {getConfirmOptions()}
                          </Input>
                        </td>
                      </tr>
                      <tr>
                        <td>Agency Services</td>
                        <td>
                          {" "}
                          <Input
                            className="form-control-alternative"
                            id="input-agencyServicesFeeTable"
                            type="text"
                            disabled
                            placeholder="Enter Agency Services Fee"
                            name="agencyServicesFeeTable"
                            value={values.agencyServicesFeeTable}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                agencyServicesFeeTable: e.target.value,
                              })
                            }
                          />
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-agencyServicesFSPPays"
                            placeholder="FSP Pays"
                            type="select"
                            name="agencyServicesFSPPays"
                            disabled
                            value={values.agencyServicesFSPPays ? "Yes" : "No"}
                          >
                            {getConfirmOptions()}
                          </Input>
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-agencyServicesSmartPayPays"
                            placeholder="SmartyPay pays"
                            type="select"
                            name="agencyServicesSmartPayPays"
                            disabled
                            value={
                              values.agencyServicesSmartPayPays ? "Yes" : "No"
                            }
                          >
                            {getConfirmOptions()}
                          </Input>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddPOS;
