import React, { useEffect } from "react";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { Table } from "reactstrap";
import moment from "moment";
import { getSortCls, reportloader } from "services/util";
import { btnCss, subsStatus } from "../../appConfig";
import FullPageLoader from "components/FullPageLoader/fullpageloader";

//TODO: modify sort props
const sortIS = {
  sortPaymentTo: 0,
  sortPaymentFrom: 0,
  sortReferenceId: 0,
  sortProductId: 0,
  sortCreatedAt: 0,
  sortAmount: 0,
  sortFeesAmount: 0,
  sortStatus: 0,
  sortPaymentMethod: 0,
  sortPaymentType: 0,
};

const reportloaderContainer = {
  position: "absolute",
  top: "240px",
  left: 0,
  width: "100%",
  heigth: "100%",
  backgroundColor: "#F8F8F8AD",
  // display: 'none'
};

const STATUS = subsStatus;

const SubscriberBalanceAnalysisReportTable = (props) => {
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [sort, setSort] = React.useState(sortIS);

  useEffect(() => {
    setFilteredList(props.datalist);
  }, [props.datalist]);

  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    setPageSize(props.pageSize);
  }, [props.pageSize]);

  useEffect(() => {
    props.setParentSort(sort);
  }, [sort]);

  const getAmt = (amt) => {
    return amt.toLocaleString();
  };

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          padding: 24,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>Report Date:</strong> {moment().format("Do MMMM, YYYY")}
          </div>
          <div style={{ width: "50%" }}>
            <strong>Time Generated:</strong> {moment().format("HH:mm")}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>Total Subscriber Balance:</strong>{" "}
            {getAmt(props?.headings?.data?.totalSubscriberBalanceAmount || 0)}
          </div>
          <div style={{ width: "50%" }}>
            <strong>I-C Ratio:</strong> {props?.headings?.data?.icRatio}
          </div>
        </div>
      </div>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
              }
            >
              Balance Range (Le)
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentMethod)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
                  }
                />
              </button>
            </th>

            <th
              scope="col"
              onClick={() => sortHandle(sort.sortPaymentTo, "sortPaymentTo")}
            >
              I.S. Count
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentTo)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentTo, "sortPaymentTo")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortPaymentFrom, "sortPaymentFrom")
              }
            >
              C.S. Count
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentFrom)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentFrom, "sortPaymentFrom")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
              }
            >
              Total Count
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentMethod)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
                  }
                />
              </button>
            </th>

            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortReferenceId, "sortReferenceId")
              }
            >
              Percent Share
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortReferenceId)}
                  onClick={() =>
                    sortHandle(sort.sortReferenceId, "sortReferenceId")
                  }
                />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0 &&
            filteredList.map((mmo, i) => {
              return (
                <tr>
                  <th>{mmo.name}</th>
                  <td>{mmo.individualSubscriber}</td>
                  <td>{mmo.corporateSubscriber}</td>
                  <td>{mmo.totalSubscriberCount}</td>
                  <td>{mmo.percentShare?.toFixed(2)}%</td>
                </tr>
              );
            })}
        </tbody>
        {props.loading && (
          <FullPageLoader
            label={"Fetching..."}
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
        {!props.loading && !filteredList.length && (
          <NoRecordFound
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
      </Table>
    </>
  );
};
export default SubscriberBalanceAnalysisReportTable;
