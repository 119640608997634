import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import { checkSpace, postMethodDefaultToken } from '../../services/httpServices';
import { checkName, setUserDetails } from '../../services/util';
import { PIN_HINT, PASSWORD_HINT, MOBILE_ONLY_COUNTRY } from "appConfig";
import { useToasts } from 'react-toast-notifications';
import swal from 'sweetalert';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";



const codes = [23225, 23230, 23231, 23232,
  23233, 23234, 23244, 23275, 23276, 23277,
  23278, 23279, 23280, 23288, 23299];


const ProfileDetails = (props) => {

  // const initialValue = {
  //   'firstName': props.userDetails && props.userDetails.firstName ? 
  //   props.userDetails.firstName : '',
  //   'lastName': props.userDetails && props.userDetails.lastName ? 
  //   props.userDetails.lastName : '',
  //   'mobileCode': props.userDetails && props.userDetails.mobileCode ? 
  //   props.userDetails.mobileCode : codes[0],
  //   'phone': props.userDetails && props.userDetails.phone ? 
  //   props.userDetails.phone : '',
  //   'email': props.userDetails && props.userDetails.email ? 
  //   props.userDetails.email : '',
  //   'password': props.userDetails && props.userDetails.password ? 
  //   props.userDetails.password : '',
  //   'oneFAAmt': props.userDetails && props.userDetails.oneFAAmt ? 
  //   props.userDetails.oneFAAmt : '',
  //   'authMode': props.userDetails && props.userDetails.authMode ? 
  //   props.userDetails.authMode : '',
  //   'confirmpswd': props.userDetails && props.userDetails.confirmpswd ? 
  //   props.userDetails.confirmpswd : ''
  // };

  const initialValue = setUserDetails(props);
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [showPswd, setShowPswd] = React.useState(false);
  const [showConfirmPswd, setComfirmPswd] = React.useState(false);
  const [showPin, setShowPin] = React.useState(false);
  const [showConfirmPin, setShowConfirmPin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { addToast } = useToasts();


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const handleInputChange = (e) => {
    var { name, value } = e.target;

    // value = value.trim();
    if (checkSpace(value)) {
      // if (name === 'password') {
      //   if (pswdValidation(value)) {
      //     setValues({ ...values, [name]: value });
      //   }
      // } else 
      if (name === "confirmpswd") {
        validateConfirmPswd(value);
        setValues({ ...values, [name]: value });
      } else if (name === 'firstName') {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === 'lastName') {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === 'organisationName') {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === 'phone') {
        if (checkMobValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "pin") {
        if (pinValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "confirmpin") {
        validateConfirmPin(value);
        setValues({ ...values, [name]: value });
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({...values, phone: value, mobileCode: data.dialCode,
        mobWithoutCC: value.substring(data.dialCode.length)
      });
    }
    return;
  }

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const validateConfirmPin = (value) => {
    let error = {};
    if (value !== values.pin) {
      error.confirmpin = 'Confirm pin should match Password';
    }
    setErrors(error);
  }

  const pswdValidation = (value) => {
    if (value.length <= 6) {
      return true;
    } else {
      return false;
    }
  }

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const validateProfile = (e) => {
    e.preventDefault();
    if (checkProfileTab()) {
      // Call gen-otp api
      genotp();
    } else {
    }
  }

  const genotp = () => {
    setLoading(true);
    let data = {
      'Email': values.email ? values.email : '',
      'Mobilenumber': values.mobWithoutCC,
      'FirstName': values.subscriberType && values.subscriberType == 'Individual' ? values.firstName.trim() :
        values.organisationName,
      'LastName': values.lastName.trim(),
      'MobileCode': Number(values.mobileCode)
    }
    let errorMsg = '';
    postMethodDefaultToken('/api/Auth/SelfOtp', data, 'post')
      .then(res => {
        setLoading(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            let msg = res.data.responseMessage || 'Otp sent';
            openModal(msg);

          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in generating otp';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in generating otp';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        setLoading(false);
        notifyFailed('Something went wrong!, Please call 3737');
      })
  }

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons:
      {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: 'sweet-btn'
        }
      }
    }).then((confirm) => {
      if (confirm) {
        props.parentCallback(values);
      } else {
        props.parentCallback(values);
      }
    });
  }

  const checkProfileTab = () => {
    let error = {};
    let valid = true;
    if (!values.subscriberType) {
      error.subscriberType = 'Subscriber Type is Required';
      valid = false;
    }
    if (values.subscriberType == 'Individual') {
      if (!values.firstName) {
        error.firstName = 'First Name is Required';
        valid = false
      }
      if (!values.lastName) {
        error.lastName = 'Last Name is Required';
        valid = false
      }
    } else if (values.subscriberType == 'Organisation') {
      if (!values.organisationName) {
        error.organisationName = 'Organisation Name is Required';
        valid = false;
      }
    }
    
    if (!values.mobileCode) {
      error.mobileCode = 'Mobile Code is Required';
      valid = false
    }
    if (!values.phone) {
      error.phone = 'Phone Number is Required';
      valid = false
    } else if (!values.mobWithoutCC) {
      error.phone = 'Phone Number is Required';
      valid = false
    } 
    // else if (values.phone.length != 6) {
    //   error.phone = 'Phone Number should have 6 digits';
    //   valid = false;
    // }
    if (!values.password) {
      error.password = 'Password is Required';
      valid = false
    } else if (values.password.length < 6) {
      error.password = 'Password should have atleast 6 characters';
      valid = false
    }
    if (!values.confirmpswd) {
      error.confirmpswd = 'Confirm Password is Required';
      valid = false
    } else if (values.confirmpswd !== values.password) {
      error.confirmpswd = 'Confirm password should match Password';
      valid = false
    }

    if (!values.pin) {
      error.pin = 'Pin is Required';
      valid = false
    } else if (values.pin.length !== 4) {
      error.pin = 'Pin should have 4 digits';
      valid = false
    }
    if (!values.confirmpin) {
      error.confirmpin = 'Confirm Pin is Required';
      valid = false
    } else if (values.pin !== values.confirmpin) {
      error.confirmpin = 'Confirm Pin should match Pin';
      valid = false
    }
    if (values.email) {
      if (!checkEmail(values.email)) {
        error.email = 'Please enter valid email';
        valid = false;
      }
    }
    setErrors(error);
    return valid;
  }

  const checkEmail = (email) => {
    let emailRegEx = /\S+@\S+\.\S+/;
    if (emailRegEx.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  const validateConfirmPswd = (value) => {
    let error = {};
    if (value !== values.password) {
      error.confirmpswd = 'Confirm password should match Password';
    }
    setErrors(error);
  }

  return (
    <>
      <Form onSubmit={validateProfile} autoComplete="off">

        <FormGroup tag="fieldset">
          <strong>Subscriber Type</strong>
          <Row>
            <Col lg="6">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="subscriberType" value="Individual"
                    checked={values.subscriberType === "Individual"}
                    onChange={handleInputChange} />{' '}
                  Individual
                </Label>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="subscriberType" value="Organisation"
                    checked={values.subscriberType === "Organisation"}
                    onChange={handleInputChange} />{' '}
                  Organisation
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>

        {values.subscriberType && <>

          {values.subscriberType == "Individual" && <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  First Name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-fname"
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleInputChange}
                />
                {errors.firstName && <div className="text-left text-danger">
                  <small>* {errors.firstName}</small>
                </div>}
              </FormGroup>
            </Col>
          </Row>}
          {values.subscriberType == 'Individual' && <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-lname"
                >
                  Last Name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-lname"
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleInputChange}
                />
                {errors.lastName && <div className="text-left text-danger">
                  <small>* {errors.lastName}</small>
                </div>}
              </FormGroup>
            </Col>
          </Row>
          }

          {values.subscriberType == 'Organisation' && <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-oname"
                >
                  Organisation Name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-oname"
                  placeholder="Organisation Name"
                  type="text"
                  name="organisationName"
                  value={values.organisationName}
                  onChange={handleInputChange}
                />
                {errors.organisationName && <div className="text-left text-danger">
                  <small>* {errors.organisationName}</small>
                </div>}
              </FormGroup>
            </Col>
          </Row>
          }
          <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-email"
                >
                  Email
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-email"
                  placeholder="(optional)"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
                {errors.email && <div className="text-left text-danger">
                  <small>* {errors.email}</small>
                </div>}
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col lg="4">
              <FormGroup>
                <label
                  for="input-mobileCode"
                  className="form-control-label">
                  Mobile Code
                </label>
                <Input
                  type="select"
                  id="input-mobileCode"
                  className="form-control-alternative"
                  name="mobileCode"
                  value={values.mobileCode}
                  onChange={handleInputChange}
                >
                  {codes.map(c => <option key={c}>{c}</option>)}
                </Input>
                {errors.mobileCode && <div className="text-left text-danger">
                  <small>* {errors.mobileCode}</small>
                </div>}
              </FormGroup>
            </Col>
            <Col lg="8">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-phone"
                >
                  Phone Number
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-phone"
                  placeholder="Phone Number"
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleInputChange}
                />
                {errors.phone && <div className="text-left text-danger">
                  <small>* {errors.phone}</small>
                </div>}
              </FormGroup>
            </Col>
          </Row>
           */}

          <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-phone"
                >
                  Phone Number
                </label>
                <PhoneInput
                  className="form-control-alternative"
                  country={'sl'}
                  value={values.phone}
                  enableSearch
                  countryCodeEditable={false}
                  onChange={phoneChange}
                />
                {errors.phone && <div className="text-left text-danger">
                  <small>* {errors.phone}</small>
                </div>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-pswd"
                >
                  Password
                </label>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    id="input-pswd"
                    type={showPswd ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {!showPswd && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye" onClick={() => setShowPswd(true)} />
                    </InputGroupText>
                  </InputGroupAddon>
                  }

                  {showPswd && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye-slash" onClick={() => setShowPswd(false)} />
                    </InputGroupText>
                  </InputGroupAddon>}
                </InputGroup>
                * <small><i>{PASSWORD_HINT}</i></small>
                {errors.password && <div className="text-left text-danger">
                  <small>* {errors.password}</small>
                </div>}
              </FormGroup>
            </Col>


            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-pswd"
                >
                  Confirm Password
                </label>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    id="input-pswd"
                    type={showConfirmPswd ? "text" : "password"}
                    name="confirmpswd"
                    value={values.confirmpswd}
                    onChange={handleInputChange}
                  />
                  {!showConfirmPswd && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye" onClick={() => setComfirmPswd(true)} />
                    </InputGroupText>
                  </InputGroupAddon>
                  }

                  {showConfirmPswd && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye-slash" onClick={() => setComfirmPswd(false)} />
                    </InputGroupText>
                  </InputGroupAddon>}
                </InputGroup>
                {errors.confirmpswd && <div className="text-left text-danger">
                  <small>* {errors.confirmpswd}</small>
                </div>}
              </FormGroup>
            </Col>

          </Row>

          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-pin"
                >
                  PIN
                </label>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    id="input-pin"
                    type={showPin ? "text" : "password"}
                    name="pin"
                    value={values.pin}
                    placeholder="Enter 4-digit Pin"
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {!showPin && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye" onClick={() => setShowPin(true)} />
                    </InputGroupText>
                  </InputGroupAddon>
                  }

                  {showPin && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye-slash" onClick={() => setShowPin(false)} />
                    </InputGroupText>
                  </InputGroupAddon>}
                </InputGroup>
                * <small><i>{PIN_HINT}</i></small>
                {errors.pin && <div className="text-left text-danger">
                  <small>* {errors.pin}</small>
                </div>}
              </FormGroup>
            </Col>


            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-confirm-pin"
                >
                  Confirm PIN
                </label>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    id="input-confirm-pin"
                    type={showConfirmPin ? "text" : "password"}
                    name="confirmpin"
                    placeholder="Enter Confirm Pin"
                    value={values.confirmpin}
                    onChange={handleInputChange}
                  />
                  {!showConfirmPin && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye" onClick={() => setShowConfirmPin(true)} />
                    </InputGroupText>
                  </InputGroupAddon>
                  }

                  {showConfirmPin && <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-eye-slash" onClick={() => setShowConfirmPin(false)} />
                    </InputGroupText>
                  </InputGroupAddon>}
                </InputGroup>
                {errors.confirmpin && <div className="text-left text-danger">
                  <small>* {errors.confirmpin}</small>
                </div>}
              </FormGroup>
            </Col>

            <Col lg-6>
              <FormGroup>
                <Button
                  className="sign-up-btn float-right mt-4"
                  color="primary" type="submit"
                  disabled={loading}
                >
                  Save and Next {loading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </FormGroup>
            </Col>
          </Row>

        </>}


      </Form>
    </>
  )
}

export default ProfileDetails;