import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import { getMethod } from "../../services/httpServices";
import PhoneInput from "react-phone-input-2";

const initialValue = {
  id: 0,
  fspCategory: "",
  fspName: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobileCodeId: 0,
  mobileNumber: "",
  userId: "",
  userStatus: "Pending",
  createBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  isDeleted: false,
  isBlocked: false,
};

const ViewFspUser = (props) => {
  const [values, setValues] = React.useState(props.values || initialValue);
  const [loading, setLoading] = React.useState(false);
  const [fsps, setFsps] = useState([]);
  const { addToast } = useToasts();

  const closeModal = () => {
    props.closeModal();
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };
  useEffect(() => {
    fetchAllFsps();
  }, []);

  const fetchAllFsps = async () => {
    let errorMsg = "";
    setLoading(true);
    getMethod("/api/FSP/GetAll", "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data?.result?.length) {
              setFsps([]);
            } else {
              setFsps(res.data.result);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add FSP User</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-fspName"
                        >
                          FSP Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-fspName"
                          placeholder="FSP Name"
                          type="select"
                          name="fspName"
                          disabled
                          value={values.fspName}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              fspName: e.target.value,
                              fspCategory: fsps.find(
                                (i) => i.id == e.target.value
                              )?.fspCategory,
                            });
                          }}
                        >
                          {/* {roles.map(c => <option key={c.key}>{c.value}</option>)} */}
                          <option key="" value="">
                            ------- Select -------
                          </option>
                          {fsps?.map((item) => (
                            <option key={item.id} value={item.fspName}>
                              {item?.fspName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    {values.fspName !== "" && (
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-fspCategory"
                            >
                              FSP Category
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-fspCategory"
                              placeholder="FSP Category"
                              type="text"
                              name="fspCategory"
                              disabled
                              value={values.fspCategory}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-firstName"
                            >
                              First Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-firstName"
                              placeholder="First Name"
                              type="text"
                              name="firstName"
                              disabled
                              value={values.firstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-middleName"
                            >
                              Middle Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-middleName"
                              placeholder="middleName"
                              type="text"
                              disabled
                              name="middleName"
                              value={values.middleName}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-lastName"
                            >
                              Last Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-lastName"
                              placeholder="lastName"
                              type="text"
                              disabled
                              name="lastName"
                              value={values.lastName}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              disabled
                              placeholder="jesse@example.com"
                              type="email"
                              name="email"
                              value={values.email}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-adminContactMobileNo"
                            >
                              Mobile No
                            </label>
                            <PhoneInput
                              className="form-control-alternative"
                              country={"sl"}
                              name="adminContactMobileNo"
                              value={values.mobileCodeId + values.mobileNumber}
                              disabled
                              countryCodeEditable={false}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewFspUser;
