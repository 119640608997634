/* eslint-disable react/no-unescaped-entities */
import MR1Img from "../../assets/kb/MR1.png";
import MR2Img from "../../assets/kb/MR2.png";
import MR3Img from "../../assets/kb/MR3.png";
import MR4Img from "../../assets/kb/MR4.png";
import MerchantImg from "../../assets/kb/Merchant.png";

const MerchantJourney = () => {
  return (
    <div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl mb-2">Introduction</h4>
        <p>
          The Merchant role within the Smart Pay system comes with a set of
          functionalities designed to facilitate financial interactions and
          streamline operations. This section outlines the key features and
          capabilities available to a Merchant user.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Cash Out by Super Agent
        </h4>
        <p>
          As a Merchant user, you have the ability to initiate a cash-out
          request through the Super Agent. This enables you to withdraw funds
          from your wallet as needed, enhancing the liquidity of your financial
          activities.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Payment Receipt Tracking
        </h4>
        <p>
          Through your Merchant account, you can efficiently track and monitor
          the payments received against bills and services. The system provides
          you with valuable insights into the status of these payments. Once the
          Settlement Bank user settles the amount, you will receive the
          corresponding funds into your account.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Transaction Search
        </h4>
        <p>
          To enhance your record-keeping capabilities, the Smart Pay platform
          allows you to search for specific transactions. This functionality
          enables you to easily locate and review transaction details,
          contributing to a comprehensive overview of your financial
          interactions.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Daily End Reports
        </h4>
        <p>
          Stay informed about your daily financial activities with the Daily End
          Reports feature. By selecting a specific date, you can generate a
          report that summarizes the transactions and activities for that day.
          This provides you with a clear snapshot of your financial performance,
          aiding in your decision-making process.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Getting Started
        </h4>
        <ol className="list-decimal w-full my-2">
          <li>
            Access the Smart Pay platform using your Merchant credentials.
          </li>
          <li>
            Navigate to the dashboard to access the various features outlined
            above.
          </li>
          <li>
            Use the "Cash Withdraw" option to initiate a cash withdrawal through
            the Super Agent.
            <div className="d-flex justify-content-center my-3">
              <img src={MR1Img} alt="Withdraw " className="img-fluid rounded" />
            </div>
          </li>
          <li>
            Monitor your payment receipts and their statuses to stay informed
            about your financial transactions.
            <div className="d-flex justify-content-center my-3">
              <img src={MR2Img} alt="receipts " className="img-fluid rounded" />
            </div>
          </li>
          <li>
            Utilize the transaction search feature to locate and review specific
            transactions.
            <div className="d-flex justify-content-center my-3">
              <img src={MR3Img} alt="search " className="img-fluid rounded" />
            </div>
          </li>
          <li>
            Generate Daily End Reports by selecting a date to gain insights into
            your daily financial activities. Also Merchant can export the report
            in the excel or PDF.
            <div className="d-flex justify-content-center my-3">
              <img src={MR4Img} alt="Reports " className="img-fluid rounded" />
            </div>
          </li>
        </ol>
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
        Merchant Flowchart
      </h4>
      <div className="d-flex justify-content-center my-3">
        <img src={MerchantImg} alt="Account "className="w-100" />
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary mb-2">Conclusion</h4>
      <p>
        As a Merchant user, the Smart Pay platform offers you a range of tools
        to effectively manage your financial operations. By leveraging the
        capabilities outlined in this manual, you can enhance your financial
        visibility, streamline transactions, and make informed decisions to
        optimize your financial activities.
      </p>
    </div>
  );
};

export default MerchantJourney;
