
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row, Col,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalHeader
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useToasts } from 'react-toast-notifications';
import { getMethod, postMethod } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { btnCss, iconCss } from '../../appConfig';
import ViewSubscriber from "views/subscriber/view-subscriber";
import ReactPaginate from 'react-paginate';
import { getSortCls } from "services/util";

const sortIS = {
  sortName: 0,
  sortEmail: 0,
  sortPhone: 0
};


const SubscriberVerificationList = () => {

  const [subslist, setSubsList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [subsObj, setSubsObj] = React.useState(null);
  const [view, setView] = React.useState(false);
  const [banks, setBanks] = React.useState(null);
  const [mobileMoney, setMMO] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');
  const [verifyBankLoading, setVerifyBankLoading] = React.useState(false);
  const [verifyBankObj, setVerifyBankObj] = React.useState(null);
  const [openVerifyBankModal, setOpenVerifyBankModal] = React.useState(false);
  const [sort, setSort] = React.useState(sortIS);
  const [refresh, setRefresh] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);
  const [totalRec, setTotalRec] = React.useState(0);



  const { addToast } = useToasts();

  React.useEffect(() => {
    // fetch mobilemoney operator
    // fetchSubs();
    fetchBank();
    fetchMMO();
  }, []);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        fetchSubs();
      }
    }
    
  }, [searchText]);

  React.useEffect(() => {
    fetchSubs();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchSubs();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchSubs();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);

  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    // fetchSubs();
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const showSubs = (obj) => {
    setSubsObj(obj);
    setView(true);
  }

  const closeViewModal = () => {
    setView(false);
    setSubsObj(null);
  }

 
  const fetchSubs = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortName == "1") {
      data['SortingOn'] = 'FirstName';
      data['IsDescending'] = false;
    } else if (sort.sortName == "2") {
      data['SortingOn'] = 'FirstName';
      data['IsDescending'] = true;
    } else if (sort.sortEmail == "1") {
      data['SortingOn'] = 'Email';
      data['IsDescending'] = false;
    } else if (sort.sortEmail == "2") {
      data['SortingOn'] = 'Email';
      data['IsDescending'] = true;
    } else if (sort.sortPhone == "1") {
      data['SortingOn'] = 'SubscriberUid';
      data['IsDescending'] = false;
    } else if (sort.sortName == "2") {
      data['SortingOn'] = 'SubscriberUid';
      data['IsDescending'] = true;
    }

    postMethod('/api/bankprofile/GetAllSubscriber', data, 'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (
          res && res.data
        ) {
          if (
            res.data.responseCode === "200" &&
            res.data.result
          ) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setSubsList(res.data.result.list);
            setFilteredList(res.data.result.list);
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            // let pc = res.data.result.totalRecords;
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Something went wrong!, Please call 3737');
      })
  }

  const fetchBank = async () => {
    let errorMsg = '';
    getMethod('/api/bankProfile/ddllist?AccountType=Collection', 'get')
      .then(res => {
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (
              !res.data.result ||
              !res.data.result.length
            ) {
              res.data.result = [];
            }
            setBanks(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        notifyFailed('Something went wrong!, Please call 3737');
      })
  }


  const fetchMMO = async () => {
    let errorMsg = '';
    getMethod('/api/MobileMoney/ddllist', 'get')
      .then(res => {
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (
              !res.data.result ||
              !res.data.result.length
            ) {
              res.data.result = [];
            }
            setMMO(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        notifyFailed('Something went wrong!, Please call 3737');
      })
  }

  const openVerifyBank = (mmo) => {
    setVerifyBankObj(mmo);
    setOpenVerifyBankModal(true);
  }

  const closeVerifyBankModal = () => {
    setVerifyBankObj(null);
    setOpenVerifyBankModal(false);
  }

  const verifySubsBank = () => {
    let errorMsg = '';
    setVerifyBankLoading(true);
    let url = `/api/User/subscriberbankverify`;
    let data = {
      'UserId': verifyBankObj.userProfileId,
      'Verify': true
    };
    postMethod(url, data, 'post')
      .then(res => {
        setVerifyBankLoading(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            notifySuccess('Verified Successfuly');
            closeVerifyBankModal();
            reloadList();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setVerifyBankLoading(false);
        errorMsg = 'Something went wrong!, Please call 3737';
        notifyFailed(errorMsg);
      });
  }

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
    fetchBank();
    fetchMMO();
  }

  const getAmt = (amt) => {
    if (amt) {
      return amt.toLocaleString();
    } else {
      return 0;
    }
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto" onSubmit={e => { e.preventDefault(); }}>
                  <FormGroup className="mb-0 my-4 navbar-search">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>


                </Form>

              </CardHeader>

              <Modal
                isOpen={view}
                style={{ width: "70vw", maxWidth: "70vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  <ViewSubscriber closeModal={closeViewModal} {...subsObj}
                    bankList={banks}
                    mobileMoneyList={mobileMoney} />
                </ModalBody>
              </Modal>

              <Modal isOpen={openVerifyBankModal} style={{ minWidth: "30vw", width: "30vw" }}>
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Verify Bank
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {verifyBankObj && verifyBankObj.firstName ?
                      <div>
                        <p className="text-left">
                          Do you want to verify bank of {verifyBankObj.firstName}  {verifyBankObj.lastName} ?
                        </p>

                        <Row className="mb-3">
                          <Col>
                            <strong>Bank Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>Bank Name : {verifyBankObj.bankName}</small>
                              </div>
                              <div>
                                <small className="mt-2">BBAN : {verifyBankObj.bban}</small>
                              </div>
                              <div>
                                <small className="mt-2">Bank Linked Mobile Number : {
                                `(${verifyBankObj.mobileCode}) - ${verifyBankObj.mobileNumber}`}</small>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-right mt-4">
                          <Button
                            color="primary"
                            onClick={verifySubsBank}
                            disabled={verifyBankLoading}>
                            Yes {verifyBankLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button
                            color="secondary"
                            onClick={closeVerifyBankModal}
                            disabled={verifyBankLoading}
                          >No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                  <th scope="col"
                      onClick={() => sortHandle(sort.sortName, 'sortName')}
                    >Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortName)}
                          onClick={() => sortHandle(sort.sortName, 'sortName')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortEmail, 'sortEmail')}
                    >Email
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortEmail)}
                          onClick={() => sortHandle(sort.sortEmail, 'sortEmail')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortPhone, 'sortPhone')}>Phone
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortPhone)}
                          onClick={() => sortHandle(sort.sortPhone, 'sortPhone')}
                        />
                      </button></th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i) => {
                  return (
                    <tr>
                      <th scope="row">
                        {mmo.firstName} {mmo.lastName}
                      </th>
                      <td>{mmo.email}</td>
                      <td>({mmo.mobileCode})-
                        {mmo.mobileNumber}</td>
                      {/* <td>{mmo.bban}</td> */}
                      {/* <td className="text-right">
                        {getAmt(mmo.balanceAmount)}
                      </td> */}
                      <td>
                        <Button
                          color="primary"
                          onClick={() => showSubs(mmo)}
                          size="sm"
                          style={btnCss}
                          data-toggle="tooltip"
                          title="View">
                          <i class="fas fa-eye fa-lg" style={iconCss}></i>
                        </Button>

                        {
                          !mmo.isBankVerified &&
                          <Button
                            color="primary"
                            onClick={() => openVerifyBank(mmo)}
                            size="sm"
                            style={btnCss}
                            data-toggle="tooltip"
                            title="Verify Bank">
                            <i class="fas fa-university fa-lg"
                              style={{ color: "#FF0000" }}>
                            </i>
                          </Button>
                        }

                      </td>
                    </tr>

                  )
                }
                )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
                {!loading && !filteredList.length && <NoRecordFound />}
                {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SubscriberVerificationList;
