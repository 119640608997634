/* eslint-disable react/no-unescaped-entities */
import React from "react";

const LegalInformation = () => {
  return (
    <div>
      <p>
        Welcome to the Smart Pay System Copyright Notice. This section outlines
        the legal information and rights associated with the Smart Pay
        application, its content, and its services. Please take a moment to
        review the following details regarding copyright, usage, and legal
        responsibilities.
      </p>
      <div className="my-4">
        <h4 className="font-bold md:text-xl mb-3">Ownership of Content</h4>
        <p>
          The Smart Pay System, including its website, mobile application,
          graphics, text, images, and other content, is protected by
          international copyright laws and intellectual property rights. All
          rights, including copyrights, trademarks, and proprietary information,
          are owned by Smart Systems Ltd. Any unauthorized reproduction,
          distribution, or use of the Smart Pay content is strictly prohibited
          and may result in legal action.
        </p>
      </div>
      <div className="my-4">
        <h4 className="font-bold md:text-xl mb-3">Usage Rights</h4>
        <p>
          Smart Systems Ltd. grants users limited, non-exclusive, and
          non-transferable rights to access and use the Smart Pay System for
          personal or internal business purposes. This includes the ability to
          interact with the system, access information, and perform authorized
          transactions. However, users are explicitly prohibited from modifying,
          reproducing, distributing, displaying, transmitting, or creating
          derivative works based on the Smart Pay content without prior written
          consent from Smart Systems Ltd.
        </p>
      </div>
      <div className="my-4">
        <h4 className="font-bold mb-3 md:text-xl">Restrictions</h4>
        <p>
          Users must adhere to the terms and conditions set forth by Smart
          Systems Ltd. Unauthorized use, copying, distribution, or modification
          of the Smart Pay content may violate copyright and trademark laws and
          could result in legal consequences. Users are advised to respect the
          rights of Smart Systems Ltd. and other parties who hold proprietary
          interests in the content available through the Smart Pay System.
        </p>
      </div>
      <div className="my-4">
        <h4 className="font-bold mb-3 md:text-xl">Third-Party Content and Links</h4>
        <p>
          The Smart Pay System may include links to third-party websites,
          services, or content that are not owned or controlled by Smart Systems
          Ltd. These links are provided for users' convenience and do not imply
          endorsement or affiliation. Smart Systems Ltd. is not responsible for
          the content, privacy practices, or terms of use of any third-party
          websites or services.
        </p>
      </div>
      <div className="my-4">
        <h4 className="font-bold mb-3 md:text-xl">Reporting Copyright Infringements</h4>
        <p>
          Smart Systems Ltd. takes copyright and intellectual property rights
          seriously. If you believe that any content on the Smart Pay System
          infringes your copyright or violates your intellectual property
          rights, please contact us immediately using the provided contact
          information below.
        </p>
      </div>
      <div className="my-4">
        <h4 className="font-bold mb-3 md:text-xl">Contact Us</h4>
        <p>
          For inquiries related to copyright, legal matters, or any questions
          regarding the Smart Pay System's terms of use, please reach out to us
          at:
        </p>
        <ul className="list-disc w-full  my-2">
          <li>
            Email:{" "}
            <a
              href="mailto:example@example.com"
              className="underline text-blue-500"
            >
              legal@smartpay.com
            </a>
          </li>
          <li>
            Phone: <a href="tel:3737">3737</a>
          </li>
        </ul>
      </div>
      <div className="my-4">
        <h4 className="font-bold mb-3 md:text-xl">Changes to Copyright Notice</h4>
        <p className="mb-3">
          Smart Systems Ltd. reserves the right to update, modify, or amend this
          Copyright Notice from time to time without prior notice. Users are
          advised to review this section periodically to stay informed about any
          changes in copyright and usage rights.
        </p>
        <p>
          Thank you for choosing the Smart Pay System. We appreciate your
          compliance with these terms and your respectful use of our services.
        </p>
      </div>
    </div>
  );
};

export default LegalInformation;
