import React, { useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const center = {
  lat: 8.484444,
  lng: -13.234444,
};

function GoogleMaps({ setCoords, position: pos, disable = false }) {
  const { isLoaded } = useJsApiLoader({
    id: "https://maps.googleapis.com/maps/api/js?key=IzaSyDbGY99IbLobnd_xUoRwSb-H6-I_Ac9Two&loading=async&libraries=geometry&callback=initMap",
    googleMapsApiKey: "AIzaSyCjntjBHx5oy1MA5omilwNQFnkWpA__9JA",
  });

  const coords =
    pos !== undefined && pos?.split(",").map((i) => Number(i.trim()));
  const [position, setPosition] = useState(
    pos !== undefined ? { lat: coords[0], lng: coords[1] } : center
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ height: "600px", width: "100%" }}
          center={center}
          zoom={10}
          onClick={(e) => {
            if (disable) {
              return;
            }
            setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
        >
          <Marker position={position} />
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}

export default React.memo(GoogleMaps);
