import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { API_ENDPOINTS } from "appConfig";
import PhoneInput from "react-phone-input-2";
import ShieldGif from "../../assets/gif/shield.gif";

const initialValue = {
  userId: "",
  otp: "",
  otpType: "ChangePIN",
  password: "",
  pin: "",
  paymentAmount: 0,
  confirmPin: "",
  type: "",
  phone: "",
  customerId: null,
  accountNo: null,
  email: null,
  dob: null,
  fspid: null,
};

const WhiteListCustomer = (props) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSentOtp, setIsSentOtp] = useState(false);
  const [allVerified, setAllVerified] = useState(false);
  const [step, setStep] = useState(0);
  const [customer, setCustomer] = useState(null);
  const { addToast } = useToasts();
  const { id } = useParams();
  const [values, setValues] = useState({
    ...initialValue,
    fspid: id?.split("-")[1] ?? null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API_ENDPOINTS}/api/FSP/Profile/Customer/ValidateUrl/${id}`)
        .then((res) => {
          if (res.data.responseCode === "200") {
            // fetchAllFsps();
            setStep(1);
            setIsVerified(true);
          } else {
            setIsVerified(false);
            setIsExpired(true);
          }
        })
        .catch(() => setIsExpired(true))
        .finally(() => setLoading(false));
    }
  }, []);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.otp?.trim()) {
      errors.otp = "OTP  is required";
    }
    if (!inputs.pin?.trim()) {
      errors.pin = "Pin  is required";
    }
    if (!inputs.confirmPin?.trim()) {
      errors.confirmPin = "Confirm Pin is required";
    }
    if (inputs.confirmPin?.trim() !== inputs?.pin?.trim()) {
      errors.confirmPin = "Pin and Confirm Pin Mismatch";
    }
    return errors;
  };

  const changeState = (obj) => {
    setValues(obj);
  };
  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values,
        mobileCodeId: data.dialCode,
        phone: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const validateCustomer = (inputs) => {
    let errors = {};
    // if (!inputs.fspid) {
    //   errors.fspid = "Fsp  is required";
    // }
    if (!inputs.phone) {
      errors.phone = "Phone is required";
    }
    // if (!inputs.email) {
    //   errors.email = "Email is required";
    // }
    return errors;
  };

  const verifyCustomerData = (e) => {
    setLoading(true);

    e.preventDefault();
    const validationErrors = validateCustomer(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    const { customerId, fspid, accountNo, phone, email } = values;
    axios
      .post(`${API_ENDPOINTS}/api/FSP/Profile/Customer/FetchDetails`, {
        customerId,
        fspid,
        accountNo,
        phone,
        email,
      })
      .then((res) => {
        if (res.data.responseCode === "200") {
          setIsSuccess(true);
          // setStep(2);
          if (
            res.data.result.dateOfBirth !== null ||
            res.data.result.dateOfBirth !== "null"
          ) {
            const actualDOB = new Date(
              res.data.result.dateOfBirth
            ).toLocaleDateString();
            const enteredDOB = new Date(values.dob).toLocaleDateString();
            if (actualDOB !== enteredDOB) {
              notifyFailed("Incorrect Date of Birth entered!");
              return;
            } else {
              setCustomer(res.data.result);
              setIsSuccess(true);
              setStep(2);
            }
          } else {
            setCustomer(res.data.result);
            setIsSuccess(true);
            setStep(2);
          }
        } else {
          const errorMsg = "Incorrect account details";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      })
      .finally(() => setLoading(false));
  };
  const maskText = (text) => {
    if (!text) {
      return null;
    }
    text = String(text);
    const textLength = text.length;
    const firstThree = text.slice(0, 3);
    const remaining = text.slice(3, textLength);

    return (
      firstThree + remaining.replace(remaining, "*".repeat(remaining.length))
    );
  };

  const submitPin = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    setLoading(true);

    const payload = {
      userId: values?.customerId ?? customer?.custId,
      otp: values.otp,
      otpType: "ChangePIN",
      password: values.password,
      pin: values.pin,
      paymentAmount: 0,
      confirmPin: values.confirmPin,
    };
    axios
      .post(
        `${API_ENDPOINTS}/api/Auth/VerifyCustomerOTP`,
        { ...payload },
        {
          headers: {
            PIN1: values.pin,
            PIN2: values.confirmPin,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.responseCode === "200") {
          setAllVerified(true);
          setStep(4);
        } else {
          const errorMsg = "Please check OTP";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setAllVerified(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };
  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };
  const generateOTP = () => {
    const payload = {
      email: values.email,
      phone: values.phone,
      accountNo:
        values?.fspid === "1" ? values.accountNo ?? customer?.accountNo : null,
      customerId:
        values?.fspid === "1" ? values?.customerId ?? customer?.custId : null,
      fspid: values?.fspid ?? "1",
    };
    setLoading(true);
    axios
      .post(`${API_ENDPOINTS}/api/FSP/Profile/Customer/Whitelist`, {
        ...payload,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.responseCode === "200") {
          notifySuccess("OTP Sent Successfully!");
          setIsSentOtp(true);
          setStep(3);
          return;
        } else {
          const errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col xl="6">
            <Card className="bg-default shadow rounded">
              <CardHeader
                className="border-0 d-flex justify-content-center card-header-border"
                style={{ fontWeight: "bold", marginTop: "12px" }}
              >
                Customer verification
              </CardHeader>
              <CardBody style={{ background: "white" }}>
                {loading && step === 0 && (
                  <p className="w-100 text-center">Loading...</p>
                )}
                {step === 0 && isExpired && (
                  <p className="text-center">Link expired!</p>
                )}
                {step === 1 && isVerified && (
                  <div>
                    <Form onSubmit={verifyCustomerData}>
                      <div className="pt-4">
                        <>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-adminContactMobileNo"
                                >
                                  Mobile No *
                                </label>
                                <PhoneInput
                                  className="form-control-alternative"
                                  country={"sl"}
                                  name="adminContactMobileNo"
                                  required
                                  onChange={phoneChange}
                                />
                                {errors.phone && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.phone}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="Email"
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleInputChange}
                                />
                                {errors.email && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.email}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            {/* <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-customerId"
                                >
                                  Customer Id
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-customerId"
                                  placeholder="Customer Id"
                                  type="text"
                                  name="customerId"
                                  value={values.customerId}
                                  onChange={handleInputChange}
                                />
                                {errors.customerId && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.customerId}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-accountNo"
                                >
                                  Account No
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-accountNo"
                                  placeholder="Account No"
                                  type="text"
                                  name="accountNo"
                                  value={values.accountNo}
                                  onChange={handleInputChange}
                                />
                                {errors.accountNo && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.accountNo}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="Email"
                                  type="email"
                                  name="email"
                                  required
                                  value={values.email}
                                  onChange={handleInputChange}
                                />
                                {errors.email && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.email}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-dob"
                                >
                                  Date Of Birth
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-dob"
                                  placeholder="dob"
                                  type="date"
                                  name="dob"
                                  required
                                  value={values.dob}
                                  onChange={handleInputChange}
                                />
                                {errors.dob && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.dob}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col> */}
                            <Col lg="12">
                              <Alert color="info">
                                If you're are a wallet holder to any platform
                                just input your wallet number or phone number
                                associated with the system.
                              </Alert>
                            </Col>
                          </Row>
                        </>
                        <Button
                          type="submit"
                          color="primary"
                          className="mt-4 float-right"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
                {step === 2 && isSuccess && (
                  <>
                    {" "}
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Account Details
                      </p>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Account Name
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer.accountName) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Branch Name
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer.branchName) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Account No
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer.accountNo) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Email
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer?.custEmail) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Available Balance
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer?.avlBal) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Customer Id
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer?.custId) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Customer Name
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer?.customerName) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Customer Phone
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(customer?.custPhone) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          Gender
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {customer?.gender ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "14px",
                            margin: "0px",
                          }}
                        >
                          DOB
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {maskText(
                            new Date(customer?.dateOfBirth).toLocaleDateString()
                          ) ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <Button color="primary" onClick={() => setStep(1)}>
                          Back
                        </Button>
                        <Button color="primary" onClick={generateOTP}>
                          {loading ? "loading..." : "Continue"}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {step === 3 && isSentOtp && (
                  <div className="d-flex justify-content-center align-items-center w-100 flex-column">
                    <img src={ShieldGif} alt="verify" className="h-25 w-25" />
                    <p>
                      Verification has started, and you have received the OTP to
                      complete the verification on your bank-registered email
                      and phone.
                    </p>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhiteListCustomer;
// {
//   "fspid": "1",
//   "phone": "322314242142",
//   "customerId": null,
//   "accountNo": null,
//   "email": "figihem893@lewenbo.com",
//   "LoggedInUserId": "490598b8-0341-4d02-a74f-d047b74d724f"
// }
