/* eslint-disable eqeqeq */
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import Header from "components/Headers/Header";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "services/httpServices";
import { btnCss, iconCss } from "../../appConfig";
import { getSortCls } from "services/util";
import ReactPaginate from "react-paginate";
import { postMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";
import FSPCustomerResetPin from "./fsp-customer-reset-pin";
import CardsList from "./cards-list";

const sortIS = {
  sortFullName: 0,
};

function FSPCustomersList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(sortIS);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  const [isCardModalOpen, setIsOpenCardModal] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const { addToast } = useToasts();

  function fetchData() {
    setLoading(true);
    getMethod("/api/FSP/Profile/Customers")
      .then((res) => {
        if (res.data?.result?.length) {
          setData(res.data.result);
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sortHandle = (fieldValue, fieldName, sortBy) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSortBy(sortBy);
  };

  let filteredData = data.sort((a, b) => {
    if (sortDirection === "asc") {
      return typeof a[sortBy] === "string"
        ? String(a[sortBy]).localeCompare(String(b[sortBy]))
        : a[sortBy] - b[sortBy];
    } else if (sortDirection === "desc") {
      return typeof a[sortBy] === "string"
        ? String(b[sortBy]).localeCompare(String(a[sortBy]))
        : b[sortBy] - a[sortBy];
    } else {
      return 0;
    }
  });

  if (searchText) {
    filteredData = filteredData.filter((item) =>
      String(item?.fullName)
        .toLocaleLowerCase()
        .includes(searchText?.trim().toLocaleLowerCase())
    );
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeDeleteModal = () => {
    setDeleteObj(null);
    fetchData();
    setOpenDelModal(false);
    setOpenReset(false);
    setIsOpenCardModal(false)
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const deleteFsp = () => {
    let errorMsg = "";
    setModalLoading(true);
    let url = "/api/FSP/Customer/Block/" + deleteObj.id;
    postMethod(url, {}, "post")
      .then((res) => {
        setModalLoading(false);
        if (typeof res.data === "string") {
          let text = deleteObj.isBlocked
            ? "FSP Customer unblocked successfully"
            : "FSP Customer blocked successfully";
          notifySuccess(text);
          closeDeleteModal();
          return;
        }
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            let text = deleteObj.isBlocked
              ? "FSP Customer unblocked successfully"
              : "FSP Customer blocked successfully";
            notifySuccess(text);
            closeDeleteModal();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setModalLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  const openDelete = (mmo) => {
    setDeleteObj(mmo);
    setOpenDelModal(true);
  };

  const openResetModal = (mmo) => {
    setDeleteObj(mmo);
    setOpenReset(true);
  };

  const openCardsModal = (mmo) => {
    setDeleteObj(mmo);
    setIsOpenCardModal(true);
  };

  const getBlockText = () => {
    let str = "block";
    if (deleteObj.isBlocked) {
      str = "unblock";
    }

    return `Are you sure you want to ${str} ${deleteObj?.fullName} FSP Customer ?`;
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Modal isOpen={openDelModal} className="modal-sm">
          <ModalHeader className="bg-white border-0">
            <h3 className="mb-0">
              {deleteObj && deleteObj.isBlocked
                ? "Unblock FSP Customer"
                : "Block FSP Customer"}
            </h3>
          </ModalHeader>

          <ModalBody>
            <div>
              {deleteObj && deleteObj.fullName ? (
                <div>
                  {deleteObj?.isBlocked && (
                    <p className="text-left">
                      You have verified all the personal information from the
                      customer and after verification it's being unblock
                    </p>
                  )}
                  <p className="text-left">{getBlockText()}</p>
                  <div className="text-right">
                    <Button
                      color="primary"
                      onClick={deleteFsp}
                      disabled={modalLoading}
                    >
                      Yes{" "}
                      {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={closeDeleteModal}
                      disabled={modalLoading}
                    >
                      No
                    </Button>
                  </div>
                </div>
              ) : (
                <> </>
              )}
            </div>
          </ModalBody>
        </Modal>
        {openReset && (
          <Modal isOpen={openReset}>
            <ModalBody style={{ padding: 0, width: "100%" }}>
              <FSPCustomerResetPin
                closeModal={closeDeleteModal}
                customerId={deleteObj?.id}
              />
            </ModalBody>
          </Modal>
        )}

        {isCardModalOpen && (
          <Modal isOpen={isCardModalOpen} style={{ width: "70vw", maxWidth: "70vw", zIndex: "100" }}>
            <ModalBody style={{ padding: 0, width: "100%" }}>
              <CardsList
                closeModal={closeDeleteModal}
                customerId={deleteObj?.id}
              />
            </ModalBody>
          </Modal>
        )}

        <Card className="shadow">
          <CardHeader className="border-0 d-flex justify-content-end align-items-center">
            <FormGroup className="mb-0 navbar-search ">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="search-cls"
                  placeholder="Search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <Button
              color="primary"
              onClick={fetchData}
              size="sm"
              style={btnCss}
              data-toggle="tooltip"
              title="Refresh"
            >
              <i class="fas fa-redo fa-lg" style={iconCss}></i>
            </Button>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Customer Id</th>
                <th
                  scope="col"
                  onClick={() =>
                    sortHandle(sort.sortFullName, "sortFullName", "fullName")
                  }
                >
                  Full Name
                  <button style={btnCss}>
                    <i
                      className={getSortCls(sort.sortFullName)}
                      onClick={() =>
                        sortHandle(
                          sort.sortFullName,
                          "sortFullName",
                          "fullName"
                        )
                      }
                    />
                  </button>
                </th>
                <th scope="col">Email</th>
                <th scope="col">Gender</th>
                <th scope="col">DOB</th>
                <th scope="col">Verification Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData
                  .slice(currentPage * 10, (currentPage + 1) * 10)
                  .map((teller, i) => {
                    return (
                      <tr>
                        <th scope="row">{teller.customerId ?? "-"}</th>
                        <td>{teller.fullName ?? "-"}</td>
                        <td>{teller.email ?? "-"}</td>
                        <td className="text-center">
                          {teller.gender === "M" ? (
                            <i class="fas fa-male"></i>
                          ) : teller.gender === "F" ? (
                            <i class="fas fa-female text-pink"></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {new Date(teller.dateOfBirth).toLocaleDateString() ??
                            "-"}
                        </td>
                        <td
                          title={teller?.verificationStatus ?? "-"}
                          className="text-center"
                        >
                          {teller.verificationStatus === "Verified" ? (
                            <i class="far text-green fa-check-circle"></i>
                          ) : teller.verificationStatus === "Pending" ? (
                            <i class="fas text-orange fa-exclamation-circle"></i>
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!teller.isBlocked ? (
                            <Button
                              color="primary"
                              onClick={() => openDelete(teller)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Block"
                            >
                              <i
                                class="fa fa-eye-slash fa-lg"
                                style={iconCss}
                              ></i>
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => openDelete(teller)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Unblock"
                            >
                              <i
                                class="fa fa-eye-slash fa-lg"
                                style={{ color: "#FF0000" }}
                              ></i>
                            </Button>
                          )}
                          <Button
                            color="primary"
                            onClick={() => openResetModal(teller)}
                            size="sm"
                            style={btnCss}
                            data-toggle="tooltip"
                            title="Reset PIN"
                          >
                            <i class="fas fa-key" style={iconCss}></i>
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => openCardsModal(teller)}
                            size="sm"
                            style={btnCss}
                            data-toggle="tooltip"
                            title="View Cards"
                          >
                            <i class="far fa-credit-card" style={iconCss}></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
            {loading && <FullPageLoader label={"Fetching..."} />}
            {!loading && !filteredData.length && <NoRecordFound />}
          </Table>
          <CardFooter className="py-4">
            {filteredData.length > 0 && (
              <Row>
                <Col lg="6">
                  <div>
                    <small>
                      <strong>Total Records : {filteredData.length}</strong>
                    </small>
                  </div>
                </Col>

                <Col lg="6">
                  <nav aria-label="...">
                    <div className="float-right">
                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(filteredData.length / 10)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"paginationV2"}
                        subContainerClassName={"pages paginationV2"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                      />
                    </div>
                  </nav>
                </Col>
              </Row>
            )}
          </CardFooter>{" "}
        </Card>
      </Container>
    </div>
  );
}

export default FSPCustomersList;
