import React from "react";
import {
  Button,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
  InputGroupAddon,
  Label,
  InputGroupText,
} from "reactstrap";
import FullPageLoader from "components/FullPageLoader/fullpageloader";

const PaySmartBills = ({
  handleSubmit,
  form,
  merchants,
  changeLabel,
  errors,
  values,
  handleInputChange,
  loading,
  checkForm,
  checkFees,
  validatePin,
  getAmt,
  fees,
  showPswd,
  setShowPswd,
  goBack,
  handleSubmitV2,
  use2FA,
  resendOtp,
  spinner,
  subsInfo,
  makePaymentInfoLoading, //props.subscriber.makePaymentInfo.loading
}) => {
  return (
    <CardBody className="px-lg-5 py-lg-4" style={{minHeight: "340px"}}>
      {makePaymentInfoLoading && <FullPageLoader />}
      {form == 1 && !makePaymentInfoLoading && (
        <Form role="form" onSubmit={handleSubmit}>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label for="input-paymentTo" className="form-control-label">
                  Payment To
                </label>
                <Input
                  type="select"
                  id="input-paymentTo"
                  className="form-control-alternative"
                  name="merchantId"
                  value={values.merchantId}
                  onChange={changeLabel}
                >
                  <option key="" value="">
                    ----- Select Merchant ------
                  </option>
                  {merchants &&
                    merchants.map((c) => (
                      <option key={c.name} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                </Input>
                {errors.merchantId && (
                  <div className="text-left text-danger">
                    <small>* {errors.merchantId}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          {values.merchantId && (
            <>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-label">
                      {values.label}
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-label"
                      placeholder={"Enter " + values.label}
                      type="text"
                      name="uniqueNo"
                      value={values.uniqueNo}
                      onChange={handleInputChange}
                    />
                    {errors.uniqueNo && (
                      <div className="text-left text-danger">
                        <small>* {errors.uniqueNo}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg-12>
                  <div>
                    <Button
                      className="btn float-right"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Validate{" "}
                      {loading && <i class="fa fa-spinner fa-spin"></i>}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Form>
      )}

      {checkForm && (
        <Row className="mb-3">
          <Col>
            <strong>Payment Details</strong>
            <div className="mt-2">
              <div>
                <small>Payment To - {values.merchantName}</small>
              </div>
              <div>
                <small className="mt-2">
                  {values.label} - {values.uniqueNo}
                </small>
              </div>
              <div>
                <small className="mt-2">Amount - {getAmt()}</small>
              </div>

              {checkFees() && (
                <div>
                  <small className="mt-2">Smart Pay Fees - {fees}</small>
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}

      {form == 2 && (
        <Form role="form" onSubmit={validatePin}>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-pswd">
                  PIN
                </label>

                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    id="input-pswd"
                    type={showPswd ? "text" : "password"}
                    name="password"
                    placeholder="Enter 4-digit PIN"
                    value={values.password}
                    onChange={handleInputChange}
                  />
                  {!showPswd && (
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="fa fa-eye"
                          onClick={() => setShowPswd(true)}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  )}

                  {showPswd && (
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="fa fa-eye-slash"
                          onClick={() => setShowPswd(false)}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  )}
                </InputGroup>
                {errors.password && (
                  <div className="text-left text-danger">
                    <small>* {errors.password}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg-6>
              <div>
                <Button
                  className="btn float-left"
                  color="primary"
                  onClick={goBack}
                >
                  <i
                    class="fas fa-arrow-alt-circle-left"
                    style={{ fontSize: "18px" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Col>

            <Col lg-6>
              <div>
                <Button
                  className="btn float-right"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Validate {loading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}

      {form == 3 && (
        <Form onSubmit={handleSubmitV2} autoComplete="off">
          {use2FA && (
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-otp">
                    OTP
                  </label>

                  <div className="float-right text-small">
                    <small>
                      <a href="#pablo" onClick={resendOtp}>
                        Resend
                      </a>{" "}
                      &nbsp; &nbsp;
                      {spinner && (
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only"></span>
                        </div>
                      )}
                    </small>
                  </div>

                  <Input
                    className="form-control-alternative"
                    id="input-otp"
                    placeholder="Enter OTP"
                    type="text"
                    name="otp"
                    value={values.otp}
                    onChange={handleInputChange}
                  />
                  <div className="text-left text-muted">
                    <small>
                      <i style={{ fontSize: "12px" }}>
                        * we have sent you an OTP on your registered mobile
                        number / email
                      </i>
                    </small>
                  </div>
                  {errors.otp && (
                    <div className="text-left text-danger">
                      <small>* {errors.otp}</small>
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          <FormGroup tag="fieldset" className="mt-1">
            <strong>Make Payment using</strong>
            <Row>
              <Col lg="6">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="accountType"
                      value="Bank"
                      onChange={handleInputChange}
                    />{" "}
                    Bank
                  </Label>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="accountType"
                      value="Wallet"
                      onChange={handleInputChange}
                    />{" "}
                    Smart Pay Wallet
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>

          {values.accountType && (
            <Row>
              {values.accountType === "Bank" && (
                <Col lg="12">
                  <strong>Bank Details</strong>
                  <div className="mt-2">
                    <div>
                      <small>Bank Name - {subsInfo.bankName}</small>
                    </div>
                    <div>
                      <small className="mt-2">
                        Account Number - {subsInfo.bban}
                      </small>
                    </div>
                  </div>
                </Col>
              )}
              {values.accountType === "MMO" && (
                <Col>
                  <strong>MMO Details</strong>
                  <div className="mt-2">
                    <div>
                      <small>MMO Name - {subsInfo.mmoName}</small>
                    </div>
                    <div>
                      <small className="mt-2">
                        Account Number - {subsInfo.mmoAccountNumber}
                      </small>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          )}

          <Row className="mt-4">
            <Col lg-6>
              <div>
                <Button
                  className="btn float-left"
                  color="primary"
                  onClick={goBack}
                  disabled={loading}
                >
                  <i
                    class="fas fa-arrow-alt-circle-left"
                    style={{ fontSize: "18px" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Col>
            {values.accountType && (
              <Col lg-6>
                <div>
                  <Button
                    className="btn float-right"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </CardBody>
  );
};

export default PaySmartBills;
