import React from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
  FormText,
} from "reactstrap";
import { postMethod } from "services/httpServices";
import CryptoJS from "crypto-js";
import OTPInput from "react-otp-input";

function FSPCustomerResetPin({ closeModal, customerId }) {
  const [values, setValues] = React.useState({ pin: "", confirmPin: "" });
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const { addToast } = useToasts();

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const validate = (inputs) => {
    let errors = {};
    if (
      !String(inputs.pin)?.trim().length ||
      String(inputs.pin)?.trim().length !== 4
    ) {
      errors.pin = "Pin must be 4 digits long";
    }
    if (inputs.pin !== inputs.confirmPin) {
      errors.confirmPin = "Pin and Confirm pin mismatch";
    }

    return errors;
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const encryptText = (textToEncrypt, key = "PaymentPIN") => {
    const keyBytes = CryptoJS.enc.Utf8.parse(
      key.padEnd(16, " ").substring(0, 16)
    );
    const iv = keyBytes;

    const encrypted = CryptoJS.AES.encrypt(textToEncrypt, keyBytes, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      const payload = {
        pin: encryptText(values.pin),
        id: customerId,
      };
      let errorMsg = "";
      setLoading(true);
      postMethod("/api/FSP/Customer/ResetPin", payload, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfully");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Reset FSP Customer PIN</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-pin"
                        >
                          PIN
                        </label>
                        <OTPInput
                          value={values.pin}
                          onChange={(e) => setValues({ ...values, pin: e })}
                          inputStyle={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "12px",
                            border: "1px solid #ddd",
                            marginRight: "8px",
                          }}
                          inputType="password"
                          numInputs={4}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} />}
                        />
                        {errors.pin && (
                          <div className="text-left text-danger">
                            <small>* {errors.pin}</small>
                          </div>
                        )}{" "}
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-confirmPin"
                        >
                          Confirm PIN
                        </label>
                        <OTPInput
                          value={values.confirmPin}
                          onChange={(e) =>
                            setValues({ ...values, confirmPin: e })
                          }
                          inputStyle={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "12px",
                            border: "1px solid #ddd",
                            marginRight: "8px",
                          }}
                          numInputs={4}
                          inputType="password"
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} />}
                        />
                        {errors.confirmPin && (
                          <div className="text-left text-danger">
                            <small>* {errors.confirmPin}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button
                  type="submit"
                  color="primary"
                  className="mt-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FSPCustomerResetPin;
