import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import {
  checkSpace,
  getUserName,
  postMethod,
} from "../../services/httpServices";
import { getFspCategories } from "appConfig";
import PhoneInput from "react-phone-input-2";
import { getConfirmOptions } from "appConfig";

const initialValue = {
  id: 0,
  fspCategory: "",
  fspName: "",
  regulatoryLicenseNo: "",
  officialEmail: "",
  officialMobileNo: "",
  adminContactEmail: "",
  adminContactMobileNo: "",
  ictContactEmail: "",
  ictContactMobileNo: "",
  posGateway: false,
  onlineGateway: false,
  agentOperations: false,
  apiurl: "",
  userName: "",
  password: "",
  privateKey: "",
  token: "",
  maxSingleTransactionValue: 0,
  extraSecurityDetails: "",
  inboundTransfersFeeTable: "",
  inboundTransfersFSPPays: false,
  inboundTransfersSmartPayPays: false,
  outboundTransfersFeeTable: "",
  outboundTransfersFSPPays: false,
  outboundTransfersSmartPayPays: false,
  agencyServicesFeeTable: "",
  agencyServicesFSPPays: false,
  agencyServicesSmartPayPays: false,
  createBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  isDeleted: false,
  isBlocked: false,
};

const EditFSP = (props) => {
  const [values, setValues] = React.useState(props?.values || initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();
  const [showPin, setShowPin] = React.useState(false);

  const changeState = (obj) => {
    setValues(obj);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeModal = () => {
    props.closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };

  const validate = (inputs) => {
    let urlRegEx =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    let errors = {};
    if (!inputs.fspCategory) {
      errors.fspCategory = "Fsp Category is required";
    }
    if (!inputs.regulatoryLicenseNo) {
      errors.regulatoryLicenseNo = "Regulatory License No  is required";
    }
    if (!inputs.officialEmail) {
      errors.officialEmail = "Official Email is required";
    }
    if (!inputs.officialMobileNo) {
      errors.officialMobileNo = "Official Mobile No year is required";
    }
    if (!inputs.adminContactEmail) {
      errors.adminContactEmail = "Admin Contact Email No is required";
    }
    if (!inputs.adminContactMobileNo) {
      errors.adminContactMobileNo = "Admin Contact Mobile No is required";
    }
    if (!inputs.ictContactEmail) {
      errors.ictContactEmail = "Ict Contact Email is required";
    }
    if (!inputs.ictContactMobileNo) {
      errors.ictContactMobileNo = "Ict Contact Mobile No  is required";
    }
    if (!inputs.apiurl) {
      errors.apiurl = "Api url is required";
    } else if (!urlRegEx.test(inputs.apiurl)) {
      errors.apiurl = "Please enter valid api url";
    }
    if (!inputs.userName) {
      errors.userName = "Username is required";
    }
    if (!inputs.password) {
      errors.password = "Password is required";
    }
    if (!inputs.privateKey) {
      errors.privateKey = "Private Key is required";
    }
    if (!inputs.token) {
      errors.token = "Token is required";
    }
    if (!inputs.maxSingleTransactionValue) {
      errors.maxSingleTransactionValue =
        "maxSingleTransactionValue is required";
    }
    if (!inputs.extraSecurityDetails) {
      errors.extraSecurityDetails = "extraSecurityDetails is required";
    }
    if (!inputs.inboundTransfersFeeTable) {
      errors.inboundTransfersFeeTable = "inboundTransfersFeeTable is required";
    }
    return errors;
  };

  /**======================== API Calls ==========================*/

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      var data = {};
      Object.entries(values).forEach(([key, value]) => {
        if (value === "Yes" || value === "No") {
          data[key] = value === "Yes";
        } else if (key === "maxSingleTransactionValue") {
          data[key] = Number(value);
        } else {
          data[key] = value;
        }
      });
      const payload = {
        ...data,
        createBy: getUserName(),
        createdDate: new Date().toISOString(),
        updatedBy: getUserName(),
        updatedDate: new Date().toISOString(),
        isDeleted: false,
        isBlocked: false,
      };
      let errorMsg = "";
      setLoading(true);
      postMethod("/api/FSP/Update", payload, "post")
        .then((res) => {
          setLoading(false);
          if (typeof res.data === "string") {
            notifySuccess(res.data || "Saved Successfully");
            closeModal();
            return;
          }
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfully");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add FSP</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <h4 className="mb-2">Profile Information</h4>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-fsp-category"
                        >
                          FSP Category
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-fsp-category"
                          placeholder="FSP Category"
                          type="select"
                          name="fspCategory"
                          required
                          value={values.fspCategory}
                          onChange={handleInputChange}
                        >
                          {getFspCategories()}
                        </Input>
                        {errors.fspCategory && (
                          <div className="text-left text-danger">
                            <small>* {errors.fspCategory}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-fspName"
                        >
                          FSP Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-fspName"
                          placeholder="FSP Name"
                          type="text"
                          name="fspName"
                          required
                          value={values.fspName}
                          onChange={handleInputChange}
                        />
                        {errors.fspName && (
                          <div className="text-left text-danger">
                            <small>* {errors.fspName}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-regulatoryLicenseNo"
                        >
                          Regulatory License No
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-regulatoryLicenseNo"
                          placeholder="Regulatory License No"
                          type="text"
                          name="regulatoryLicenseNo"
                          required
                          value={values.regulatoryLicenseNo}
                          onChange={handleInputChange}
                        />
                        {errors.regulatoryLicenseNo && (
                          <div className="text-left text-danger">
                            <small>* {errors.regulatoryLicenseNo}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-officialEmail"
                        >
                          Official Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-officialEmail"
                          placeholder="jesse@example.com"
                          type="email"
                          name="officialEmail"
                          disabled
                          value={values.officialEmail}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              officialEmail: e.target.value,
                              userName: e.target.value,
                            });
                          }}
                        />
                        {errors.officialEmail && (
                          <div className="text-left text-danger">
                            <small>* {errors.officialEmail}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-officialMobileNo"
                        >
                          Official Mobile No
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="officialMobileNo"
                          value={values.officialMobileNo}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          disabled
                          onChange={(e) => {
                            setValues({ ...values, officialMobileNo: e });
                          }}
                        />
                        {errors.officialMobileNo && (
                          <div className="text-left text-danger">
                            <small>* {errors.officialMobileNo}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-adminContactEmail"
                        >
                          Admin Contact Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-adminContactEmail"
                          required
                          placeholder="jesse@example.com"
                          type="email"
                          name="adminContactEmail"
                          value={values.adminContactEmail}
                          onChange={handleInputChange}
                        />
                        {errors.adminContactEmail && (
                          <div className="text-left text-danger">
                            <small>* {errors.adminContactEmail}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-adminContactMobileNo"
                        >
                          Admin Contact Mobile No
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="adminContactMobileNo"
                          value={values.adminContactMobileNo}
                          required
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          onChange={(e) => {
                            setValues({ ...values, adminContactMobileNo: e });
                          }}
                        />
                        {errors.adminContactMobileNo && (
                          <div className="text-left text-danger">
                            <small>* {errors.adminContactMobileNo}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-ictContactEmail"
                        >
                          ICT Contact Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-ictContactEmail"
                          placeholder="jesse@example.com"
                          type="email"
                          required
                          name="ictContactEmail"
                          value={values.ictContactEmail}
                          onChange={handleInputChange}
                        />
                        {errors.ictContactEmail && (
                          <div className="text-left text-danger">
                            <small>* {errors.ictContactEmail}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-ictContactMobileNo"
                        >
                          ICT Contact Mobile No
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="ictContactMobileNo"
                          value={values.ictContactMobileNo}
                          // onlyCountries={MOBILE_ONLY_COUNTRY}
                          countryCodeEditable={false}
                          onChange={(e) => {
                            setValues({ ...values, ictContactMobileNo: e });
                          }}
                          required
                        />
                        {errors.ictContactMobileNo && (
                          <div className="text-left text-danger">
                            <small>* {errors.ictContactMobileNo}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <div>
                  <h4 className="mb-2">
                    Services Information (Select As Applicable)
                  </h4>
                  <Row>
                    <Col lg="6">
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="posGateway"
                          checked={values.posGateway}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              posGateway: e.target.checked,
                            })
                          }
                        />
                        <Label check>POS Gateway</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="onlineGateway"
                          checked={values.onlineGateway}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              onlineGateway: e.target.checked,
                            })
                          }
                        />
                        <Label check>Online Gateway</Label>
                      </FormGroup>
                      {/* <FormGroup check inline>
                        <Input
                          type="checkbox"
                          name="agentOperations"
                          checked={values.agentOperations}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              agentOperations: e.target.checked,
                            })
                          }
                        />
                        <Label check>Agent Operations</Label>
                      </FormGroup> */}
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <div>
                  <h4 className="mb-2">Integration and Security Parameters</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-apiurl"
                          >
                            Api Url
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-apiurl"
                            placeholder="Api Integration Url"
                            type="text"
                            required
                            name="apiUrl"
                            value={values.apiurl}
                            onChange={(e) =>
                              setValues({ ...values, apiurl: e.target.value })
                            }
                          />
                          {errors.apiUrl && (
                            <div className="text-left text-danger">
                              <small>* {errors.apiUrl}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-privateKey"
                          >
                            Private Key
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-privateKey"
                            placeholder="Private key"
                            type="text"
                            name="privateKey"
                            required
                            value={values.privateKey}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                privateKey: e.target.value,
                              })
                            }
                          />
                          {errors.privateKey && (
                            <div className="text-left text-danger">
                              <small>* {errors.privateKey}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-token"
                          >
                            Token
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-token"
                            type="text"
                            name="token"
                            placeholder="Token"
                            required
                            value={values.token}
                            onChange={(e) =>
                              setValues({ ...values, token: e.target.value })
                            }
                          />
                          {errors.token && (
                            <div className="text-left text-danger">
                              <small>* {errors.token}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-maxSingleTransactionValue"
                          >
                            Maximum Single Transaction Value
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-maxSingleTransactionValue"
                            type="number"
                            required
                            placeholder="Enter maxSingleTransactionValue"
                            name="maxSingleTransactionValue"
                            value={values.maxSingleTransactionValue}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                maxSingleTransactionValue: e.target.value,
                              })
                            }
                          />
                          {errors.maxSingleTransactionValue && (
                            <div className="text-left text-danger">
                              <small>
                                * {errors.maxSingleTransactionValue}
                              </small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-extraSecurityDetails"
                          >
                            Anything else to add here for extra security?
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-extraSecurityDetails"
                            type="text"
                            placeholder="Enter Extra Security Details"
                            name="extraSecurityDetails"
                            required
                            value={values.extraSecurityDetails}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                extraSecurityDetails: e.target.value,
                              })
                            }
                          />
                          {errors.extraSecurityDetails && (
                            <div className="text-left text-danger">
                              <small>* {errors.extraSecurityDetails}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-userName"
                          >
                            User Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-userName"
                            type="email"
                            placeholder="Enter Username"
                            name="basicAuthUName"
                            value={values.userName}
                            disabled
                            onChange={(e) =>
                              setValues({
                                ...values,
                                userName: e.target.value,
                              })
                            }
                          />
                          {errors.userName && (
                            <div className="text-left text-danger">
                              <small>* {errors.userName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >
                            Password
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-password"
                              type={showPin ? "password" : "text"}
                              name="basicAuthpassword"
                              placeholder="Enter Password"
                              value={values.password}
                              required
                              disabled
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  password: e.target.value,
                                })
                              }
                              autoComplete="off"
                            />
                            {!showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye-slash"
                                    onClick={() => setShowPin(true)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}

                            {showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() => setShowPin(false)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                          {errors.password && (
                            <div className="text-left text-danger">
                              <small>* {errors.password}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
                <hr className="my-4" />
                <div>
                  <h4 className="mb-2">Commercials</h4>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Transaction Type</th>
                        <th>Applicable Fees Table</th>
                        <th>FSP Pays (Y/N)</th>
                        <th>Smart Pay Pays (Y/N)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Inbound Transfers</td>

                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-inboundTransfersFeeTable"
                            type="text"
                            required
                            placeholder="Enter Inbound Transfers Fee"
                            name="inboundTransfersFeeTable"
                            value={values.inboundTransfersFeeTable}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                inboundTransfersFeeTable: e.target.value,
                              })
                            }
                          />
                          {errors.inboundTransfersFeeTable && (
                            <div className="text-left text-danger">
                              <small>* {errors.inboundTransfersFeeTable}</small>
                            </div>
                          )}
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-inboundTransfersFSPPays"
                            placeholder="FSP Pays"
                            type="select"
                            name="inboundTransfersFSPPays"
                            required
                            value={
                              values.inboundTransfersFSPPays ? "Yes" : "No"
                            }
                            onChange={handleInputChange}
                          >
                            {getConfirmOptions()}
                          </Input>
                          {errors.inboundTransfersFSPPays && (
                            <div className="text-left text-danger">
                              <small>* {errors.inboundTransfersFSPPays}</small>
                            </div>
                          )}
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-inboundTransfersSmartPayPays"
                            placeholder="SmartyPay pays"
                            type="select"
                            name="inboundTransfersSmartPayPays"
                            required
                            value={
                              values.inboundTransfersSmartPayPays ? "Yes" : "No"
                            }
                            onChange={handleInputChange}
                          >
                            {getConfirmOptions()}
                          </Input>
                          {errors.inboundTransfersSmartPayPays && (
                            <div className="text-left text-danger">
                              <small>
                                * {errors.inboundTransfersSmartPayPays}
                              </small>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Outbound Transfers</td>
                        <td>
                          {" "}
                          <Input
                            className="form-control-alternative"
                            id="input-outboundTransfersFeeTable"
                            type="text"
                            required
                            placeholder="Enter Outbound Transfers Fee"
                            name="outboundTransfersFeeTable"
                            value={values.outboundTransfersFeeTable}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                outboundTransfersFeeTable: e.target.value,
                              })
                            }
                          />
                          {errors.outboundTransfersFeeTable && (
                            <div className="text-left text-danger">
                              <small>
                                * {errors.outboundTransfersFeeTable}
                              </small>
                            </div>
                          )}
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-outboundTransfersFSPPays"
                            placeholder="FSP Pays"
                            type="select"
                            name="outboundTransfersFSPPays"
                            required
                            value={
                              values.outboundTransfersFSPPays ? "Yes" : "No"
                            }
                            onChange={handleInputChange}
                          >
                            {getConfirmOptions()}
                          </Input>
                          {errors.outboundTransfersFSPPays && (
                            <div className="text-left text-danger">
                              <small>* {errors.outboundTransfersFSPPays}</small>
                            </div>
                          )}
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-outboundTransfersSmartPayPays"
                            placeholder="SmartyPay pays"
                            type="select"
                            name="outboundTransfersSmartPayPays"
                            required
                            value={
                              values.outboundTransfersSmartPayPays
                                ? "Yes"
                                : "No"
                            }
                            onChange={handleInputChange}
                          >
                            {getConfirmOptions()}
                          </Input>
                          {errors.outboundTransfersSmartPayPays && (
                            <div className="text-left text-danger">
                              <small>
                                * {errors.outboundTransfersSmartPayPays}
                              </small>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Agency Services</td>
                        <td>
                          {" "}
                          <Input
                            className="form-control-alternative"
                            id="input-agencyServicesFeeTable"
                            type="text"
                            required
                            placeholder="Enter Agency Services Fee"
                            name="agencyServicesFeeTable"
                            value={values.agencyServicesFeeTable}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                agencyServicesFeeTable: e.target.value,
                              })
                            }
                          />
                          {errors.agencyServicesFeeTable && (
                            <div className="text-left text-danger">
                              <small>* {errors.agencyServicesFeeTable}</small>
                            </div>
                          )}
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-agencyServicesFSPPays"
                            placeholder="FSP Pays"
                            type="select"
                            name="agencyServicesFSPPays"
                            required
                            value={values.agencyServicesFSPPays ? "Yes" : "No"}
                            onChange={handleInputChange}
                          >
                            {getConfirmOptions()}
                          </Input>
                          {errors.agencyServicesFSPPays && (
                            <div className="text-left text-danger">
                              <small>* {errors.agencyServicesFSPPays}</small>
                            </div>
                          )}
                        </td>
                        <td>
                          <Input
                            className="form-control-alternative"
                            id="input-agencyServicesSmartPayPays"
                            placeholder="SmartyPay pays"
                            type="select"
                            name="agencyServicesSmartPayPays"
                            required
                            value={
                              values.agencyServicesSmartPayPays ? "Yes" : "No"
                            }
                            onChange={handleInputChange}
                          >
                            {getConfirmOptions()}
                          </Input>
                          {errors.agencyServicesSmartPayPays && (
                            <div className="text-left text-danger">
                              <small>
                                * {errors.agencyServicesSmartPayPays}
                              </small>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Button
                  type="submit"
                  color="primary"
                  className="mt-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditFSP;
