import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { API_ENDPOINTS } from "appConfig";
import UserGif from "../../assets/img/brand/verified.png";
import OTPInput from "react-otp-input";

const initialValue = {
  userId: "",
  otp: "",
  otpType: "ChangePIN",
  password: "",
  pin: "",
  paymentAmount: 0,
  confirmPin: "",
};

const WhiteListCustomer = (props) => {
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { addToast } = useToasts();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setValues({ ...values, userId: id });
    }
  }, []);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.otp?.trim()) {
      errors.otp = "OTP  is required";
    }
    if (!inputs.pin?.trim()) {
      errors.pin = "Pin  is required";
    }
    if (inputs.pin?.trim().length < 3) {
      errors.pin = "PIN must be 4 digits or longer.";
    }
    if (!inputs.confirmPin?.trim()) {
      errors.confirmPin = "Confirm Pin is required";
    }
    if (inputs.confirmPin?.trim() !== inputs?.pin?.trim()) {
      errors.confirmPin = "Pin and Confirm Pin Mismatch";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    setLoading(true);
    const { confirmPin, ...rest } = values;
    axios
      .post(
        `${API_ENDPOINTS}/api/Auth/VerifyCustomerOTP`,
        { ...rest },
        {
          headers: {
            PIN1: values.pin,
            PIN2: values.confirmPin,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.responseCode === "200") {
          setIsSuccess(true);
        } else {
          const errorMsg = "You have entered the wrong OTP. Please try again.";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsSuccess(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  return (
    <>
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col xl="6">
            <Card className="bg-default shadow rounded">
              <CardHeader
                className="border-0 d-flex justify-content-center card-header-border"
                style={{ fontWeight: "bold", marginTop: "12px" }}
              >
                Customer verification
              </CardHeader>
              <CardBody style={{ background: "white" }}>
                {isSuccess ? (
                  <>
                    <div className="d-flex flex-column justify-content-center align-items-center w-100 gap-4">
                      <img src={UserGif} alt="verify" className="h-25 w-25" />
                      <h4 style={{ marginTop: "8px", fontWeight: "bolder" }}>
                        Verified!
                      </h4>
                      <p className="text-center">
                        You are whitelisted on the SmartPay system. You can pay
                        at any SmartPay POS machine. Happy paying!
                      </p>
                      <Button
                        color="primary"
                        onClick={() => window.location.replace("/")}
                      >
                        Ok
                      </Button>
                    </div>
                  </>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    {values.userId && (
                      <div className="pt-4 d-flex flex-column justify-content-center align-items-center">
                        <>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-otp"
                                >
                                  OTP *
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-otp"
                                  placeholder="OTP"
                                  type="text"
                                  name="otp"
                                  value={values.otp}
                                  onChange={handleInputChange}
                                />
                                {errors.otp && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.otp}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-pin"
                                >
                                  PIN *
                                </label>
                                {/* <Input
                                  className="form-control-alternative"
                                  id="input-pin"
                                  placeholder="PIN"
                                  type="password"
                                  name="pin"
                                  value={values.pin}
                                  onChange={handleInputChange}
                                />
                                {errors.pin && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.pin}</small>
                                  </div>
                                )} */}
                                <OTPInput
                                  value={values.pin}
                                  onChange={(e) =>
                                    setValues({ ...values, pin: e })
                                  }
                                  inputStyle={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius:"12px",
                                    border: "1px solid #ddd",
                                    marginRight:"8px"
                                  }}
                                  inputType="password"
                                  numInputs={4}
                                  renderSeparator={<span>{" "}</span>}
                                  renderInput={(props) => <input {...props} />}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-confirmPin"
                                >
                                  Confirm PIN *
                                </label>
                                {/* <Input
                                  className="form-control-alternative"
                                  id="input-confirmPin"
                                  placeholder="Confirm PIN"
                                  type="password"
                                  name="confirmPin"
                                  value={values.confirmPin}
                                  onChange={handleInputChange}
                                /> */}
                                <OTPInput
                                  value={values.confirmPin}
                                  onChange={(e) =>
                                    setValues({ ...values, confirmPin: e })
                                  }
                                  inputStyle={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius:"12px",
                                    border: "1px solid #ddd",
                                    marginRight:"8px"
                                  }}
                                  numInputs={4}
                                  inputType="password"
                                  renderSeparator={<span>{" "}</span>}
                                  renderInput={(props) => <input {...props} />}
                                />
                                {errors.confirmPin && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.confirmPin}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                        <Button
                          type="submit"
                          color="primary"
                          className="mt-4 float-right"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    )}
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhiteListCustomer;
