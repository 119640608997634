
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalHeader,
  Col
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import Merchant from "./add-merchant";
import { useToasts } from 'react-toast-notifications';
import { btnCss, iconCss } from '../../appConfig';
import { getMethod, postMethod, handleError } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import ViewMerchant from "./view-merchant";
import EditMerchant from "./edit-merchant";
import { fetchSettlementBank } from "views/finance-user/action";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';
import { getSortCls } from "services/util";
import { getPrivileges } from "services/util";

const sortIS = {
  sortName: 0,
  sortEmail: 0,
  sortPhone: 0,
  sortBban: 0
};

const MerchantList = (props) => {

  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [merchantObj, setMerchantObj] = React.useState(null);
  const [openEdit, setOpenEditModal] = React.useState(false);
  const [editObj, setEditObj] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [merchantlist, setMerchantList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [cBanks, setCbanks] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [refresh, setRefresh] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  const { addToast } = useToasts();
  const privileges = getPrivileges('merchant');

  React.useEffect(() => {
    // fetch mobilemoney operator
    // fetchMerchant();
    props.fetchSettlementBankInfo();
  }, []);

  React.useEffect(() => {
    if (
      props?.finance?.settlementBankInfo &&
      props?.finance?.settlementBankInfo.list &&
      props?.finance?.settlementBankInfo.list.length
    ) {
      setCbanks(props?.finance?.settlementBankInfo.list);
    }
  }, [props?.finance?.settlementBankInfo.list]);


  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        fetchMerchant();
      }
    }
  }, [searchText]);


  React.useEffect(() => {
    fetchMerchant();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchMerchant();
      }
    }
  }, [sort]);

  React.useEffect(() => {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchMerchant();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    fetchMerchant();
  }

  const showMerchant = (obj) => {
    setMerchantObj(obj);
    setView(true);
  }

  const closeViewModal = () => {
    setView(false);
    // setMerchantObj(null);
  }

  const closeEditModal = () => {
    setOpenEditModal(false);
    // setEditObj(null);
    fetchMerchant();
  }

  const openDelete = (mmo) => {
    setDeleteObj(mmo);
    setOpenDelModal(true);
  }

  const closeDeleteModal = () => {
    setDeleteObj(null);
    setOpenDelModal(false);
  }


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const fetchMerchant = async () => {
    let errorMsg = '';
    setLoading(true);
    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortName == "1") {
      data['SortingOn'] = 'Name';
      data['IsDescending'] = false;
    } else if (sort.sortName == "2") {
      data['SortingOn'] = 'Name';
      data['IsDescending'] = true;
    } else if (sort.sortEmail == "1") {
      data['SortingOn'] = 'Email';
      data['IsDescending'] = false;
    } else if (sort.sortEmail == "2") {
      data['SortingOn'] = 'Email';
      data['IsDescending'] = true;
    } else if (sort.sortPhone == "1") {
      data['SortingOn'] = 'MobNumber';
      data['IsDescending'] = false;
    } else if (sort.sortPhone == "2") {
      data['SortingOn'] = 'MobNumber';
      data['IsDescending'] = true;
    } else if (sort.sortBban == "1") {
      data['SortingOn'] = 'Bban';
      data['IsDescending'] = false;
    } else if (sort.sortBban == "2") {
      data['SortingOn'] = 'Bban';
      data['IsDescending'] = true;
    }
    postMethod('/api/MerchantProfile/AllMerchant', data, 'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setMerchantList(res.data.result.list);
            setFilteredList(res.data.result.list)
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Something went wrong!, Please call 3737');
      })
  }

  const fetchMerchantById = (id, type) => {
    let errorMsg = '';
    postMethod('/api/MerchantProfile/Edit/' + id, {}, 'get')
      .then(res => {
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (type == 'edit') {
              setEditObj(res.data.result);
              setOpenEditModal(true);
            } else if (type == 'view') {
              showMerchant(res.data.result);
            }

          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            // setSError(errorMsg);
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            // setSError(errorMsg);
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          // setSError(errorMsg);
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        // setSError();
        notifyFailed('Something went wrong!, Please call 3737');
      });
  }


  const deleteMerchant = () => {
    let errorMsg = '';
    setModalLoading(true);
    let url = '/api/User/block/' + deleteObj.userProfileId;
    postMethod(url, {}, 'post')
      .then(res => {
        setModalLoading(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            let text = deleteObj.isBlocked ? 'Merchant unblocked successfully' :
              'Merchant blocked succsessfully';
            notifySuccess(text);
            closeDeleteModal();
            fetchMerchant();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setModalLoading(false);
        errorMsg = 'Something went wrong!, Please call 3737';
        notifyFailed(errorMsg);
      });
  }

  const handleClick = (e, index) => {

    e.preventDefault();
    setCurrentPage(index);

  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    // fetchSubs();
  }


  const getSearch = (val) => {
    setSearchText(val);
    // if (searchText.length == 0) {
    //   fetchMerchant()
    // }
  };

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const getBlockText = () => {
    let str = 'block';
    if (deleteObj.isBlocked) {
      str = 'unblock';
    }

    return `Do you want to ${str} ${deleteObj.name} merchant ?`;
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={e => { e.preventDefault(); }}>
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;

                  {privileges.add &&
                    <Button className="my-4" color="primary"
                      onClick={openModal}>
                      Add Merchant
                    </Button>}

                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>


                </Form>


              </CardHeader>

              <Modal isOpen={open}
                // fade={false}
                style={{ width: "70vw", maxWidth: "70vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  <Merchant closeModal={closeModal} cBanks={cBanks} />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={view}
                style={{ width: "70vw", maxWidth: "70vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  <ViewMerchant closeModal={closeViewModal} {...merchantObj}
                    cBanks={cBanks} />
                </ModalBody>
              </Modal>


              <Modal
                isOpen={openEdit}
                style={{ width: "70vw", maxWidth: "70vw" }}
                cBanks={cBanks}
              >
                <ModalBody style={{ padding: 0 }}>
                  <EditMerchant closeModal={closeEditModal} {...editObj}
                    cBanks={cBanks} />
                </ModalBody>
              </Modal>

              <Modal isOpen={openDelModal} className="modal-sm">

                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    {deleteObj && deleteObj.isBlocked ? 'Unblock Merchant' : 'Block Merchant'}
                  </h3>
                </ModalHeader>

                <ModalBody>
                  <div>
                    {deleteObj && deleteObj.bankName ?
                      <div>
                        <p className="text-left">
                          {getBlockText()}
                        </p>
                        <div className="text-right">
                          <Button color="primary" onClick={deleteMerchant} disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeDeleteModal} disabled={modalLoading}>
                            No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>


              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortName, 'sortName')}
                    >
                      Merchant Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortName)}
                          onClick={() => sortHandle(sort.sortName, 'sortName')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortEmail, 'sortEmail')}>
                      Email
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortEmail)}
                          onClick={() => sortHandle(sort.sortEmail, 'sortEmail')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortPhone, 'sortPhone')}>
                      Phone
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortPhone)}
                          onClick={() => sortHandle(sort.sortPhone, 'sortPhone')}
                        />
                      </button>
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList.length > 0 && filteredList.map((mmo, i) => {
                    return (
                      <tr>
                        <th scope="row">
                          {mmo.name}
                        </th>
                        <td>{mmo.email}</td>
                        <td>({mmo.mobileCode}) - {mmo.phoneNumber}</td>
                        <td>
                          {privileges.view && <Button
                            color="primary"
                            onClick={() => fetchMerchantById(mmo.id, 'view')} size="sm"
                            style={btnCss}
                            data-toggle="tooltip"
                            title="View"
                          >
                            <i class="fas fa-eye fa-lg" style={iconCss}></i>
                          </Button>}

                          {privileges.edit && <Button
                            color="primary"
                            onClick={() => fetchMerchantById(mmo.id, 'edit')}
                            size="sm"
                            style={btnCss}
                            data-toggle="tooltip"
                            title="Edit">
                            <i class="fas fa-edit fa-lg" style={iconCss}></i>
                          </Button>}

                          {!mmo.isBlocked && privileges.block &&
                            <Button
                              color="primary"
                              onClick={() => openDelete(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Block">
                              <i class="fa fa-eye-slash fa-lg" style={iconCss}></i>
                            </Button>}
                          {mmo.isBlocked && privileges.unblock &&
                            <Button
                              color="primary"
                              onClick={() => openDelete(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Unblock">
                              <i class="fa fa-eye-slash fa-lg" style={{ color: "#FF0000" }}></i>
                            </Button>
                          }

                          {/* {!mmo.isBlocked ?
                            <Button
                              color="primary"
                              onClick={() => openDelete(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Block">
                              <i class="fa fa-eye-slash fa-lg" style={iconCss}></i>
                            </Button>
                            :
                            <Button
                              color="primary"
                              onClick={() => openDelete(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Unblock">
                              <i class="fa fa-eye-slash fa-lg" style={{ color: "#FF0000" }}></i>
                            </Button>
                          } */}

                        </td>
                      </tr>

                    )
                  }
                  )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
                {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state }
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettlementBankInfo: () => dispatch(fetchSettlementBank()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantList);
