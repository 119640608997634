/* eslint-disable react/no-unescaped-entities */
import SA1Img from "../../assets/kb/SA1.png";
import SA3Img from "../../assets/kb/SA3.png";
import SA4Img from "../../assets/kb/SA4.png";
import SA5Img from "../../assets/kb/SA5.png";
import SA6Img from "../../assets/kb/SA6.png";
import SA7Img from "../../assets/kb/SA7.png";
import SA8Img from "../../assets/kb/SA8.png";
import SubAgentImg from "../../assets/kb/SubAgent.png";

const SubAgentJourney = () => {
  return (
    <div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl  mb-2">Introduction</h4>
        <p>
          Welcome to the role of Sub Agent within the SmartPay platform. As a
          Sub Agent, you have a crucial role in facilitating financial
          transactions and offering essential services. This guide will provide
          you with insights into your responsibilities and capabilities.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Top Up Money to Subscribers
        </h4>
        <p>
          One of your primary functions is to Top the wallet money to
          Subscribers within the SmartPay network. This process involves:
        </p>
        <ol className="list-decimal w-full my-2">
          <li>Initiating a money transfer from your dashboard.</li>
          <li>Selecting the desired Subscriber to receive the funds.</li>
          <li>
            Providing the required details, including the amount and any
            necessary verification.
          </li>
          <li>
            Completing the transfer, ensuring the funds are accurately received
            by the intended Subscriber.
            <div className="d-flex justify-content-center my-3">
              <img src={SA6Img} alt="transfer " className="w-100" />
            </div>
          </li>
        </ol>
      </div>
      <div>
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">Cash Out to Super Agents</h4>
        <p>
          As a Sub Agent, you have the ability to perform cash outs to Super
          Agents. This feature allows you to return funds to the Super Agents as
          needed.
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={SA1Img} alt="Sub Agent" className="w-100" />
        </div>
      </div>
      <div>
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">Transaction Monitoring</h4>
        <p>
          Your Sub Agent account provides access to a transaction monitoring
          feature, allowing you to review and track all transactions within your
          network.
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={SA7Img} alt="monitoring" className="w-100" />
        </div>
      </div>
      <div>
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">Payment Services</h4>
        <p>
          Within your role as a Sub Agent, you can offer various payment
          services to Subscribers:
        </p>
        <ul className="list-disc w-full my-2">
          <li>
            EDSA - Electricity Pin Purchase
            <div className="d-flex justify-content-center my-3">
              <img src={SA5Img} alt="EDSA" className="w-100" />
            </div>
          </li>
          <li>
            SmartKorpor Services
            <div className="d-flex justify-content-center my-3">
              <img src={SA4Img} alt="SmartKorpor" className="w-100" />
            </div>
          </li>
          <li>
            Betting Platform Tickets
            <div className="d-flex justify-content-center my-3">
              <img src={SA3Img} alt="Betting" className="w-100" />
            </div>
          </li>
        </ul>
      </div>
      <div>
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">Dashboard Insights</h4>
        <p>
          Your dashboard provides a comprehensive overview of your financial
          activities:
        </p>
        <ul className="list-disc w-full my-2">
          <li>Transfer: Initiate money transfers to Subscribers.</li>
          <li>Receive: Receive funds from Super Agents and other sources.</li>
          <li>
            Other Insights: Access key information related to your transactions
            and activities.
            <div className="d-flex justify-content-center my-3">
              <img src={SA8Img} alt="Insights" className="w-100" />
            </div>
          </li>
        </ul>
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary mb-2">Sub-Agent flowchart</h4>
      <div className="d-flex justify-content-center my-3">
        <img src={SubAgentImg} alt="Sub-Agent flowchart "  className="w-100"/>
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary mb-2">Conclusion</h4>
      <p>
        As a Sub Agent, you play an essential role in the SmartPay financial
        ecosystem. Your responsibilities include transferring money to
        Subscribers, performing cash outs, offering payment services, and
        overseeing transactions. Utilizing your dashboard and provided
        functionalities, you contribute to the seamless functioning of the
        platform.
      </p>
    </div>
  );
};

export default SubAgentJourney;
