/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { useEffect, useState } from "react";

// reactstrap components
import { Card, CardHeader, Table, Row, Button, Col } from "reactstrap";
// core components
import { useToasts } from "react-toast-notifications";
import { postMethod } from "../../services/httpServices";

const CardsList = (props) => {
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);

  const { addToast } = useToasts();

  useEffect(() => {
    if (props?.customerId) {
      fetchNFC(props?.customerId);
    }
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchNFC = async (customerId) => {
    let errorMsg = "";
    setLoading(true);

    postMethod(
      "/api/POS/Search/GetSmartPayCardByCustomerId?Id=" + customerId,
      {},
      "post"
    )
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setFilteredList(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  return (
    <>
      {/* Page content */}
      <div>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Customer Cards List</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={props.closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Card Name</th>
                    <th scope="col">ID</th>
                    <th scope="col">Serial Number</th>
                    <th scope="col">Status</th>
                    <th scope="col">createdBy</th>
                    <th scope="col">createdDate</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList.length > 0 ? (
                    filteredList.map((mmo, i) => {
                      return (
                        <tr>
                          <th scope="row">{mmo.nfcCardName}</th>
                          <td>{mmo.nfcCardID}</td>
                          <td>{mmo.nfcCardClientID}</td>
                          <td className="text-center">
                            <i
                              class={`fas fa-circle ${
                                !mmo.customerId ? "text-red" : "text-green"
                              }`}
                            ></i>
                          </td>
                          <td>{mmo.createdBy}</td>

                          <td>
                            {new Date(mmo.createdDate).toLocaleDateString()}
                          </td>

                          {/* <td>
                            {privileges.edit && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  setEditObj(mmo);
                                  setOpenEditModal(true);
                                }}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Edit"
                              >
                                <i
                                  class="fas fa-edit fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            )}
                            {!mmo.isBlock ? (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Block"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Unblock"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={{ color: "#FF0000" }}
                                ></i>
                              </Button>
                            )}
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <th
                        scope="row"
                        colSpan={"6"}
                        className="text-center w-100"
                      >
                        {" "}
                        {loading ? "Loading..." : "No Cards Available!"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CardsList;
