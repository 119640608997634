import React from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";

const BetSalonePaymentTab = ({
  values,
  handleSubmit,
  handleInputChange,
  formState,
  errors,
  currencyTypes,
  langs,
  userResponse,
  setValues,
  initialValue,
  loading,
  setFormState,
}) => {
  return (
    <div className="mt-2">
      <Form onSubmit={handleSubmit} autoComplete="off">
        <div className="pl-lg-4">
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-phone">
                  Phone Number
                </label>
                <Input
                  className="form-control-alternative"
                  // country={"sl"}
                  id="input-phone"
                  name="phone"
                  type="tel"
                  value={values.phone}
                  // onlyCountries={MOBILE_ONLY_COUNTRY}
                  // countryCodeEditable={false}
                  onChange={handleInputChange}
                  placeholder="Phone number"
                  required
                  maxLength={10}
                  minLength={5}
                  disabled={formState === 2}
                />
                {errors.phone && (
                  <div className="text-left text-danger">
                    <small>* {errors.phone}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label htmlFor="currency" className="form-control-label">
                  Currency
                </label>
                <Input
                  type="select"
                  id="currency"
                  className="form-control-alternative"
                  name="currency"
                  value={values.currency}
                  onChange={handleInputChange}
                  required
                  disabled={formState === 2}
                >
                  <option key="" value="">
                    -----Select Currently ------
                  </option>
                  {currencyTypes?.map((c) => (
                    <option key={c.key} value={c.label}>
                      {c.label}
                    </option>
                  ))}
                </Input>

                {errors.currency && (
                  <div className="text-left text-danger">
                    <small>* {errors.currency}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label htmlFor="lang" className="form-control-label">
                  Language
                </label>
                <Input
                  type="select"
                  id="lang"
                  className="form-control-alternative"
                  name="lang"
                  value={values.lang}
                  onChange={handleInputChange}
                  required
                  disabled={formState === 2}
                >
                  <option key="" value="">
                    -----Select Language ------
                  </option>
                  {langs?.map((c) => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </Input>

                {errors.lang && (
                  <div className="text-left text-danger">
                    <small>* {errors.lang}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          {formState === 2 && (
            <>
              {userResponse?.id && (
                <>
                  <p>Name: {userResponse?.name}</p>
                  <p>Surname: {userResponse?.surname}</p>
                </>
              )}
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-amount"
                    >
                      Amount
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-amount"
                      name="amount"
                      type="number"
                      value={values.amount}
                      // onlyCountries={MOBILE_ONLY_COUNTRY}
                      // countryCodeEditable={false}
                      onChange={handleInputChange}
                      placeholder="Amount"
                      required
                      maxLength={50}
                    />
                    {errors.amount && (
                      <div className="text-left text-danger">
                        <small>* {errors.amount}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-pin">
                      Pin
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-pin"
                      name="pin"
                      type="tel"
                      value={values.pin}
                      onChange={handleInputChange}
                      placeholder="Pin"
                      required
                      maxLength={10}
                    />
                    {errors.pin && (
                      <div className="text-left text-danger">
                        <small>* {errors.pin}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <Row>
            {formState === 2 && (
              <Col>
                <div className="float-left">
                  <Button
                    className="btn float-right"
                    color="primary"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      setValues(initialValue);
                      setFormState(1);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            )}
            <Col>
              <div>
                <Button
                  className="btn float-right"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default BetSalonePaymentTab;
