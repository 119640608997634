import { 
  FETCH_SETTLEMENT_BANK, RESET_SETTLEMENT_BANK,
  SETTLEMENT_BANK_ERROR, SET_SETTLEMENT_BANK,
} from "./constant";

export function fetchSettlementBank() {
  return {
    type: FETCH_SETTLEMENT_BANK
  }; 
}

export function setSettlementBank(data) {
  return {
    type: SET_SETTLEMENT_BANK,
    payload: data
  };
}

export function settlementBankError(data) {
  return {
    type: SETTLEMENT_BANK_ERROR,
    payload: data
  };
}

export function resetSettleError() {
  return {
    type: RESET_SETTLEMENT_BANK
  };
}