/* eslint-disable react/no-unescaped-entities */

import LoginImg from "../../assets/kb/Login.png";
import SelfRegisterImg from "../../assets/kb/SelfRegister.png";
import OTPEmail from "../../assets/kb/OTPEmail.png";
import SelfRegOTP from "../../assets/kb/SelfRegOTP.png";
import SleftRegOTPVerify from "../../assets/kb/SleftRegOTPVerify.png";
import FA1Img from "../../assets/kb/1FA and bank.png";
import PreviewImg from "../../assets/kb/Preview.png";
import regsuccessfullImg from "../../assets/kb/regsuccessfull.png";
import credentialemailImg from "../../assets/kb/credentialemail.png";
import SubscriberFlowImg from "../../assets/kb/SubscriberFlow.png";
import Img1 from "../../assets/kb/1.png";
import Img2 from "../../assets/kb/2.png";
import Img3 from "../../assets/kb/3.png";
import Img4 from "../../assets/kb/4.png";
import Img6 from "../../assets/kb/6.png";
import Img7 from "../../assets/kb/7.png";
import Img8 from "../../assets/kb/8.png";
import Img9 from "../../assets/kb/9.png";
import Img10 from "../../assets/kb/10.png";
import Img11 from "../../assets/kb/11.png";
import Img12 from "../../assets/kb/12.png";
import Img13 from "../../assets/kb/13.png";
import Img14 from "../../assets/kb/14.png";

import { Link } from "react-router-dom";

const SubscriberJourney = () => {
  return (
    <div>
      <h4 className="font-bold text-bluePrimary  mb-3">User Process</h4>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl   mb-2">
          Welcome to the SmartPay Self-Signup Process!
        </h4>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 1: Access SmartPay
        </h4>
        <p>
          Visit our website at{" "}
          <Link
            to={"https://smartpaysl.com/"}
            target="_blank"
            className="underline text-blue-500"
          >
            https://smartpaysl.com
          </Link>{" "}
          to get started.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Step 2: Click "Signup"
        </h4>
        <p>
          On the login dialog, you'll find the "Signup" button located at the
          bottom left corner. Click on it to begin your signup journey.
        </p>
        <div className="d-flex justify-content-center my-3 ">
          <img
            src={LoginImg}
            alt="Signup
            "
            className="img-fluid rounded"
          />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 3: Choose Account Type
        </h4>
        <p>
          As a subscriber, you have the option to sign up as an "Individual" or
          as part of an "Organization." Select "Individual" to proceed.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 4: Fill in Your Information
        </h4>
        <p>It's time to provide your details:</p>
        <ul className="list-decimal w-full my-2">
          <li>First Name</li>
          <li>Last Name</li>
          <li>Email</li>
          <li>Phone Number</li>
          <li>Create a Password and Confirm it</li>
          <li>
            Set a 4-digit PIN (Personal Identification Number)
            <div className="d-flex justify-content-center my-3">
              <img
                src={SelfRegisterImg}
                alt="SelfRegisterImg "
                className="img-fluid rounded"
              />
            </div>
          </li>
        </ul>
        <p>
          Once you've filled in your information, click "Save and Next" to move
          forward.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 5: Verify with OTP
        </h4>
        <p>
          An OTP (One-Time Password) will be sent to both your email and phone.
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={OTPEmail} alt="OTPEmail" className="img-fluid rounded" />
        </div>
        <p>
          Enter the OTP to verify your contact information. If you miss the OTP,
          you can request a new one within 10 minutes.
        </p>
        <div className="d-flex justify-content-center my-3 flex-column flex-items-middle">
          <img
            src={SelfRegOTP}
            alt="SelfRegOTP"
            style={{ height: "300px", objectFit: "contain" }}
          />
          <img src={SleftRegOTPVerify} alt="SleftRegOTPVerify" className="" />
        </div>
      </div>

      <div>
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 6: Non-Editable Details
        </h4>
        <p>You'll now see some non-editable information:</p>
        <ul className="list-disc w-full my-2">
          <li>Default Bank (NO BANK initially)</li>
          <li>
            Bank Account Number (by default, it's your registered mobile/phone
            number)
          </li>
          <li>
            Authentication Type (default is 1FA - One-Factor Authentication)
          </li>
          <li>
            Initial Transaction Amount using 1FA (default: 50000 SLE)
            <div className="d-flex justify-content-center my-3">
              <img
                src={FA1Img}
                alt="Authentication"
                className="img-fluid rounded"
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 7: Save and Preview
        </h4>
        <p>
          Click "Save and Preview" to review all the information you've entered.
          This is your chance to double-check and make sure everything is
          accurate. Preview
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={PreviewImg} alt="Preview" className="img-fluid rounded" />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 8: Submission
        </h4>
        <p>
          Once you're satisfied with the details, hit "Submit." You'll receive a
          confirmation email/SMS with your account credentials.
        </p>
        <div className="d-flex justify-content-center my-3 flex-column">
          <img
            src={regsuccessfullImg}
            alt="regsuccessfull"
            className="img-fluid rounded"
            style={{ height: "300px", objectFit: "contain" }}
          />
          <img
            src={credentialemailImg}
            alt="credentialemail"
            className="img-fluid rounded"
          />
        </div>
      </div>

      <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
        Subscriber Flowchart
      </h4>
      <div className="d-flex justify-content-center my-3">
        <img src={SubscriberFlowImg} alt="SubscriberFlow" className="w-100" />
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Step 9: Account Activation
        </h4>
        <p className="mb-2">
          Your account will be fully activated once it's personally verified by
          a BANK user in the SmartPay system.
        </p>
        <p>
          Congratulations! You're now well on your way to using SmartPay for
          seamless transactions.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-2xl text-bluePrimary  mb-2">
          Subscriber Journey
        </h4>
        <h4 className="font-bold md:text-xl text-bluePrimary mb-2">
          Dashboard Overview
        </h4>
        <p>
          The SmartPay dashboard provides a comprehensive overview of your
          financial activities and balance. Here's what you can find on your
          dashboard:
        </p>
        <ul className="list-disc w-full my-2">
          <li>
            Available Balance: The available balance of your wallet is displayed
            prominently on the dashboard. This balance reflects the funds you
            have available for transactions and payments.
          </li>
          <li>
            Bank Withdrawals: This section allows you to initiate withdrawals
            from your SmartPay wallet to your associated bank account. You can
            manage your financial liquidity by making secure transfers.
          </li>
          <li>
            MMO Withdrawals: If you prefer to use mobile money services for
            withdrawals, this section enables you to initiate MMO withdrawals
            directly from your SmartPay wallet.
          </li>
          <li>
            Payments: The Payments section facilitates secure payments for
            various services, bills, and utilities. You can conveniently make
            payments within the SmartPay platform.
          </li>
          <li>
            Wallet Transfer: Transfer money to another SmartPay subscriber's
            wallet seamlessly. Whether you want to share funds with friends or
            family, this feature simplifies the process.
          </li>
          <li>
            Wallet Received: Keep track of funds received from other SmartPay
            subscribers. This section helps you monitor incoming transactions.
          </li>
          <li>
            Visual Graphs for Better Insights: The dashboard includes visual
            graphs that offer insights into your financial trends and
            activities. These graphs help you understand your transactions and
            balance fluctuations more effectively.
            <div className="d-flex justify-content-center my-3">
              <img src={Img1} alt="Img1" className="img-fluid rounded" />
            </div>
          </li>
        </ul>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-2xl text-bluePrimary mb-2">
          Top-Up Process
        </h4>
        <p className="mb-3">
          The top-up process allows you to add funds to your SmartPay wallet
          quickly and securely. There are two options available:
        </p>
        <h4 className="font-bold md:text-xl  mb-2">Self Wallet Top-Up</h4>
        <ul className="list-disc w-full my-2">
          <li>Self Wallet Top-Up</li>
          <li>Select "Own Wallet" and proceed.</li>
          <li>
            Choose your preferred payment method: MMO or Credit/Debit Card.
          </li>
          <li>
            Provide the necessary financial details as per your chosen payment
            method and confirm.
            <div className="d-flex justify-content-center my-3">
              <img src={Img2} alt="Img2" className="img-fluid rounded" />
            </div>
          </li>
        </ul>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl  mb-2">Other Wallet Top-Up</h4>
        <ol className="list-decimal w-full my-2">
          <li>Click on "Top-Up" in the dashboard.</li>
          <li>
            Select "Other Wallet" and enter the recipient's wallet number
            (SmartPay).
          </li>
          <li>
            Choose your preferred payment method: MMO or Credit/Debit Card.
          </li>
          <li>
            Fill in the recipient's financial details and confirm the top-up.
            <div className="d-flex justify-content-center my-3">
              <img src={Img3} alt="Img3" className="img-fluid rounded" />
            </div>
          </li>
        </ol>
        <p className="mb-3">
          Payment Options (MMO, Credit/Debit Card): Depending on your chosen
          payment method, enter the required information. For MMO, provide
          mobile money details. For Credit/Debit Card, enter card details.
        </p>
        <p>Transaction Confirmation and Notifications:</p>
        <ol className="list-decimal w-full my-2">
          <li>
            After confirming the top-up, you will be redirected to the SmartPay
            home page.
          </li>
          <li>A transaction ID and success message will be provided to you.</li>
          <li>
            SMS and email notifications will also be sent to confirm the
            transaction.
            <div className="d-flex justify-content-center my-3">
              <img src={Img4} alt="Img4" className="img-fluid rounded" />
            </div>
            <div className="d-flex justify-content-center my-3">
              <img src={Img6} alt="Img6" className="img-fluid rounded" />
            </div>
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-2xl text-bluePrimary mb-2">
          Transactions History
        </h4>
        <h4 className="font-bold md:text-xl  mb-2">
          View All Transaction Details
        </h4>
        <ol className="list-decimal w-full my-2">
          <li>
            Access your transaction history by clicking on "Transactions" in the
            dashboard.
          </li>
          <li>
            The transaction history provides a detailed record of all your
            financial activities within the SmartPay platform.
          </li>
        </ol>
        <h4 className="font-bold md:text-xl  mb-2">
          Transaction Status and Information
        </h4>
        <ol className="list-decimal w-full my-2">
          <li>
            Each transaction entry includes essential details such as the date
            of the transaction, recipient information, transaction amount, and
            status.
          </li>
          <li>
            By checking the transaction status, you can stay informed about the
            progress of each financial activity.
            <div className="d-flex justify-content-center my-3">
              <img src={Img7} alt="Img7" className="img-fluid rounded" />
            </div>
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-2xl text-bluePrimary  mb-2">
          Transfer Money
        </h4>
        <h4 className="font-bold md:text-xl  mb-2">
          Wallet-to-Wallet Transfer
        </h4>
        <ol className="list-decimal w-full my-2">
          <li>Navigate to the "Transfer Money" section in the dashboard.</li>
          <li>
            Enter the recipient's wallet number (phone number) for the transfer.
          </li>
          <li>
            The system will automatically populate the recipient's details.
          </li>
          <li>
            Provide the transfer amount and your associated PIN for
            verification.
          </li>
          <li>Verify and confirm the transaction.</li>
        </ol>
        <p>
          Automatic Population of Receiver Details: The SmartPay system
          conveniently auto-populates receiver details based on the provided
          wallet number.
        </p>
        <div className="d-flex justify-content-center my-3 mb-3">
          <img src={Img8} alt="Img8" className="img-fluid rounded" />
        </div>
        <p className="mb-2">Transaction Completion and Notifications:</p>
        <ol className="list-decimal w-full my-2">
          <li>
            Once the transfer is verified, the funds will be transferred to the
            recipient's wallet. .
          </li>
          <li>
            Both the sender and receiver will receive SMS and email
            notifications to confirm the successful transfer.
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-2xl text-bluePrimary  mb-2">
          Cash Out
        </h4>
        <h4 className="font-bold md:text-xl  mb-2">Cash Out to Sub Agent</h4>
        <ol className="list-decimal w-full my-2 mb-2">
          <li>Visit the "Cash Out" section on the dashboard.</li>
          <li>
            Enter the sub agent's wallet number (phone number) for the
            transaction.
          </li>
          <li>
            Receiver details will be automatically populated by the system.
          </li>
          <li>
            Provide the amount you wish to cash out and your associated PIN.
          </li>
          <li>Verify and confirm the cash out transaction.</li>
        </ol>
        <p className="mb-2">
          Automatic Population of Receiver Details: The system streamlines the
          process by automatically filling in the receiver's details.
        </p>
        <p className="mb-2">Transaction Completion and Notifications:</p>
        <ol className="list-decimal w-full my-2 mb-2">
          <li>
            Once the transaction is verified, the cash will be successfully
            transferred to the sub agent's wallet.
          </li>
          <li>
            Both the sender and sub agent will receive SMS and email
            notifications confirming the transaction.
            <div className="d-flex justify-content-center my-3">
              <img src={Img9} alt="Img9" className="img-fluid rounded" />
            </div>
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-2xl text-bluePrimary  mb-2">
          Make Payment
        </h4>
        <h4 className="font-bold md:text-xl  mb-2">Payment to Services</h4>
        <ol className="list-decimal w-full my-2 mb-2">
          <li>Access the "Make Payment" option in the dashboard.</li>
          <li>
            Choose the type of service you intend to make a payment for:
            SmartBills, University Fees, or Other Services.
          </li>
        </ol>
        <p className="mb-2 font-bold">
          Service Types (SmartBills, University Fees, Other Services)
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={Img10} alt="Img10" className="img-fluid rounded" />
        </div>
        <ol className="list-decimal w-full my-2 mb-2">
          <li>
            Based on your selection, further specify the service you are making
            a payment for. Options include EDSA, NRA, ITS, and Others.
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl  mb-2">Pay Smart bills</h4>
        <div className="d-flex justify-content-center my-3">
          <img src={Img11} alt="Img11" className="img-fluid rounded" />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl  mb-2">Pay University Fees</h4>
        <div className="d-flex justify-content-center my-3">
          <img src={Img12} alt="Img12" className="img-fluid rounded" />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl  mb-2">Pay Other Services</h4>
        <div className="d-flex justify-content-center my-3">
          <img src={Img13} alt="Img12" className="img-fluid rounded" />
        </div>
      </div>
      <p className="mb-2">Payment Confirmation and Notifications:</p>
      <ol className="list-decimal w-full my-2 mb-2">
        <li>Confirm the payment details.</li>
        <li>
          Receive a payment confirmation along with SMS and email notifications
          to verify the successful payment.
        </li>
      </ol>
      <div className="mb-3">
        <h4 className="font-bold md:text-2xl text-bluePrimary  mb-2">
          Top-Up History
        </h4>
        <h4 className="font-bold md:text-xl  mb-2">View Top-Up History</h4>
        <ol className="list-decimal w-full my-2 mb-2">
          <li>Navigate to the "Top-Up History" section in the dashboard.</li>
          <li>
            Gain insights into your top-up history, including details of top-ups
            made to your own wallet and to other wallets.
            <div className="d-flex justify-content-center my-3">
              <img src={Img14} alt="Img14" className="img-fluid rounded" />
            </div>
          </li>
        </ol>
        <p>
          If you have any questions or need assistance, our support team is here
          to help. Happy SmartPaying!
        </p>
      </div>
    </div>
  );
};

export default SubscriberJourney;
