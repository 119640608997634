/* eslint-disable react/no-unescaped-entities */
import CB1Img from "../../assets/kb/CB1.png";
import CB2Img from "../../assets/kb/CB2.png";
import CB3Img from "../../assets/kb/CB3.png";
import CB4Img from "../../assets/kb/CB4.png";
import SB1Img from "../../assets/kb/SB1.png";
import SB2Img from "../../assets/kb/SB2.png";

const BankUserJourney = () => {
  return (
    <div>
      <div className="mb-3">
        <p>
          This guide will help you navigate and utilize the features of both
          Collection Bank and Settlement Bank effectively.
        </p>
      </div>
      <h2 className="font-bold  md:text-2xl text-bluePrimary mb-3">
        Collection Bank
      </h2>
      <div className="mb-3">
        <h2 className="font-bold md:text-xl mb-2">Dashboard</h2>
        <ul className="list-disc w-full  my-2">
          <li>
            <strong>Total COLLECTION:</strong> View the total collection amount
            for the current period.
          </li>
          <li>
            <strong>TOTAL SUBSCRIBER:</strong> Monitor the total number of
            subscribers registered.
          </li>{" "}
          <li>
            <strong>VERIFIED SUBSCRIBER:</strong> Keep track of the count of
            verified subscribers.
          </li>{" "}
          <li>
            <strong>UNVERIFIED SUBSCRIBER:</strong> Track the number of
            subscribers awaiting verification.
          </li>{" "}
          <li>
            <strong>MTD Commissions:</strong> See the total commissions earned
            month-to-date.
          </li>{" "}
          <li>
            <strong>MTD Customer Transfers:</strong> Review the total customer
            transfers for the current month.
          </li>{" "}
        </ul>
        <div className="d-flex justify-content-center my-3">
          <img src={CB1Img} alt="Dashboard"  className="img-fluid rounded" />
        </div>
      </div>
      <div className="mb-3">
        <h2 className="font-bold md:text-xl mb-2">Features</h2>
        <ul className="list-disc w-full  my-2">
          <li>
            <strong>Subscriber Account Approval:</strong> As a Collection Bank
            user, you have the authority to approve subscriber accounts. Once
            approved, subscribers can access and use their accounts.
            <div className="d-flex justify-content-center my-3">
              <img src={CB4Img} alt="Account "  className="img-fluid rounded" />
            </div>
          </li>
          <li>
            <strong>Master Agent Top-Up Approval:</strong> You also have the
            ability to approve top-up amounts for master agents. This
            facilitates smooth transactions within the system.
            <div className="d-flex justify-content-center my-3">
              <img src={CB2Img} alt="Top-Up"  className="img-fluid rounded" />
            </div>
          </li>
          <li>
            <strong>Commission Collection:</strong> You can view the collected
            commissions through a dedicated section, allowing you to keep track
            of earnings accurately.
            <div className="d-flex justify-content-center my-3">
              <img src={CB3Img} alt="Commission"  className="img-fluid rounded" />
            </div>
          </li>
          <li>
            <strong>Transaction Search:</strong> To find specific transactions,
            use the transaction search feature. This helps in quickly locating
            and reviewing particular transaction details.
          </li>
        </ul>
      </div>
      <h2 className="font-bold md:text-2xl mb-3 text-bluePrimary mt-6">
        Settlement Bank
      </h2>
      <ul className="list-disc w-full  my-2">
        <li>
          <strong>Dashboard Overview: </strong> Upon logging in, you'll be
          greeted with an informative dashboard. This dashboard displays
          essential information, including Settlement Balance, Collection
          Accounts Balance, and Merchant Settlement Amounts.
          <div className="d-flex justify-content-center my-3">
            <img src={SB1Img} alt="Overview "  className="img-fluid rounded" />
          </div>
        </li>
        <li>
          <strong>Merchant Settlement: </strong> Within the dashboard, you'll
          find a dedicated tab for Merchant Settlements. Use this tab to approve
          and settle merchant bills, ensuring timely and accurate payments.
          <div className="d-flex justify-content-center my-3">
            <img src={SB2Img} alt="Overview "  className="img-fluid rounded" />
          </div>
        </li>
        <li>
          <strong>Search Transactions:</strong> The Search Transaction tab
          allows you to search for detailed transaction information. This
          feature is particularly useful for tracking down specific transactions
          and their associated details.
        </li>
      </ul>
    </div>
  );
};

export default BankUserJourney;
