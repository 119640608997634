import React from "react";
import {
  Row,
  Col,

} from "reactstrap";

const VisuallyLoader = (props) => {
  var colorCls = "spinner-grow";
  if (props.colorCls) {
    colorCls = colorCls + " " + props.colorCls;
  }

  var label = "Fetching";
  if (props.label) {
    label = props.label;
  }


  return (
    <>
      <Row>
        <Col lg="12">
          <div className="d-flex justify-content-center">
            <div className={colorCls} role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <span>{label}</span>
        </Col>
      </Row>
    </>
  )
};

export default VisuallyLoader;