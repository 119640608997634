import React, { useEffect, useState } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { getUserId, postMethod } from "services/httpServices";
import { merchantMTDChartData, merchantYTDChartData } from "variables/charts";
import { daysInMonth, daysInArray, MonthArraySM } from "services/util";
import { getLoginType } from "services/httpServices";

const MerchantHeader = (props) => {
  const [totalAmt, setAmount] = React.useState("");
  const [totalPayable, setTotalPayable] = React.useState("");
  const [currentMonthAmt, setCurrentMonthAmt] = React.useState("");
  const [todayAmt, setTodayAmt] = React.useState("");
  const [totalCashTopUp, setTotalCashTopUp] = React.useState("");
  const [balanceLoading, setBalLoading] = React.useState(false);
  const [showTopupList, setShowTopupList] = React.useState(false);
  const [topupList, setTopupList] = React.useState([]);
  const [totalTopupAmount, setTotalTopupAmout] = React.useState(0);
  const [dashboardInfo, setDashboardInfo] = useState({
    todaySales: 0,
    todayCommissions: 0,
    totalTodaySales: 0,
    mtdSales: 0,
    mtdCommissions: 0,
    totalMTDSales: 0,
    ytdSales: 0,
    ytdCommissions: 0,
    totalYTDSales: 0,
    aspt: 0,
    asptAmount: 0,
    asptCommission: 0,
    asptStatus: "RED",
    dailySales: [],
    monthlySales: [],
  });
  const { addToast } = useToasts();
  const loginUser = getLoginType()
console.log({loginUser})

  useEffect(() => {
    fetchBalance();
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const getGraphData = (input) => {
    return Object.values(
      input.reduce((acc, item) => {
        const date = new Date(item.Key);
        const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
        if (!acc[month]) {
          acc[month] = {
            month: month,
            amount: item.Value,
          };
        } else {
          acc[month].amount += item.Value;
        }
        return acc;
      }, {})
    );
  };

  const getDayData = (input) => {
    return Object.values(
      input.reduce((acc, item) => {
        const date = new Date(item.Key);
        const day = date.getDate(); // Extracting the day number
        if (!acc[day]) {
          acc[day] = {
            day: day,
            amount: item.Value,
          };
        } else {
          acc[day].amount += item.Value;
        }
        return acc;
      }, {})
    );
  };
  const fetchBalance = () => {
    let errorMsg = "";
    let id = getUserId();
    setBalLoading(true);
    postMethod(
      "/api/MerchantProfile/DashboardData?MerchantId=" + id,
      {},
      "post"
    )
      .then((res) => {
        setBalLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            let monthArr = MonthArraySM;
            let dataArr = [];
            for (let i = 1; i <= 12; i++) {
              let val = getGraphData(res.data.result?.monthlySales).find(
                (l) => l.month == i
              );
              if (val && val.hasOwnProperty("amount")) {
                dataArr.push(Number(val.amount));
              } else {
                dataArr.push(0);
              }
            }
            let ytdChart = merchantYTDChartData({
              monthArr: monthArr,
              dataArr: dataArr,
            });

            props.showMerchantYTD(ytdChart);
            props.showMerchantYTDLoader();

            setData(res.data.result);
            setDashboardInfo(res.data.result);

            let month = Number(props.merchantMTDMonth);
            let year = Number(props.merchantMTDYear);
            let totalDays = daysInMonth(month + 1, year);
            let arr = daysInArray(totalDays);
            let valArr = [];
            for (let i = 0; i < arr.length; i++) {
              let val = getDayData(res.data.result?.dailySales).find(
                (l) => l.day == arr[i]
              );
              if (val && val.hasOwnProperty("amount")) {
                valArr.push(Number(val.amount));
              } else {
                valArr.push(0);
              }
            }
            let mtdChart = merchantMTDChartData({
              daysArr: arr,
              dataArr: valArr,
            });
            props.showMerchantMTD(mtdChart);
            props.showMerchantMTDLoader(false);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setBalLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const setData = (resp) => {
    let paid = resp.totalReceived ? Number(resp.totalReceived) : 0;
    let pending = resp.totalPending ? Number(resp.totalPending) : 0;
    let monthAmt = resp.monthlyReceived ? Number(resp.monthlyReceived) : 0;
    let todayAmt = resp.todayReceived ? Number(resp.todayReceived) : 0;
    let cashTopUp = resp.totalTopUp ? Number(resp.totalTopUp) : 0;
    setAmount(paid);
    setTotalPayable(pending);
    setCurrentMonthAmt(monthAmt);
    setTodayAmt(todayAmt);
    setTotalCashTopUp(cashTopUp);
    setTopupList(resp?.topupToPlatform);
    let total = 0;
    if (resp?.topupToPlatform?.length) {
      resp?.topupToPlatform?.forEach((item) => {
        total += item?.totalAmount;
      }, 0);
    }
    setTotalTopupAmout(total);
  };

  const getAmt = (amt) => {
    return amt.toLocaleString();
  };

  return (
    <>
      <div className="header bg-gradient-info pb-4 pt-2 pt-md-2">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row className="mt-2">
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>ASPT</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.aspt)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>ASPT Amount</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.asptAmount)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>ASPT Commission</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-chart-bar"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.asptCommission)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>ASPT Status</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          <i
                            class={`fas fa-circle ${
                              dashboardInfo.asptStatus === "RED"
                                ? "text-red"
                                : "text-green"
                            }`}
                          ></i>
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mt-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Today Commissions</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i className="fas fa-money-bill-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.todayCommissions)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card
                  className="card-stats mt-4 mb-xl-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowTopupList(!showTopupList)}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>MTD Commissions</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fa fa-money-check text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.mtdCommissions)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card
                  className="card-stats mt-4 mb-xl-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowTopupList(!showTopupList)}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>YTD Commissions</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-indigo text-white rounded-circle shadow">
                          <i class="fa fa-money-check text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.ytdCommissions)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mt-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Today Sales</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i className="fas fa-money-bill-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.todaySales)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card
                  className="card-stats mt-4 mb-xl-0"
                  style={{ cursor: "pointer" }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>MTD Sales</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fa fa-money-check text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.mtdSales)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card
                  className="card-stats mt-4 mb-xl-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowTopupList(!showTopupList)}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>YTD Sales</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-indigo text-white rounded-circle shadow">
                          <i class="fa fa-money-check text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.ytdSales)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mt-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total today's Sales</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i className="fas fa-money-bill-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.totalTodaySales)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card
                  className="card-stats mt-4 mb-xl-0"
                  style={{ cursor: "pointer" }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total MTD sales</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fa fa-money-check text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.totalMTDSales)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card
                  className="card-stats mt-4 mb-xl-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowTopupList(!showTopupList)}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total YTD Sales</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-indigo text-white rounded-circle shadow">
                          <i class="fa fa-money-check text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(dashboardInfo.totalYTDSales)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MerchantHeader;
