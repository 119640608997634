/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  ModalHeader,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { getUserId, postMethod } from "../../services/httpServices";
import { useToasts } from "react-toast-notifications";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import AddPOS from "./add-pos-terminal";
import ViewPOS from "./view-pos-terminal";
import { btnCss, iconCss } from "../../appConfig";
import EditPOS from "./edit-post-terminal";
// import AssignPOS from "./assign-pos-terminal";
import ReactPaginate from "react-paginate";

const PosTerminalList = () => {
  const [loading, setLoading] = React.useState(false);
  const [postlist, setPosList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [openEdit, setOpenEditModal] = React.useState(false);
  const [POSObj, setPOSObj] = React.useState(null);
  const [editObj, setEditObj] = React.useState(null);
  const [totalRec, setTotalRec] = React.useState(0);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  // const [assign, setAssign] = React.useState(false);
  // const [assignObj, setAssignObj] = React.useState(null);
  const [modalLoading, setModalLoading] = React.useState(false);

  const { addToast } = useToasts();

  /**======================== React Hooks ==========================*/

  React.useEffect(() => {
    fetchPOS();
  }, []);

  React.useEffect(() => {
    let timeout;
    if (timeout) {
      clearTimeout(timeout);
    } else {
      timeout = setTimeout(() => {
        fetchPOS();
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [searchText]);

  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  // const notifySuccess = (text) => {
  //   if (!text) {
  //     text = "Save successfully";
  //   }
  //   addToast(text, {
  //     appearance: "success",
  //     autoDismiss: true,
  //   });
  // };

  const getSearch = (val) => {
    setSearchText(val);
    // if (timeout) clearTimeout(timeout);
    // const timeout1 = setTimeout(() => {
    //   let filteredList = [];
    //   for (let i = 0; i < postlist.length; i++) {
    //     if (
    //       postlist[i].bban
    //         .trim()
    //         .toLowerCase()
    //         .includes(val.trim().toLowerCase()) ||
    //       postlist[i].email
    //         .trim()
    //         .toLowerCase()
    //         .includes(val.trim().toLowerCase()) ||
    //       postlist[i].mobileCode
    //         .toString()
    //         .trim()
    //         .toLowerCase()
    //         .includes(val.trim().toLowerCase()) ||
    //       postlist[i].phoneNumber
    //         .toString()
    //         .trim()
    //         .toLowerCase()
    //         .includes(val.trim().toLowerCase()) ||
    //       postlist[i].name
    //         .toString()
    //         .trim()
    //         .toLowerCase()
    //         .includes(val.trim().toLowerCase())
    //     ) {
    //       filteredList.push(postlist[i]);
    //     }
    //   }
    //   setFilteredList(filteredList);
    //   setCurrentPage(0);
    //   let pc = Math.ceil(filteredList.length / pageSize);
    //   setPageCount(pc);
    // }, 750);
    // setTimeoutNew(timeout1);
  };

  const reloadList = () => {
    setSearchText("");
    setCurrentPage(0);
    fetchPOS();
  };

  const closeModal = () => {
    setOpen(false);
    fetchPOS();
  };

  const openModal = () => {
    setOpen(true);
  };

  // const handleClick = (e, index) => {
  //   e.preventDefault();
  //   setCurrentPage(index);
  // };

  const showPOS = (obj) => {
    setPOSObj(obj);
    setView(true);
  };

  const closeViewModal = () => {
    setView(false);
    setPOSObj(null);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
    setEditObj(null);
    fetchPOS();
  };

  const openDelete = (mmo) => {
    setDeleteObj(mmo);
    setOpenDelModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteObj(null);
    setOpenDelModal(false);
  };

  // const openAssign = (mmo) => {
  //   setAssignObj(mmo);
  //   setAssign(true);
  // };

  // const closeAssignModal = () => {
  //   setAssignObj(null);
  //   setAssign(false);
  // };

  /**======================== Validation Functions ==========================*/

  /**======================== API Calls ==========================*/

  const fetchPOS = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod(
      "/api/POS/SearchDevice",
      {
        searchText: searchText,
        isDescending: false,
        sortingOn: "",
        perPage: 10,
        pageNumber: 1,
      },
      "post"
    )
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            setPosList(res.data.result.list);
            setFilteredList(res.data.result.list);
            let pc = Math.ceil(res.data.result.list.length / pageSize);
            setTotalRec(res.data.result.totalRecords);

            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchPOSById = (id) => {
    let errorMsg = "";
    postMethod("/api/POS/Edit/" + id, {}, "post")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setEditObj(res.data.result);
            setOpenEditModal(true);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            // setSError(errorMsg);
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            // setSError(errorMsg);
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          // setSError(errorMsg);
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        // setSError();
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };
  const getBlockText = () => {
    let str = "block";
    if (deleteObj.isBlocked) {
      str = "unblock";
    }

    return `Do you want to ${str} ${deleteObj?.posDeviceName}?`;
  };
  const deletePOS = () => {
    var data = {};
    data["posDeviceName"] = deleteObj.deviceName;
    data["posMake"] = deleteObj.make;
    data["posId"] = deleteObj.posId;
    data["posModel"] = deleteObj.model;
    data["posManufactureYear"] = deleteObj.manufactureYear;
    data["posSerialNumber"] = deleteObj.serialNo;
    data["posMacAddress"] = deleteObj.macAddress;
    data["isNFCEnabled"] = deleteObj.nfc === "Enable";
    data["isMagStripeEnabled"] = deleteObj.magStrip === "Enable";
    data["isChipEnabled"] = deleteObj.chip === "Enable";
    data["isDeleted"] = deleteObj.isDeleted;
    data["isBlocked"] = !deleteObj.isBlocked;
    data["createdAt"] = deleteObj.createdAt;
    data["createdBy"] = deleteObj.createdBy;
    data["updatedAt"] = new Date().toISOString();
    data["updatedBy"] = getUserId();

    let errorMsg = "";
    setLoading(true);
    postMethod("/api/POS/Update", data, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            let text = deleteObj.isBlocked
              ? "NFC unblocked successfully"
              : "NFC blocked successfully";
            notifySuccess(text);
            closeModal();
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };
  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form
                  className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="search-cls"
                        placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  &nbsp;&nbsp;
                  <Button className="my-4" color="primary" onClick={openModal}>
                    Add POS Terminal
                  </Button>
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh"
                  >
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>
                </Form>
              </CardHeader>

              <Modal isOpen={open} style={{ width: "70vw", maxWidth: "70vw" }}>
                <ModalBody style={{ padding: 0, width: "100%" }}>
                  <AddPOS closeModal={closeModal} />
                </ModalBody>
              </Modal>

              <Modal isOpen={view} style={{ width: "70vw", maxWidth: "70vw" }}>
                <ModalBody style={{ padding: 0 }}>
                  <ViewPOS closeModal={closeViewModal} {...POSObj} />
                </ModalBody>
              </Modal>

              <Modal isOpen={openDelModal} className="modal-sm">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    {deleteObj && deleteObj.isBlock
                      ? "Unblock POS"
                      : "Block POS"}
                  </h3>
                </ModalHeader>

                <ModalBody>
                  <div>
                    {deleteObj && deleteObj.posDeviceName ? (
                      <div>
                        <p className="text-left">{getBlockText()}</p>
                        <div className="text-right">
                          <Button
                            color="primary"
                            onClick={deletePOS}
                            disabled={modalLoading}
                          >
                            Yes{" "}
                            {modalLoading && (
                              <i class="fa fa-spinner fa-spin"></i>
                            )}
                          </Button>{" "}
                          <Button
                            color="secondary"
                            onClick={closeDeleteModal}
                            disabled={modalLoading}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <> </>
                    )}
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={openEdit}
                style={{ width: "70vw", maxWidth: "70vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  <EditPOS closeModal={closeEditModal} {...editObj} />
                </ModalBody>
              </Modal>

              {/* <Modal isOpen={assign} className="modal-sm">
                <ModalBody style={{ padding: 0 }}>
                  <AssignPOS closeModal={closeAssignModal} />
                </ModalBody>
              </Modal> */}

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Device Name</th>
                    <th scope="col">Make</th>
                    <th scope="col">Manufacture Year</th>
                    <th scope="col">Model</th>
                    <th scope="col">Serial No.</th>
                    <th scope="col">MAC Address</th>

                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList
                    .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                    .map((mmo, i) => {
                      return (
                        <tr>
                          <th scope="row">{mmo.posDeviceName}</th>
                          <td>{mmo.posMake}</td>
                          <td>{mmo.posManufactureYear}</td>
                          <td>{mmo.posModel}</td>
                          <td>{mmo.posSerialNumber}</td>
                          <td>{mmo.posMacAddress}</td>

                          <td>
                            <Button
                              color="primary"
                              onClick={() => showPOS(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="View"
                            >
                              <i class="fas fa-eye fa-lg" style={iconCss}></i>
                            </Button>

                            <Button
                              color="primary"
                              onClick={() => fetchPOSById(mmo.posId)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Edit"
                            >
                              <i class="fas fa-edit fa-lg" style={iconCss}></i>
                            </Button>

                            {/* <Button
                              color="primary"
                              onClick={() => openAssign(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Edit"
                            >
                              <i class="fas fa-link fa-lg" style={iconCss}></i>
                            </Button> */}

                            {!mmo.isBlocked ? (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Block"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Unblock"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={{ color: "#FF0000" }}
                                ></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
                {!loading && !filteredList.length && <NoRecordFound />}
                {filteredList.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>Total Records : {totalRec}</strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6">
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={({ selected: selectedPage }) =>
                              setCurrentPage(selectedPage)
                            }
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      </nav>
                    </Col>
                  </Row>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PosTerminalList;
