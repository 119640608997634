import React from "react";
import Header from "components/Headers/Header.js";
import { useToasts } from 'react-toast-notifications';
import { getUserId, postMethod } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import {btnCss, iconCss} from "../../appConfig";


import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalHeader,
  Badge,
  Col
} from "reactstrap";
import { SettleBankUserStatus } from "appConfig";
import ReactPaginate from 'react-paginate';
import { getSortCls } from "services/util";


const STATUS = SettleBankUserStatus;

const initialState = {
  merchantId: '',
  approveReason: ''
};

const sortIS = {
  sortMerchantName: 0,
  sortMerchantBankName: 0,
  sortMerchantAccountNo: 0,
  sortAmount: 0,
  sortCommissionAmount: 0
};


const SettlementList = () => {
  const [values, setValues] = React.useState(initialState);
  const [errors, setError] = React.useState({});
  const [banklist, setBankList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [openApproveModal, setOpenApproveModal] = React.useState(false);
  const [approveObj, setApproveObj] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState(STATUS[0].key);
  const [totalList, setTotalList] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [coreTransId, setCoreTransId] = React.useState(null);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [isMounted, setIsMounted] = React.useState(false);


  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  // React.useEffect(() => {
  //   // fetch mobilemoney operator
  //   fetchSettlement();
  // }, []);

  // React.useEffect(() => {
  //   setListDataWrapper(totalList);
  // }, [statusFilter]);

  // React.useEffect(()=> {
  //   if (refresh) {
  //     reloadListHandle();
  //   }
  // }, [refresh]);


  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        if (currentPage != 0) {
          setCurrentPage(0);
        } else {
          fetchSettlement();
        }
      }
    }
  }, [searchText]);


  React.useEffect(() => {
    fetchSettlement();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchSettlement();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchSettlement();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchSettlement();
      }
    }
  }, [statusFilter]);


  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const changeStatus = (val) => {
    var value = val;
    setStatusFilter(value);
  }

  const statusFormat = (val) => {
    let ob = STATUS.find((s)=> s.key === val);
    // return ob && ob.value ? ob.value : val;
    return ob && ob.value ? (
      <Badge color="" className="badge-dot mr-4">
        <i className={ob.className}/>
        {ob.value}
      </Badge>
    ) : val
  };

  const getSearchHandle = (val, blist) => {
    let filteredList = [];
    let banklist = blist;
    for (let i = 0; i < banklist.length; i++) {
      if (
        (banklist[i].merchantName &&
        banklist[i].merchantName.toString()
          .trim()
          .toLowerCase()
          .includes(val.trim().toLowerCase()) ||
          banklist[i].bankName &&
          banklist[i].bankName.toString()
          .trim()
          .toLowerCase()
          .includes(val.trim().toLowerCase()) ||
          banklist[i].merchantAccountNo &&
          banklist[i].merchantAccountNo.toString()
          .trim()
          .toLowerCase()
          .includes(val.trim().toLowerCase()))
      ) {
        filteredList.push(banklist[i]);
      }
    }
    return filteredList;
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const getAmt = (amt) => {
    return Number(amt).toLocaleString();
  }

  const setListDataWrapper = (resp) => {
    let arr = setListData(resp);
    if (arr && arr.length) {
      setLoading(false);
    }
    if (searchText) {
      arr = getSearchHandle(searchText, arr);
    }
    setBankList(arr);
    setFilteredList(arr);
    let pc = Math.ceil(arr.length / pageSize);
    setPageCount(pc);
  }

  const setListData = (resp) => {
    if (statusFilter === 'All') {
      return resp;
    }
    let arr = [];
    for (let i = 0; i < resp.length; i++) {
      if (resp[i].status === statusFilter) {
        arr.push(resp[i]);
      }
    }
    return arr;
  }

  const fetchSettlement = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize,
      'Status': statusFilter
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortMerchantName == "1") {
      data['SortingOn'] = 'MerchantName';
      data['IsDescending'] = false;
    } else if (sort.sortMerchantName == "2") {
      data['SortingOn'] = 'MerchantName';
      data['IsDescending'] = true;
    } else if (sort.sortMerchantBankName == "1") {
      data['SortingOn'] = 'MerchantBankName';
      data['IsDescending'] = false;
    } else if (sort.sortMerchantBankName == "2") {
      data['SortingOn'] = 'MerchantBankName';
      data['IsDescending'] = true;
    } else if (sort.sortMerchantAccountNo == "1") {
      data['SortingOn'] = 'MerchantAccountNo';
      data['IsDescending'] = false;
    } else if (sort.sortMerchantAccountNo == "2") {
      data['SortingOn'] = 'MerchantAccountNo';
      data['IsDescending'] = true;
    } else if (sort.sortAmount == "1") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = false;
    } else if (sort.sortAmount == "2") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = true;
    } else if (sort.sortCommissionAmount == "1") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = false;
    } else if (sort.sortCommissionAmount == "2") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = true;
    }

    postMethod('/api/Settlement/CollectionSettlementList', data, 'post')
    .then(res => {
      setLoading(false);
      setRefresh(false);
      setIsMounted(true);
      if (
        res && res.data
      ) {

        if (res.data.responseCode === "200" &&
          res.data.result) {
          // Success
          if (
            !res.data.result.list ||
            !res.data.result.list.length
          ) {
            res.data.result.list = [];
          }
          setTotalList(res.data.result.list);
          setFilteredList(res.data.result.list)
          let pc = Math.ceil(res.data.result.totalRecords / pageSize);
          setTotalRec(res.data.result.totalRecords);
          setPageCount(pc);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);

        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);

      }
    }).catch(err => {
      setLoading(false);
      setIsMounted(true);
      setRefresh(false);
      notifyFailed('Something went wrong!, Please call 3737');
    });
  }

  const approve = (trans) => {
    setOpenApproveModal(true);
    setCoreTransId(null);
    setApproveObj(trans);
  }

  const closeApproveModal = () => {
    setApproveObj(null);
    setError({});
    setCoreTransId(null);
    setValues(initialState);
    setOpenApproveModal(false);
    // if (settleBankList && settleBankList.length) {
    //   setSettleBankId(settleBankList[0].key);
    // }
  }

  const setApproveReason = (reason) => {
    setValues({...values, approveReason: reason});
  }

  const approvePayment = () => {
    const validationErrors = validate(values);
    setError(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      let settleIds = [];
      let topUpSettlementIds = [];
      if (
        approveObj.settlementIds &&
        approveObj.settlementIds.length
      ) {
        settleIds = approveObj.settlementIds.map(i => Number(i));
      }
      if (
        approveObj.topUPSettlementIds &&
        approveObj.topUPSettlementIds.length
      ) {
        topUpSettlementIds = approveObj.topUPSettlementIds.map(i => Number(i));
      }
      var data = {
        "SettlementIds": settleIds,
        "SettlementAmout": Number(approveObj.amount),
        "Description": values.approveReason,
        "Status": "Approved",
        "UserId": getUserId(),
        "MerchantId": Number(approveObj.merchantId),
        'CorePaymentTransactionId': coreTransId,
        "TopUPSettlementIds": topUpSettlementIds,
        "TopUpAmount": approveObj.topUpAmount ? Number(approveObj.topUpAmount) : 0
      }
      setModalLoading(true);
      let url = '/api/Settlement/SettlementApproveReject';
      postMethod(url, JSON.stringify(data), 'post')
        .then(res => {
          setModalLoading(false);
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200") {
              // Success
              notifySuccess('Approved Successfuly');
              closeApproveModal();
              fetchSettlement();
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in approving';
              closeApproveModal();
              notifyFailed(errorMsg);
              fetchSettlement();
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in approving';
              notifyFailed(errorMsg);
              closeApproveModal();
              fetchSettlement();
            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
            closeApproveModal();
            fetchSettlement();
          }
      }).catch(err => {
        setModalLoading(false);
        errorMsg = 'Something went wrong!, Please call 3737';
        notifyFailed(errorMsg);
        closeApproveModal();
        fetchSettlement();
      });
    }
  }

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.approveReason) {
      errors.approveReason = 'Approval reason is required';
    }
    if (!approveObj || !approveObj.settlementIds) {
      errors.settlementId = 'Settlement bankid not found';
    }
    if (!coreTransId) {
      errors.coreTransId = 'Please provide core banking transaction id';
    }
    return errors;
  }

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const reloadListHandle = () => {
    fetchSettlement();
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }


  return (
    <>
      <Header/>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto" onSubmit={e => { e.preventDefault(); }}>
                  
                <FormGroup>
                  <label
                    for="status"
                    className="form-control-label">
                    Status
                  </label>
                  &nbsp; &nbsp;

                  <div class="dropdown-border">
                    <Input
                      type="select"
                      id="status"
                      className="form-control-alternative status-filter"
                      name="statusFilter"
                      value={statusFilter}
                      onChange={(e) => changeStatus(e.target.value)}
                      >
                      {STATUS.map(c => 
                      <option key={c.key} value={c.key}>{c.value}</option>
                      )}
                    </Input>
                  </div>
                  
                </FormGroup>

                &nbsp;&nbsp;&nbsp;
                  
                  <FormGroup className="mb-0 my-4 navbar-search">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search" 
                        type="text" 
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip" 
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>

                </Form>

              </CardHeader>


              <Modal isOpen={openApproveModal} className="modal-md">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Approve Payment
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {approveObj ?
                      <div>
                        {/* <p className="text-left">
                          Do you want to approve {approveObj.merchantName}'s payment ?
                        </p> */}
                         <Row className="mb-3">
                          <Col>
                            <strong>Transaction Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>Merchant Name - 
                                  <strong>
                                    {approveObj.merchantName}
                                  </strong>
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Amount - {getAmt(approveObj.amount)}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Settlement Bank Account No - {approveObj.settlementAccountNo}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Merchant Bank Name - {approveObj.merchantBankName}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Merchant Account No - {approveObj.merchantAccountNo}
                                </small>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        
                        <Input
                          className="form-control-alternative"
                          id="approve-reason"
                          placeholder="Reason for approval"
                          type="text"
                          name="approveReason"
                          onChange={(e)=> setApproveReason(e.target.value)}
                        />
                        {errors.approveReason && <div className="text-left text-danger">
                          <small>* {errors.approveReason}</small>
                        </div>}
                        <Input
                          className="form-control-alternative mt-3"
                          id="approve-reason"
                          placeholder="Enter core banking transaction id"
                          type="text"
                          name="coreTransId"
                          onChange={(e) => setCoreTransId(e.target.value)}
                        />
                        {errors.coreTransId && <div className="text-left text-danger">
                          <small>* {errors.coreTransId}</small>
                        </div>}
                        <div className="text-right mt-4">
                          <Button color="primary" onClick={approvePayment} disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeApproveModal}
                            disabled={modalLoading}>No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>



              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}>
                      Merchant Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortMerchantName)}
                          onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortMerchantBankName, 'sortMerchantBankName')}>
                      Merchant Bank
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortMerchantBankName)}
                          onClick={() => sortHandle(sort.sortMerchantBankName, 'sortMerchantBankName')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortMerchantAccountNo, 'sortMerchantAccountNo')}>
                      Merchant BBAN
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortMerchantAccountNo)}
                          onClick={() => sortHandle(sort.sortMerchantAccountNo, 'sortMerchantAccountNo')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}>
                      Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortAmount)}
                          onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortCommissionAmount, 'sortCommissionAmount')}>
                      Fees
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCommissionAmount)}
                          onClick={() => sortHandle(sort.sortCommissionAmount, 'sortCommissionAmount')}
                        />
                      </button>
                    </th>
                    <th scope="status">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i) => {
                  return (
                    <tr>
                      <th scope="row">
                        {mmo.merchantName}
                      </th>
                      <td>{mmo.merchantBankName}</td>
                      <td>{mmo.merchantAccountNo}</td>
                      <td>{getAmt(mmo.amount)}</td>
                      <td>{getAmt(mmo.commissionAmount)}</td>
                      <td>{statusFormat(mmo.status)}</td>
                      <td>
                        { 
                          mmo.status == "Pending" &&
                            <Button
                              color="primary"
                              onClick={() => approve(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip" 
                              title="Send to Merchant">
                              <i class="fas fa-money-check-alt fa-lg" 
                                style={{color: "#008000"}}></i>
                            </Button>
                          } 
                      </td>
                    </tr>

                  )
                }
                )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
              {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )

}

export default SettlementList;