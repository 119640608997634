import { call, put, takeLatest } from 'redux-saga/effects';
// import request from 'utils/request';
import { API_ENDPOINTS } from '../../appConfig';
import { getMethod, getUserId, postMethod } from '../../services/httpServices';
import { PAYMENT_FECTH_LIST, COLLECTION_BANK_LIST } from './constant';
import { 
  paymentList, setPaymentList, paymentListError, resetPaymentList,
  cBankList, setCbankList, cBankListError, resetCbankList
} from './action';

export function* fetchPaymentList(data) {
  var errorMsg = '';
  try {
    const userId = getUserId();
    const res = yield postMethod('/api/Transactions/BankTransactionForMasterAgent/', data.payload, 'post');
    if (res.data.responseCode === "200" && res.data.result) {
      // Success
      yield put(setPaymentList(res.data.result));
    } else if (res.data.responseCode === "400") {
      // Error
      errorMsg = res.data.responseMessage || 'Error in fetching bank transactions';
      yield put(paymentListError(errorMsg));
      yield put(resetPaymentList());
    } else {
      // Unknown Error
      errorMsg = 'Unknown Error in fetching bank transactions';
      yield put(paymentListError(errorMsg));
      yield put(resetPaymentList());
    }
  } catch (err) {
    errorMsg = 'Something went wrong!, Please call 3737';
    yield put(paymentListError(errorMsg));
    yield put(resetPaymentList());
  }
}

export function* fetchCBankList() {
  var errorMsg = '';
  try {
    const res = yield getMethod('/api/bankProfile/ddllist?Accounttype=Collection', 'get');
    if (res.data.responseCode === "200") {
      // Success
      yield put(setCbankList(res.data.result));
    } else if (res.data.responseCode === "400") {
      // Error
      errorMsg = res.data.responseMessage || 'Error in fetching colection bank';
      yield put(cBankListError(errorMsg));
      yield put(resetCbankList());
    } else {
      // Unknown Error
      errorMsg = 'Unknown Error in fetching bank transactions';
      yield put(cBankListError(errorMsg));
      yield put(resetCbankList());
    }
  } catch (err) {
    errorMsg = 'Something went wrong!, Please call 3737';
    yield put(cBankListError(errorMsg));
    yield put(resetCbankList());
  }
}

export default function* bankSaga() {
  yield takeLatest(PAYMENT_FECTH_LIST, fetchPaymentList);
  yield takeLatest(COLLECTION_BANK_LIST, fetchCBankList);
}
