import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import { checkSpace, getUserId, postMethod } from "../../services/httpServices";
import { NFC_OPTION, GET_YEAR_LIST } from "appConfig";
import { checkMacAddress } from "services/util";
import { setPosTerminalvalues } from "services/pos-terminal-service";

const NFC = NFC_OPTION && NFC_OPTION.length ? NFC_OPTION : [];

const EditPOS = (props) => {
  const initialValue = setPosTerminalvalues(props);

  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const changeState = (obj) => {
    setValues(obj);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeModal = () => {
    props.closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.deviceName) {
      errors.deviceName = "Device name is required";
    }
    if (!inputs.make) {
      errors.make = "Make is required";
    }
    if (!inputs.model) {
      errors.model = "Model is required";
    }
    if (!inputs.manufactureYear) {
      errors.manufactureYear = "Manufacture year is required";
    }
    if (!inputs.serialNo) {
      errors.serialNo = "Serial No is required";
    }
    if (!inputs.macAddress) {
      errors.macAddress = "MAC Address is required";
    } else if (!checkMacAddress(inputs.macAddress)) {
      errors.macAddress = "Please enter valid MAC Address";
    }
    if (!inputs.nfc) {
      errors.nfc = "Nfc option is required";
    }
    if (!inputs.chip) {
      errors.chip = "Chip option is required";
    }
    if (!inputs.magStrip) {
      errors.magStrip = "Mag Strip option is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      var data = {};
      data["posDeviceName"] = values.deviceName;
      data["posMake"] = values.make;
      data["posId"] = values.posId;
      data["posModel"] = values.model;
      data["posManufactureYear"] = values.manufactureYear;
      data["posSerialNumber"] = values.serialNo;
      data["posMacAddress"] = values.macAddress;
      data["isNFCEnabled"] = values.nfc === "Enable";
      data["isMagStripeEnabled"] = values.magStrip === "Enable";
      data["isChipEnabled"] = values.chip === "Enable";
      data["isDeleted"] = values.isDeleted;
      data["isBlocked"] = values.isBlocked;
      data["createdAt"] = values.createdAt;
      data["createdBy"] = values.createdBy;
      data["updatedAt"] = new Date().toISOString();
      data["updatedBy"] = getUserId();

      let errorMsg = "";
      setLoading(true);
      postMethod("/api/POS/Update", data, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfuly");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  return (
    <>
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">POS Terminal</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-make"
                          >
                            Device Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-make"
                            placeholder="Make"
                            type="text"
                            name="make"
                            value={values.deviceName}
                          />
                          {errors.deviceName && (
                            <div className="text-left text-danger">
                              <small>* {errors.deviceName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-make"
                          >
                            Make
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-make"
                            placeholder="Make"
                            type="text"
                            name="make"
                            value={values.make}
                            onChange={handleInputChange}
                          />
                          {errors.make && (
                            <div className="text-left text-danger">
                              <small>* {errors.make}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-model"
                          >
                            Model
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-model"
                            placeholder="Model"
                            type="text"
                            name="model"
                            value={values.model}
                            onChange={handleInputChange}
                          />
                          {errors.model && (
                            <div className="text-left text-danger">
                              <small>* {errors.model}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-manufacture-year"
                          >
                            Manufacture Year
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-manufacture-year"
                            placeholder="Manufacture Year"
                            type="select"
                            name="manufactureYear"
                            value={values.manufactureYear}
                            onChange={handleInputChange}
                          >
                            {GET_YEAR_LIST()}
                          </Input>
                          {errors.manufactureYear && (
                            <div className="text-left text-danger">
                              <small>* {errors.manufactureYear}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-serialNo"
                          >
                            Serial No
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-serialNo"
                            placeholder="Serial No"
                            type="text"
                            name="serialNo"
                            value={values.serialNo}
                            onChange={handleInputChange}
                          />
                          {errors.serialNo && (
                            <div className="text-left text-danger">
                              <small>* {errors.serialNo}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-macAddress"
                          >
                            MAC Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-macAddress"
                            placeholder="eg- 00:0a:95:9d:68:16"
                            type="text"
                            name="macAddress"
                            value={values.macAddress}
                            onChange={handleInputChange}
                          />
                          {errors.macAddress && (
                            <div className="text-left text-danger">
                              <small>* {errors.macAddress}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label for="input-nfc" className="form-control-label">
                            NFC
                          </label>
                          <Input
                            type="select"
                            id="input-nfc"
                            className="form-control-alternative"
                            name="nfc"
                            value={values.nfc}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select NFC Option -------
                            </option>
                            {NFC.map((c) => (
                              <option key={c.key}>{c.value}</option>
                            ))}
                          </Input>
                          {errors.nfc && (
                            <div className="text-left text-danger">
                              <small>* {errors.nfc}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label for="input-nfc" className="form-control-label">
                            Chip
                          </label>
                          <Input
                            type="select"
                            id="input-nfc"
                            className="form-control-alternative"
                            name="chip"
                            value={values.chip}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select NFC Option -------
                            </option>
                            {NFC.map((c) => (
                              <option key={c.key}>{c.value}</option>
                            ))}
                          </Input>
                          {errors.chip && (
                            <div className="text-left text-danger">
                              <small>* {errors.chip}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label for="input-nfc" className="form-control-label">
                            Mag Strip
                          </label>
                          <Input
                            type="select"
                            id="input-nfc"
                            className="form-control-alternative"
                            name="magStrip"
                            onChange={handleInputChange}
                            value={values.magStrip}
                          >
                            <option key="" value="">
                              -----Select NFC Option -------
                            </option>
                            {NFC.map((c) => (
                              <option key={c.key}>{c.value}</option>
                            ))}
                          </Input>
                          {errors.magStrip && (
                            <div className="text-left text-danger">
                              <small>* {errors.magStrip}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg-12>
                      <div>
                        <Button
                          className="btn float-right"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Submit{" "}
                          {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditPOS;
