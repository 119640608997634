import React, { useState } from "react";
import {
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Card,
  Container,
  CardBody,
  TabPane,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import Header from "components/Headers/Header.js";
import { knowledgeBaseItems } from "appConfig";
import Overview from "./Overview";
import TechnicalBackground from "./TechnicalBackground";
import SubscriberJourney from "./SubscriberJourney";
import MasterAgentJourney from "./MasterAgentJourney";
import SuperAgentJourney from "./SuperAgentJourney";
import SubAgentJourney from "./SubAgentJourney";
import MerchantJourney from "./MerchantJourney";
import BankUserJourney from "./BankUserJourney";
import LegalInformation from "./LegalInformation";
import ContactUs from "./ContactUs";

const cardHeaderCls = {
  backgroundColor: "#6490CA",
};
const All_TABS = [
  Overview,
  TechnicalBackground,
  SubscriberJourney,
  MasterAgentJourney,
  SuperAgentJourney,
  SubAgentJourney,
  MerchantJourney,
  BankUserJourney,
  LegalInformation,
  ContactUs,
];
const AddSubscriber = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabName, setActiveTabName] = useState(
    knowledgeBaseItems[0].label
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getActiveTabContent = (tab) => {
    const CurrentTab = All_TABS[tab - 1];
    return <CurrentTab />;
  };

  return (
    <div style={{ position: "relative" }}>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <CardBody>
            <Nav
              tabs
              style={{
                flexWrap: "nowrap",
                overflowY: "hidden",
              }}
            >
              {knowledgeBaseItems.map((item, idx) => (
                <NavItem key={item.label}>
                  <NavLink
                    className={classnames({ active: activeTab === idx + 1 })}
                    onClick={() => {
                      setActiveTabName(item.label);
                      toggle(idx + 1);
                    }}
                    style={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {item.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <div className="card-border shadow">
                  <Card>
                    <CardHeader style={cardHeaderCls}>
                      <Row className="align-items-center">
                        <Col lg="12">
                          <h3 className="mb-0 text-center text-white">
                            {activeTabName}
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>{getActiveTabContent(activeTab)}</CardBody>
                  </Card>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AddSubscriber;
