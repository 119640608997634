import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/app.css";
import "assets/css/app.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { ToastProvider } from 'react-toast-notifications';
import { getLoggedIn } from "./services/httpServices";
import routes from "routes.js";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";

const validLayouts = [
  "/admin", "/bank", "/subscriber", "/finance",
  "/settlementBank", "/merchant", "/super-agent", "/sub-agent"];
 
const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (validLayouts.indexOf(prop.layout) > -1) {
      return (
        <Route
          path={prop.path}
          render={(prop) => getLoggedIn() ? <AdminLayout {...prop} /> :
          <Redirect from="/" to="/auth/login" />}
          key={key}
        />
      )
    } else {
      return null
    }
  });
}

ReactDOM.render(
  <div>
    <Provider store={configureStore()}>
      <ToastProvider>
        <BrowserRouter>
          <Switch>
            {/* <Route path="/admin" render={(props) =>
              getLoggedIn() ? <AdminLayout {...props} /> :
                <Redirect from="/" to="/auth/login" />
            } /> */}
            {getRoutes(routes)}
            <Route path="*" render={(props) => <AuthLayout {...props} />} />
            {/* <Redirect from="/" to="/auth/login" /> */}
          </Switch>
        </BrowserRouter>
      </ToastProvider>
    </Provider>
  </div>,
  document.getElementById("root")
);
