import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal, ModalBody
} from "reactstrap";
import { setUserDetails, getMobCodes } from "services/util";
import { checkSpace } from '../../services/httpServices';
import PreviewSubscriber from '../preview-subscriber';
import { useHistory } from "react-router";


const MMODetails = (props) => {
  const MMO = props.mmo ? props.mmo : [];
  const codes = getMobCodes();
  const initialValue = setUserDetails(props);
  const [mobileMoney, setMMO] = useState(MMO);
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const history = useHistory();

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    // value = value.trim();
    if (checkSpace(value)) {
      if (name === 'mobileMoneyAccNo') {
        if (checkMobileMoneyAccNo(value)) {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const checkMobileMoneyAccNo = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 11) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const goBack = () => {
    props.goBack();
  }

  const validateMM = (e) => {
    e.preventDefault();
    if (checkMMTab()) {
      props.parentCallback(values);
      setOpen(true);
    }
  }

  const checkMMTab = () => {
    let error = {};
    let valid = true;
    
    if (values.mobileMoney) {
      if (!values.mobileMoneyAccNo) {
        error.mobileMoneyAccNo = 'Mobile Money Account Number is Required';
        valid = false;
      }
      // if (values.mobileMoneyAccNo.length !== 11) {
      //   error.mobileMoneyAccNo = 'Mobile Money Account Number should have 11 digits';
      //   valid = false;
      // }
    } else if (values.mobileMoneyAccNo) {
      if (!values.mobileMoney) {
        error.mobileMoney = 'Mobile Money Account is Required';
        valid = false;
      }
      // if (values.mobileMoneyAccNo.length !== 11) {
      //   error.mobileMoneyAccNo = 'Mobile Money Account Number should have 11 digits';
      //   valid = false;
      // }
    }

    // if (!values.mobileMoney) {
    //   error.mobileMoney = 'Mobile Money Account is Required';
    //   valid = false;
    // }
    // if (!values.mobileMoneyAccNo) {
    //   error.mobileMoneyAccNo = 'Mobile Money Account Number is Required';
    //   valid = false;
    // }
    // if (values.mobileMoneyAccNo.length !== 11) {
    //   error.mobileMoneyAccNo = 'Mobile Money Account Number should have 11 digits';
    //   valid = false;
    // }
    setErrors(error);
    return valid;
  }

  const closeModal = () => {
    setOpen(false);
    // history.push('/auth/login');
    history.push({
      pathname: '/show-msg',
      state: {
        error: false, 
        msg: `You've been successfully registered on Smartpay`,
        login: true
      }
    });
  }

  const reviewModal = () => {
    setOpen(false);
  }

  return (
    <>
      <Modal
          isOpen={open}
          style={{ width: "70vw", maxWidth: "70vw" }}
      >
        <ModalBody style={{ padding: 0 }}>
          <PreviewSubscriber
            closeModal={closeModal}
            reviewModal={reviewModal}
            {...values}
            bankList={props.banks}
            mobileMoneyList={props.mmo}
            selfRegistered={true}
          />
        </ModalBody>
      </Modal>
      <Form onSubmit={validateMM} autoComplete="off">
        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                for="input-mobilemoney"
                className="form-control-label">
                Mobile Money (optional)
              </label>
              <Input
                type="select"
                id="input-mobilemoney"
                className="form-control-alternative"
                name="mobileMoney"
                value={values.mobileMoney}
                onChange={handleInputChange}
              >
                <option key="" value="">----Select MMO-----</option>
                {mobileMoney && mobileMoney.map(c => <option key={c.key} value={c.key}>
                  {c.value}</option>)}
              </Input>
              {errors.mobileMoney && <div className="text-left text-danger">
                <small>* {errors.mobileMoney}</small>
              </div>}
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-mmaccno"
              >
                MM Account Number (optional)
              </label>
              <Input
                className="form-control-alternative"
                id="input-mmaccno"
                placeholder="Account Number (optional)"
                type="text"
                name="mobileMoneyAccNo"
                value={values.mobileMoneyAccNo}
                onChange={handleInputChange}
              />
              {errors.mobileMoneyAccNo && <div className="text-left text-danger">
                <small>* {errors.mobileMoneyAccNo}</small>
              </div>}
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg-6>
            <div>
              <Button className="sign-up-btn float-left" color="primary"
                onClick={goBack}>
                <i class="fas fa-arrow-alt-circle-left" style={{ fontSize: '18px' }}></i> Back
              </Button>
            </div>
          </Col>
          
          <Col lg="6">
            <div>
              <Button className="sign-up-btn float-right"
                color="primary" type="submit">
                Save and Preview
              </Button>
            </div>
          </Col>
        </Row>

      </Form>
    </>
  )
}
export default MMODetails;