import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import { checkSpace, postMethod } from "../../services/httpServices";
import { NFC_OPTION, GET_YEAR_LIST } from "appConfig";
import { checkMacAddress } from "services/util";

const initialValue = {
  posDeviceName: "",
  posMake: "",
  posModel: "",
  posManufactureYear: "",
  posSerialNumber: "",
  posMacAddress: "",
  isNFCEnabled: null,
  isMagStripeEnabled: null,
  isChipEnabled: null,
};

const NFC = NFC_OPTION && NFC_OPTION.length ? NFC_OPTION : [];

const AddPOS = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();

  const changeState = (obj) => {
    setValues(obj);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeModal = () => {
    props.closeModal();
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.posDeviceName) {
      errors.posDeviceName = "Device name is required";
    }
    if (!inputs.posMake) {
      errors.posMake = "POS Make is required";
    }
    if (!inputs.posModel) {
      errors.posModel = "POS Model is required";
    }
    if (!inputs.posManufactureYear) {
      errors.posManufactureYear = "Manufacture year is required";
    }
    if (!inputs.posSerialNumber) {
      errors.posSerialNumber = "Serial No is required";
    }
    // if (!inputs.posMacAddress) {
    //   errors.posMacAddress = "MAC Address is required";
    // } else if (!checkMacAddress(inputs.posMacAddress)) {
    //   errors.posMacAddress = "Please enter valid MAC Address";
    // }
    if (!inputs.isNFCEnabled) {
      errors.isNFCEnabled = "Nfc option is required";
    }
    if (!inputs.isMagStripeEnabled) {
      errors.isMagStripeEnabled = "Mag Stripe option is required";
    }
    if (!inputs.isChipEnabled) {
      errors.isChipEnabled = "Chip option is required";
    }

    return errors;
  };

  /**======================== API Calls ==========================*/

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      var data = {};
      data["posDeviceName"] = values.posDeviceName;
      data["posMake"] = values.posMake;
      data["posModel"] = values.posModel;
      data["posManufactureYear"] = values.posManufactureYear;
      data["posSerialNumber"] = values.posSerialNumber;
      data["posMacAddress"] = values.posMacAddress;
      data["isNFCEnabled"] = values.isNFCEnabled === "Enable";
      data["isMagStripeEnabled"] = values.isMagStripeEnabled === "Enable";
      data["isChipEnabled"] = values.isChipEnabled === "Enable";

      let errorMsg = "";
      setLoading(true);
      postMethod("/api/POS/Register", data, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfuly");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  return (
    <>
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">POS Terminal</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-make"
                          >
                            Device Name * 
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-make"
                            placeholder="Device name"
                            type="text"
                            name="posDeviceName"
                            value={values.posDeviceName}
                            onChange={handleInputChange}
                          />
                          {errors.posDeviceName && (
                            <div className="text-left text-danger">
                              <small>* {errors.posDeviceName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-make"
                          >
                            Make *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-posMake"
                            placeholder="Make"
                            type="text"
                            name="posMake"
                            value={values.posMake}
                            onChange={handleInputChange}
                          />
                          {errors.posMake && (
                            <div className="text-left text-danger">
                              <small>* {errors.posMake}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-model"
                          >
                            Model *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-posModel"
                            placeholder="Model"
                            type="text"
                            name="posModel"
                            value={values.posModel}
                            onChange={handleInputChange}
                          />
                          {errors.posModel && (
                            <div className="text-left text-danger">
                              <small>* {errors.posModel}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-manufacture-year"
                          >
                            Manufacture Year *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-manufacture-year"
                            placeholder="Manufacture Year"
                            type="select"
                            name="posManufactureYear"
                            value={values.posManufactureYear}
                            onChange={handleInputChange}
                          >
                            {GET_YEAR_LIST()}
                          </Input>
                          {errors.posManufactureYear && (
                            <div className="text-left text-danger">
                              <small>* {errors.posManufactureYear}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-serialNo"
                          >
                            Serial No *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-posSerialNumber"
                            placeholder="Serial No"
                            type="text"
                            name="posSerialNumber"
                            value={values.posSerialNumber}
                            onChange={handleInputChange}
                          />
                          {errors.posSerialNumber && (
                            <div className="text-left text-danger">
                              <small>* {errors.posSerialNumber}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-macAddress"
                          >
                            MAC Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-posMacAddress"
                            placeholder="eg- 00:0a:95:9d:68:16"
                            type="text"
                            name="posMacAddress"
                            value={values.posMacAddress}
                            onChange={handleInputChange}
                          />
                          {errors.posMacAddress && (
                            <div className="text-left text-danger">
                              <small>* {errors.posMacAddress}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label for="input-nfc" className="form-control-label">
                            NFC *
                          </label>
                          <Input
                            type="select"
                            id="input-isNFCEnabled"
                            className="form-control-alternative"
                            name="isNFCEnabled"
                            value={values.isNFCEnabled}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select NFC Option -------
                            </option>
                            {NFC.map((c) => (
                              <option key={c.key}>{c.value}</option>
                            ))}
                          </Input>
                          {errors.isNFCEnabled && (
                            <div className="text-left text-danger">
                              <small>* {errors.isNFCEnabled}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-isMagStripeEnabled"
                            className="form-control-label"
                          >
                            Mag Stripe *
                          </label>
                          <Input
                            type="select"
                            id="input-isMagStripeEnabled"
                            className="form-control-alternative"
                            name="isMagStripeEnabled"
                            value={values.isMagStripeEnabled}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select NFC Option -------
                            </option>
                            {NFC.map((c) => (
                              <option key={c.key}>{c.value}</option>
                            ))}
                          </Input>
                          {errors.isMagStripeEnabled && (
                            <div className="text-left text-danger">
                              <small>* {errors.isMagStripeEnabled}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-isChipEnabled"
                            className="form-control-label"
                          >
                            Chip *
                          </label>
                          <Input
                            type="select"
                            id="input-isChipEnabled"
                            className="form-control-alternative"
                            name="isChipEnabled"
                            value={values.isChipEnabled}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select NFC Option -------
                            </option>
                            {NFC.map((c) => (
                              <option key={c.key}>{c.value}</option>
                            ))}
                          </Input>
                          {errors.isChipEnabled && (
                            <div className="text-left text-danger">
                              <small>* {errors.isChipEnabled}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col lg-12>
                      <div>
                        <Button
                          className="btn float-right"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Submit{" "}
                          {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddPOS;
