import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardSubtitle,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useToasts } from "react-toast-notifications";
import { checkSpace, postMethod, getUserId } from "services/httpServices";
import {
  fetchSubs,
  fetchMerchant,
  setMerchant,
  fetchPayableAmt,
} from "./action";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import swal from "sweetalert";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { getUserEmail } from "services/httpServices";
import PaySmartBills from "components/makePaymentScreens/pay-smart-bills";
import PayUniversityFees from "components/makePaymentScreens/pay-university-fees";
import classNames from "classnames";
import EDSAPayment from "../sub-agent/EDSAPayment";
import SmartKorporPaymentTab from "../sub-agent/SmartKorporPaymentTab";
import { getMethod } from "services/httpServices";

const merchantDummy = [
  { name: "Meter Online", uniqueLabel: "Meter No.", id: "1" },
  { name: "E-Registry", uniqueLabel: "File No.", id: "2" },
];

const AMT = 6000000;

const initialValue = {
  merchantId: "",
  label: "",
  uniqueNo: "",
  amount: AMT,
  merchantName: "",
  accountType: "",
  otp: "",
  merchantProfileId: "",
  password: "",
};

const initialValue1 = {
  refNo: "",
  amount: null,
  currency: "",
  customername: "",
  customer_account_number: "",
  pin: "",
};

const initialValue2 = {
  amount: "",
  billNumber: "",
  pin: "",
};

const billTypes = [
  {
    id: 1,
    name: "Pay Smartbills",
    color: "#2b3b84",
  },
  {
    id: 2,
    name: "Pay University fees",
    color: "#2b3b84",
  },
  {
    id: 3,
    name: "Pay Other Services",
    color: "#2b3b84",
  },
];

const billSubTypes = [
  {
    id: 1,
    name: "EDSA",
    color: "#566e8e",
  },
  {
    id: 2,
    name: "NRA",
    color: "#566e8e",
  },
  {
    id: 3,
    name: "ITS",
    color: "#566e8e",
  },
  {
    id: 4,
    name: "Any other service",
    color: "#566e8e",
  },
];

const MakePayment = (props) => {
  const [values, setValues] = useState(initialValue);
  const [merchants, setMerchants] = useState([]);
  const [subsInfo, setSubsInfo] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [form2, setForrm2] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [use1FA, set1FA] = React.useState(false);
  const [use2FA, set2FA] = React.useState(false);
  const [showPswd, setShowPswd] = React.useState(false);
  const [form3, setForm3] = React.useState(false);
  const [currentScreenName, setcurrentScreenName] = React.useState("");
  const [currentSubScreenName, setcurrentSubScreenName] = React.useState("");

  const [form, setForm] = React.useState(1);
  const [fees, setFees] = React.useState(0);
  const [currentBillScreen, setCurrentBillScreen] = useState(0);

  const [currentSubBillScreen, setCurrentSubBillScreen] = useState(0);
  const { addToast } = useToasts();
  const history = useHistory();

  //SmartKorpor
  const [values1, setValues1] = useState(initialValue1);
  const [errors1, setErrors1] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [formState1, setFormState1] = useState(1);
  const [userResponse1, setUserResponse1] = useState({});

  //EDSA
  const [values2, setValues2] = useState(initialValue2);
  const [errors2, setErrors2] = useState({});
  const [loading2, setLoading2] = useState(false);
  const [formState2, setFormState2] = useState(1);

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      setValues2({ ...values2, [name]: value });
    }
  };

  const validateEDSA = (inputs) => {
    let errors = {};

    if (!inputs.billNumber) {
      errors.billNumber = "This field is required";
    }
    if (!inputs.amount) {
      errors.amount = "This field is required";
    }
    return errors;
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const validationErrors = validateEDSA(values2);
    setErrors2(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError && formState2 === 1) {
      initiatePaymentEDSA();
    }
  };
  const initiatePaymentEDSA = () => {
    var errorMsg = "";
    const { amount, billNumber, pin } = values2;
    var data = {
      amount: Number(amount),
      billNumber,
      pin,
    };

    setLoading2(true);
    let url = "/api/Transactions/InitiatePaymentSubscriberEDSA";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading2(false);
        if (res.data?.responseCode === "400") {
          notifyFailed(res?.data?.responseMessage);
          return;
        }
        if (res.data) {
          notifySuccess(String(res.data));
          setValues2(initialValue2);
          setFormState2(1);
          setLoading2(false);
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading2(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading2(false);
      });
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const validationErrors = validate1(values1);
    setErrors1(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError && formState1 === 1) {
      validateSmartKorpor();
    } else if (isNoError && formState1 === 2) {
      initiatePaymentSmartKorpor();
    }
  };
  const initiatePaymentSmartKorpor = () => {
    var errorMsg = "";
    const {
      amount,
      refNo,
      currency,
      customername,
      customer_account_number,
      pin,
    } = values1;
    var data = {
      amount: Number(amount),
      refno: refNo,
      currency,
      customername,
      customer_account_number,
      pin,
    };

    setLoading1(true);
    let url = "/api/Transactions/InitiatePaymentSmartKorporSubscriber";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading1(false);
        if (res.data?.responseCode === "400") {
          notifyFailed(res?.data?.responseMessage);
          return;
        }
        if (res.data) {
          notifySuccess(String(res.data));
          setValues1(initialValue);
          setFormState1(1);
          setLoading1(false);
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading1(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading1(false);
      });
  };

  const validateSmartKorpor = () => {
    var errorMsg = "";
    const { refNo } = values1;

    setLoading1(true);
    let url = "/api/Transactions/SmartKorporFetchInvoice/?invoiceNo=" + refNo;
    getMethod(url, "get")
      .then((res) => {
        setLoading1(false);

        if (res.data?.status === "error") {
          notifyFailed(res.data?.message);
        }
        if (res.data) {
          if (res?.data?.data.payment_status === "settled") {
            notifyFailed("Bill/Invoice already paid");
            setLoading1(false);
            setValues1(initialValue1);
            setFormState1(1);
          } else {
            setUserResponse1(res?.data?.data);
            setValues1({
              ...values1,
              currency: res.data?.data?.currency,
              customername: res.data?.data?.name,
              amount: res.data?.data?.amount,
            });
            setFormState1(2);
            if (res.data?.desc) notifySuccess(res.data?.desc);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading1(false);
        }
      })
      .catch((err) => {
        errorMsg = "Check you referance/Bill/Invoice number";
        notifyFailed(errorMsg);
        setLoading1(false);
      });
  };

  const validate1 = (inputs) => {
    let errors = {};

    if (!inputs.refNo) {
      errors.refNo = "This field is required";
    }

    if (formState1 === 2) {
      if (!inputs.customer_account_number) {
        errors.customer_account_number = "This field is required";
      }
      if (!inputs.pin) {
        errors.pin = "This field is required";
      }
      if (!inputs.currency) {
        errors.currency = "This field is required";
      }
    }
    return errors;
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      setValues1({ ...values1, [name]: value });
    }
  };

  /**======================== React Hooks ==========================*/

  useEffect(() => {
    // props.fetchSubsInfo();
    props.fetchMerchantInfo();
  }, []);

  useEffect(() => {
    if (props.subscriber.makePaymentInfo.error) {
      notifyFailed(props.subscriber.makePaymentInfo.errorMsg);
    }
  }, [props.subscriber.makePaymentInfo.error]);

  useEffect(() => {
    if (
      props.subscriber.makePaymentInfo.list &&
      props.subscriber.makePaymentInfo.list.length > 0
    ) {
      const list = props.subscriber.makePaymentInfo.list;
      setMerchants(list);
      setValues({
        ...values,
        // merchantName: list && list[0] ? list[0].name : '',
        // merchantId: list && list[0] ? list[0].id : '',
        // label: list && list[0] ? list[0].labelKey : '',
        // merchantProfileId: list && list[0] ? list[0].userProfileId : ''

        merchantName: "",
        merchantId: "",
        label: "",
        merchantProfileId: "",
      });
    }
  }, [props.subscriber.makePaymentInfo.list]);

  /** Fetch Amount states */

  useEffect(() => {
    if (props.subscriber.amountInfo.error) {
      notifyFailed(props.subscriber.amountInfo.errorMsg);
      setLoading(false);
    }
  }, [props.subscriber.amountInfo.error]);

  useEffect(() => {
    if (props.subscriber.amountInfo.success) {
      setValues({ ...values, amount: props.subscriber.amountInfo.data.amount });

      setLoading(false);
      setForm(2);
    }
  }, [props.subscriber.amountInfo.success]);

  useEffect(() => {
    if (use2FA) {
      genOtp();
    }
  }, [use2FA]);

  useEffect(() => {
    if (props.subscriber.amountInfo.loading) {
      setLoading(props.subscriber.amountInfo.loading);
    }
  }, [props.subscriber.amountInfo.loading]);

  /**======================== File Functions ==========================*/

  const getLabel = (id) => {
    let merchant = merchants.find((m) => m.id == id);
    if (merchant) {
      return {
        label: merchant.labelKey,
        name: merchant.name,
        userProfileId: merchant.userProfileId,
      };
    } else {
      return {};
    }
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const changeLabel = (e) => {
    const { name, value } = e.target;
    const v = getLabel(value);
    if (name === "merchantId") {
      setValues({
        ...values,
        [name]: value,
        label: v.label,
        merchantName: v.name,
        uniqueNo: "",
        merchantProfileId: v.userProfileId,
      });
    }
  };

  const goBack = () => {
    set1FA(false);
    set2FA(false);
    setForm(1);
    setValues({ ...values, accountType: "", otp: "", password: "" });
  };

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons: {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: "sweet-btn",
        },
      },
    }).then((confirm) => {
      if (confirm) {
        setForm(3);
      } else {
        setForm(3);
      }
    });
  };

  const getAmt = () => {
    let amt = Number(values.amount);
    return amt.toLocaleString();
  };

  const resendOtp = (e) => {
    e.preventDefault();
    setValues({ ...values, otp: "" });
    setSpinner(true);
    genOtp();
  };

  const checkForm = () => {
    if (form == 2 || form == 3) {
      return true;
    } else {
      return false;
    }
  };

  const checkFees = () => {
    if (values.accountType == "Bank") {
      return true;
    } else {
      return false;
    }
  };

  /**======================== Form Validation function ==========================*/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === "password") {
        if (pinValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "otp") {
        if (checkOTP(value)) {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const validate = (inputs) => {
    let errors = {};

    if (!inputs.merchantId) {
      errors.merchantId = "This field is required";
    }
    if (!inputs.uniqueNo) {
      errors.uniqueNo = "This field is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      const data = {
        MerchantUserId: values.merchantProfileId,
        ProductId: values.uniqueNo.trim(),
      };
      props.fetchAmt(data);
    } else {
      setForrm2(false);
    }
  };

  const validate2 = function (inputs) {
    var errors = {};
    if (!inputs.accountType) {
      errors.accountType = "Please select one account";
    }
    if (!inputs.amount) {
      errors.amount = "Amount is required";
    }
    if (!subsInfo.id) {
      errors.id = "Subscriber is required";
    }
    if (use2FA && !inputs.otp) {
      errors.otp = "Please enter OTP";
    }

    if (!inputs.password) {
      errors.password = "Please enter password";
    }
    return errors;
  };

  const handleSubmitV2 = (e) => {
    e.preventDefault();
    const validationErrors = validate2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      // verifyOtp();
      saveTrans();
    }
  };

  const validate3 = (inputs) => {
    var errors = {};
    if (!inputs.amount) {
      errors.amount = "Amount is required";
    }
    // if (!subsInfo.id) {
    //   errors.id = 'Subscriber is required';
    // }
    if (!inputs.password) {
      errors.password = "Please enter pin";
    } else if (inputs.password.length != 4) {
      errors.password = "PIN should contain 4-digits";
    }
    return errors;
  };

  const validatePin = (e) => {
    e.preventDefault();
    const validationErrors = validate3(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      // call an api to verify pin
      verifyPin();

      //for testing
      // setForm(3);
    }
  };

  const proceed = (e) => {
    e.preventDefault();
    if (use2FA) {
      // call genOtp()
      setLoading(true);
      genOtp();
    } else {
      setForm(3);
    }
  };

  /**======================== API Calls ==========================*/

  const verifyOtp = () => {
    var errorMsg = "";
    var data = {
      UserId: getUserId(),
      OtpType: "TransactionOTP",
      Password: values.password,
    };

    if (use2FA) {
      data["Otp"] = values.otp;
    }
    setLoading(true);
    let url = "/api/Transactions/CheckOTPorPin";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        if (res.data) {
          if (res.data.responseCode === "200") {
            saveTrans();
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in generating otp";
            notifyFailed(errorMsg);
            setLoading(false);
          } else {
            errorMsg = "Unknown error in generating otp";
            notifyFailed(errorMsg);
            setLoading(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading(false);
      });
  };

  const saveTrans = () => {
    setLoading(true);
    if (values.accountType === "Wallet") {
      walletPayment();
    } else {
      var data = new FormData();
      data.append("SubscriberUserId", getUserId());
      data.append("Amount", values.amount);
      data.append("PaymentMethod", values.accountType);
      data.append("PaymentType", "Merchant");
      data.append("PaymentTo", values.merchantName);
      data.append("AmountType", "P");
      data.append("MerchantUserId", values.merchantProfileId);
      data.append("ProductId", values.uniqueNo.trim());
      data.append("PIN", values.password);
      data.append("CollectionType", "BankPayment");
      if (use2FA) {
        data.append("Otp", values.otp);
      }
      if (values.accountType === "Bank") {
        data.append("PaymentFrom", subsInfo.bankName);
        data.append("PaymentMethodId", subsInfo.bankProfileId);
      } else if (values.accountType === "MMO") {
        data.append("PaymentFrom", subsInfo.mmoName);
        data.append("PaymentMethodId", subsInfo.mmoId);
      }
      data.append("FeesAmount", fees);
      let errorMsg = "";
      setLoading(true);
      postMethod("/api/Transactions/Save", data, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              // updateMerchant(res.data.result);
              if (subsInfo.isBankApiAvailable) {
                updateMerchant(res.data.result.transactionId, "Paid");
              } else {
                updateMerchant(res.data.result.transactionId, "In Process");
                // notifySuccess('Payment added');
                // history.push('/subscriber-transaction');
              }
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  const walletPayment = () => {
    var data = {
      MerchantUserId: values.merchantProfileId,
      // SubscriberUserId: getUserId(),
      Amount: values.amount,
      ProductId: values.uniqueNo.trim(),
      PIN: values.password,
      CollectionType: "WalletPayment",
      // FeesAmount: fees
      // Otp: values.otp
    };
    if (use2FA) {
      data["Otp"] = values.otp;
    }
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/Transactions/voucherpayment", data, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // notifySuccess('Payment added');
            // history.push('/subscriber-transaction');
            updateMerchant(res.data.result.transactionId, "Paid");
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  const genOtp = () => {
    var errorMsg = "";
    var data = JSON.stringify({
      // "UserId": getUserId(),
      OtpType: "TransactionOTP",
    });
    // setLoading(true);
    let url = "/api/Auth/Sendotp";
    postMethod(url, data, "post")
      .then((res) => {
        setLoading(false);
        setSpinner(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || "Otp sent";
            // notifySuccess(msg);
            openModal(msg);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in generating otp";
            notifyFailed(errorMsg);
          } else {
            errorMsg = "Unknown error in generating otp";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setSpinner(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  const updateMerchant = (transId, status) => {
    var data = JSON.stringify({
      MerchantId: Number(values.merchantId),
      ProductId: values.uniqueNo.trim(),
      Amount: values.amount,
      TransactRefId: transId.toString(),
      Status: status,
    });
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/Transactions/PaymentUpdateTOMerchant", data, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess("Payment added");
            history.push("/subscriber-transaction");
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  const verifyPin = () => {
    var errorMsg = "";
    var data = {
      PaymentAmount: values.amount,
      Password: values.password,
      Email: getUserEmail(),
      PaymentType: "Bank",
    };

    setLoading(true);
    let url = "/api/Subscriber/GetSubscriberDetails";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        if (res.data) {
          if (res.data.responseCode === "200") {
            let result = res.data.result;
            setSubsInfo(result);
            if (!isNaN(result.fees)) {
              setFees(Number(result.fees));
            }
            if (result.authFactorType == "1FA") {
              set1FA(true);
              set2FA(false);
              setLoading(false);
              setForm(3);
            } else if (result.authFactorType == "2FA") {
              set1FA(false);
              set2FA(true);
            } else {
              set1FA(false);
              set2FA(false);
              setLoading(false);
              setForm(3);
            }
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in validating pin";
            notifyFailed(errorMsg);
            setLoading(false);
          } else {
            errorMsg = "Unknown error in validating pin";
            notifyFailed(errorMsg);
            setLoading(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading(false);
      });
  };

  const verifyPinV2 = () => {
    var errorMsg = "";
    var data = {
      PaymentAmount: Number(values.amount),
      Password: values.password,
      Email: getUserEmail(),
      PaymentType: "Bank",
    };

    setLoading(true);
    let url = "/api/Subscriber/GetSubscriberDetails";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        if (res.data) {
          if (res.data.responseCode === "200") {
            let result = res.data.result;
            setSubsInfo(result);
            if (!isNaN(result.fees)) {
              setFees(Number(result.fees));
            }
            if (result.authFactorType == "1FA") {
              set1FA(true);
              set2FA(false);
            } else if (result.authFactorType == "2FA") {
              set1FA(false);
              set2FA(true);
            } else {
              set1FA(false);
              set2FA(false);
            }
            verifyPinHandle(result);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in validating pin";
            notifyFailed(errorMsg);
            setLoading(false);
          } else {
            errorMsg = "Unknown error in validating pin";
            notifyFailed(errorMsg);
            setLoading(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading(false);
      });
  };

  const verifyPinHandle = (result) => {
    if (result.fees == 0) {
      if (result.authFactorType == "2FA") {
        setLoading(false);
        genOtp();
      } else {
        setForm(4);
      }
    } else {
      setLoading(false);
      setForm(3);
    }
  };

  // const onClickBillSelectBtn = (item) => {
  //   setCurrentBillScreen(item.id);
  //   setcurrentScreenName(item.name)
  // };
  const onClickBillSelectBtn = (billType, subBillType) => {
    if (billType.id === 3 && subBillType) {
      // Handle the selection of "Pay Other Services" with a sub-bill type
      // Perform the necessary actions based on the selected bill and sub-bill types
    } else {
      // Handle the selection of other bill types without sub-bill types
      setCurrentBillScreen(billType.id);
      setcurrentScreenName(billType.name);
    }
  }

  const onClickSubBillSelectBtn = (subBillType) => {
    setCurrentSubBillScreen(subBillType.id);
    setcurrentSubScreenName(subBillType.name);

  }

  const renderSubBillScreen = (scrennNum) => {
    switch (scrennNum) {
      case 1:
        return (
          <EDSAPayment
          values2={values2}
          handleSubmit2={handleSubmit2}
          handleInputChange2={handleInputChange2}
          formState2={formState2}
          errors2={errors2}
          setValues2={setValues2}
          initialValue2={initialValue2}
          loading2={loading2}
          setFormState2={setFormState2}
          />
        );
      case 2:
        return (
          <SmartKorporPaymentTab
            values1={values1}
            handleSubmit1={handleSubmit1}
            handleInputChange1={handleInputChange1}
            formState1={formState1}
            errors1={errors1}
            userResponse1={userResponse1}
            setValues1={setValues1}
            initialValue1={initialValue1}
            loading1={loading1}
            setFormState1={setFormState1}
          />
        ); 
        case 3:
          return (
            <SmartKorporPaymentTab
              values1={values1}
              handleSubmit1={handleSubmit1}
              handleInputChange1={handleInputChange1}
              formState1={formState1}
              errors1={errors1}
              userResponse1={userResponse1}
              setValues1={setValues1}
              initialValue1={initialValue1}
              loading1={loading1}
              setFormState1={setFormState1}
            />
          );
   
        case 4:
          return (
            <SmartKorporPaymentTab
              values1={values1}
              handleSubmit1={handleSubmit1}
              handleInputChange1={handleInputChange1}
              formState1={formState1}
              errors1={errors1}
              userResponse1={userResponse1}
              setValues1={setValues1}
              initialValue1={initialValue1}
              loading1={loading1}
              setFormState1={setFormState1}
            />
          );
        default:
          break;
      }
    };

  const renderBillScreen = (scrennNum) => {
    switch (scrennNum) {
      case 1:
        return (
          <PaySmartBills
            handleSubmit={handleSubmit}
            merchants={merchants}
            changeLabel={changeLabel}
            errors={errors}
            values={values}
            form={form}
            handleInputChange={handleInputChange}
            loading={loading}
            checkForm={checkForm()}
            checkFees={checkFees}
            validatePin={validatePin}
            getAmt={getAmt}
            fees={fees}
            showPswd={showPswd}
            setShowPswd={setShowPswd}
            goBack={goBack}
            handleSubmitV2={handleSubmitV2}
            use2FA={use2FA}
            resendOtp={resendOtp}
            spinner={spinner}
            subsInfo={subsInfo}
            makePaymentInfoLoading={props.subscriber.makePaymentInfo.loading}
          />
        );
      case 2:
        return <PayUniversityFees />;
      case 3:
        return (
          <>
            <Card
              className="bg-secondary"
              style={{ minWidth: "300px" }}
            >
              <CardBody style={{ display: "flex", justifyContent: "center" }}>
                {currentSubBillScreen ? (
                  <div style={{ width: "100%",alignItems: "center" }}>
                  <span>&nbsp;
                  {currentSubBillScreen !== 0 && (
                      <span onClick={() => setCurrentSubBillScreen(0)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="#000"
                          className="bi bi-arrow-left-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                          />
                        </svg> &nbsp;
                      </span>
                    )}{currentSubScreenName}</span>
                     
                    
                    
                  
                  {renderSubBillScreen(currentSubBillScreen)}
                </div>
                ) : (
                  <div
                    className="flex"
                    style={{
                      display: "flex",
                      gap: "14px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {billSubTypes.map((item) => (
                      <Card
                        key={item.id}
                        style={{
                          width: "12rem",
                          height: "12.5rem",
                        }}
                      >
                        <CardHeader
                          inverse
                          style={{
                            backgroundColor: item.color,
                            borderColor: item.color,
                            color: "#FFFFFF",
                          }}
                        >
                          {item.name}
                        </CardHeader>
                        <CardBody style={{ justifyContent: "center" }}>
                          <Button
                            onClick={() => onClickSubBillSelectBtn(item)}
                          >
                            Select
                          </Button>
                        </CardBody>
                      </Card>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          </>
        );
 
      default:
        break;
    }
  };

  return (
    <>
      <Header />

      <Container className="mt--6 pb-5">
        <Row className="justify-content-center">
          <Col xs="10">
            <Card
              className="bg-secondary shadow border-0"
              style={{ minWidth: "300px" }}
            >
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    {currentBillScreen !== 0 && (
                      <div onClick={() => {setCurrentBillScreen(0); setCurrentSubBillScreen(0)}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="#F5F5F5"
                          class="bi bi-arrow-left-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                          />
                        </svg>
                      </div>
                    )}
                    <h3 className="mb-0 text-center ml-4">Make Payment</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ display: "flex", justifyContent: "center" }}>
                {currentBillScreen ? (
                  <div style={{ width: "100%" }}>
                    <h2>{currentScreenName}</h2>
                    {renderBillScreen(currentBillScreen)}
                  </div>
                ) : (
                  <div
                    className="flex"
                    style={{
                      display: "flex",
                      gap: "14px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {billTypes.map((item) => (
                      <Card
                        key={item.id}
                        style={{
                          width: "12rem",
                          height: "12.5rem",
                        }}
                      >
                        <CardHeader
                          inverse
                          style={{
                            backgroundColor: item.color,
                            borderColor: item.color,
                            color: "#FFFFFF",
                          }}
                        >
                          {item.name}
                        </CardHeader>
                        <CardBody style={{ justifyContent: "center" }}>
                          <Button
                            onClick={() => onClickBillSelectBtn(item, null)}
                          >
                            Select
                          </Button>
                        </CardBody>
                      </Card>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubsInfo: () => dispatch(fetchSubs()),
    fetchMerchantInfo: () => dispatch(fetchMerchant()),
    fetchAmt: (data) => dispatch(fetchPayableAmt(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MakePayment);
