/* eslint-disable react/no-unescaped-entities */

import SP1Img from "../../assets/kb/SP1.png";
import SP2Img from "../../assets/kb/SP2.png";
import SP3Img from "../../assets/kb/SP3.png";
import SP4Img from "../../assets/kb/SP4.png";
import SP5Img from "../../assets/kb/SP5.png";
import SuperAgentImg from "../../assets/kb/SuperAgent.png";

const SuperAgentJourney = () => {
  return (
    <div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl  mb-2">Introduction</h4>
        <p>
          Welcome to the Super Agent's role within the SmartPay platform. As a
          Super Agent, you play a pivotal role in managing and facilitating
          financial activities. This guide will provide you with an overview of
          your responsibilities and capabilities.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Registration and Access
        </h4>
        <p>
          As a Super Agent, your account is registered and authorized by the
          Super Admin. Once registered, you gain access to a range of
          functionalities that allow you to oversee financial transactions.
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={SP1Img} alt="transfer " className="img-fluid rounded" />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Create Sub Agents
        </h4>
        <p>
          One of your key roles as a Super Agent is the ability to create Sub
          Agents under your supervision. This process involves:
        </p>
        <ol className="list-decimal w-full my-2">
          <li>Initiating the creation of a Sub Agent from your dashboard.</li>
          <li>Providing necessary details for the Sub Agent account.</li>
          <li>Verifying and confirming the creation.</li>
          <li>
            The newly created Sub Agent will be under your management.
            <div className="d-flex justify-content-center my-3">
              <img src={SP2Img} alt="transfer " className="img-fluid rounded" />
            </div>
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Transfer to Sub Agents
        </h4>
        <p>
          As a Super Agent, you have the authority to transfer funds to Sub
          Agents when required. This enables you to support and facilitate their
          financial operations.
        </p>
        <div className="d-flex justify-content-center my-3">
          <img
            src={SP3Img}
            alt="Transfer to Sub Agents "
            className="img-fluid rounded"
          />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Transaction Monitoring
        </h4>
        <p>
          Your Super Agent account allows you to monitor all transactions taking
          place within your network. This includes transactions initiated by Sub
          Agents and other users.
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={SP4Img} alt="Monitoring" className="img-fluid rounded" />
        </div>
      </div>
      <div>
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Dashboard Overview
        </h4>
        <p>
          Your dashboard provides a comprehensive view of your financial
          operations:
        </p>
        <ul className="list-disc w-full my-2">
          <li>Wallet Balance: Keep track of the balance in your own wallet.</li>
          <li>
            Commission: Stay informed about the commissions associated with
            transactions.
          </li>
          <li>
            Sub Agent Balances: Access the balances of Sub Agents under your
            management.
            <div className="d-flex justify-content-center my-3">
              <img src={SP5Img} alt="Insights" className="img-fluid rounded" />
            </div>
          </li>
        </ul>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Transfer to Created Sub Agents
        </h4>
        <p>
          It's important to note that you can transfer funds exclusively to Sub
          Agents you have created. This ensures controlled financial management
          within your network.
        </p>
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
        Super Agent Flowchart
      </h4>
      <div className="d-flex justify-content-center my-3">
        <img
          src={SuperAgentImg}
          alt="Super Agent Flowchart"
          className="w-100"
        />
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
        Conclusion
      </h4>
      <p>
        As a Super Agent, you play a crucial role in the SmartPay financial
        ecosystem. Your responsibilities encompass creating and managing Sub
        Agents, overseeing transactions, and facilitating transfers. Utilizing
        your dashboard and provided functionalities, you contribute to the
        efficient functioning of the platform.
      </p>
    </div>
  );
};

export default SuperAgentJourney;
