import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { getMethod } from "../../services/httpServices";
import "react-phone-input-2/lib/style.css";
import { checkName } from "services/util";
import GoogleMaps from "components/GoogleMaps";

const initialValue = {
  locationId: 0,
  merchantId: 0,
  district: "",
  chiefdomOrZone: "",
  gpsCoordinate: "",
};

const ViewMerchantLocation = (props) => {
  const [value, setValue] = useState(props?.values ?? initialValue);
  const [loading, setLoading] = useState(false);
  const [allMerchants, setAllMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(1);
  const [showMap, setShowMap] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    fetchAllMerchant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllMerchant = () => {
    if (allMerchants.length !== 0) return;
    setLoading(true);
    getMethod("/api/MerchantProfile/all", "get")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setAllMerchants(res.data.result);
        }
      })
      .catch(() => {
        notifyFailed("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (e, idx) => {
    const { name, value: currentValue } = e.target;
    if (checkName(value)) {
      setValue({ ...value, [name]: currentValue });
    }
  };

  const closeModal = () => {
    props.closeModal();
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Merchant Location Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          for="input-roleId"
                          className="form-control-label"
                        >
                          Merchant
                        </label>
                        <Input
                          type="select"
                          id="input-roleId"
                          disabled
                          className="form-control-alternative"
                          onChange={(e) => setSelectedMerchant(e.target.value)}
                          value={value.merchantId}
                        >
                          {/* {roles.map(c => <option key={c.key}>{c.value}</option>)} */}
                          <option key="" value="">
                            ------- Select -------
                          </option>
                          {allMerchants?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  {selectedMerchant && (
                    <div>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-district"
                            >
                              District
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-district"
                              placeholder="District"
                              type="text"
                              name="district"
                              value={value.district}
                              disabled
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              Chief dom Or Zone
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-chiefdomOrZone"
                              placeholder="Chief dom Or Zone"
                              type="text"
                              name="chiefdomOrZone"
                              disabled
                              value={value.chiefdomOrZone}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              GPS Coordinates
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-gpsCoordinate"
                              placeholder="Lat, Lng Ex: 1.11, 2.22"
                              type="text"
                              name="gpsCoordinate"
                              disabled
                              value={value.gpsCoordinate}
                              onChange={handleInputChange}
                            />
                            <Button
                              color="primary"
                              className="mt-2"
                              type="button"
                              onClick={() => setShowMap(!showMap)}
                              disabled={loading}
                            >
                              Open Map
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={showMap}
          size="lg"
          style={{ width: "70vw", maxWidth: "70vw" }}
        >
          <ModalBody>
            <div>
              <GoogleMaps disable={true} position={value?.gpsCoordinate} />
              <Button
                color="primary"
                className="mt-2 float-right"
                type="button"
                onClick={() => setShowMap(false)}
              >
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default ViewMerchantLocation;
