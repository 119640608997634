/* eslint-disable react/no-unescaped-entities */

import M1Img from "../../assets/kb/M1.png";
import M2Img from "../../assets/kb/M2.png";
import M3Img from "../../assets/kb/M3.png";
import M4Img from "../../assets/kb/M4.png";
import MasterAgentFlowImg from "../../assets/kb/MasterAgentFlow.png";

const MasterAgentJourney = () => {
  return (
    <div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl  mb-2">Introduction</h4>
        <p>
          Welcome to the Master Agent's role in the SmartPay ecosystem. As a
          Master Agent, you play a vital role in managing and overseeing
          transactions within the platform. This guide will walk you through
          your responsibilities and capabilities.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Registration and Access
        </h4>
        <p>
          As a Master Agent, your account is registered by the Super Admin. Once
          registered, you'll gain access to a range of functionalities to
          facilitate and monitor financial activities.
        </p>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Top-Up Balance
        </h4>
        <p>
          Your Master Agent account enables you to top up your own wallet
          balance using an associated bank account. The process involves the
          following steps:
        </p>
        <ol className="list-decimal w-full my-2">
          <li>
            Wallet Balance: Monitor the balance in your own wallet, reflecting
            your available funds.
          </li>
          <li>
            Commission: Stay updated on the commissions associated with
            transactions.
          </li>
          <li>
            Super Agent Balances: Access the balances of Super Agents in your
            network.
          </li>
          <li>
            Sub Agent Balances: Review the balances of Sub Agents linked to your
            operations.
            <div className="d-flex justify-content-center my-3">
              <img src={M2Img} alt="transfer " className="img-fluid rounded" />
            </div>
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Transfer to Super Agents
        </h4>
        <p>
          One of your key responsibilities is facilitating financial
          transactions between Master Agents and Super Agents. You can initiate
          money transfers to Super Agent wallets using the SmartPay platform.
        </p>
        <div className="d-flex justify-content-center my-3">
          <img
            src={M3Img}
            alt="Transfer to Super Agents
            "
            className="img-fluid rounded"
          />
        </div>
      </div>
      <div className="mb-3">
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Transaction Monitoring
        </h4>
        <p>
          Your Master Agent account allows you to keep track of all transactions
          that take place within the platform. This includes transactions
          initiated by Super Agents, Sub Agents, and other users. CC
        </p>
        <div className="d-flex justify-content-center my-3">
          <img src={M4Img} alt="Monitoring" className="img-fluid rounded" />
        </div>
      </div>
      <div>
        <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
          Dashboard Overview
        </h4>
        <p>
          Your dashboard provides a comprehensive view of your financial
          operations:
        </p>
        <ul className="list-disc w-full my-2">
          <li>Wallet Balance: Keep track of the balance in your own wallet.</li>
          <li>
            Commission: Stay informed about the commissions associated with
            transactions.
          </li>
          <li>
            Sub Agent Balances: Access the balances of Sub Agents under your
            management.
            <div className="d-flex justify-content-center my-3">
              <img src={M1Img} alt="Insights" className="img-fluid rounded" />
            </div>
          </li>
        </ul>
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
        Master Agent Flowchart
      </h4>
      <div className="d-flex justify-content-center my-3">
        <img
          src={MasterAgentFlowImg}
          alt="Master Agent Flowchart"
          className="w-100"
        />
      </div>
      <h4 className="font-bold md:text-xl text-bluePrimary  mb-2">
        Conclusion
      </h4>
      <p>
        As a Master Agent, you play a pivotal role in the SmartPay financial
        ecosystem. Your responsibilities include managing transactions,
        facilitating transfers, and monitoring financial activities. By
        utilizing your dashboard and the functionalities provided, you
        contribute to the seamless functioning of the platform.
      </p>
    </div>
  );
};

export default MasterAgentJourney;
