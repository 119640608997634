import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavbarBrand,
} from "reactstrap";

import {
  loginMethod,
  setLoggedIn,
  setLoginType,
  setUserEmail,
  setUserName,
  setUserB64,
  setUserId,
  setPswd,
} from "../../services/httpServices";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import jwt_decode from "jwt-decode";
import { fetchSubs } from "../subscriber/action";
import { connect } from "react-redux";
import { setUserImage } from "./action";
import PhoneInput from "react-phone-input-2";

const Login = (props) => {
  const initialValue = {
    email: null,
    pswd: null,
  };

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState(initialValue);
  const [showPswd, setShowPswd] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loginOption, setLoginOption] = React.useState(""); // Initial option: 'mobile'

  const { addToast } = useToasts();
  const history = useHistory();

  const handleLoginOptionChange = (e) => {
    setValues({ ...values, email: "" });
    setLoginOption(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validate = (inputs) => {
    let errors = {};
    let digiRegex = /^[0-9]*$/;
    if (!inputs.email) {
      errors.email = "Email address / mobile number is required";
    } else if (
      isNaN(inputs.email) &&
      !/\S+@\S+\.\S+/.test(inputs.email) &&
      !digiRegex.test(inputs.email)
    ) {
      errors.email = "* Email address / mobile number is invalid";
    }
    // else if (digiRegex.test(inputs.email) && inputs.email.length !== 11) {
    //   errors.email = ' * Mobile number should contain 11 digits';
    // }
    if (!inputs.pswd) {
      errors.pswd = "* Password is required";
    }
    return errors;
  };

  const notify = (text, type) => {
    if (!text) {
      text = "Save successfully";
    }
    if (!type) {
      type = "success";
    }
    addToast(text, {
      appearance: type,
      autoDismiss: true,
    });
  };

  const forgotPswd = (e) => {
    e.preventDefault();
    history.push("/forgot-password");
  };

  const privacyPolicy = (e) => {
    e.preventDefault();
    history.push("/privacy-policy");
  };

  const signUp = (e) => {
    e.preventDefault();
    history.push("/sign-up");
  };

  useEffect(() => {
    window.localStorage.setItem("validUser", false);
  }, []);

  const handleSubmit = (event) => {
    let errorMsg = "";
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const noErrors = Object.values(validationErrors).every((x) => x === "");
    setErrors(validationErrors);
    const noDashboardUsers = [
      "Sales Supervisor",
      "Sales Representative",
      "Operation supervisor",
      "Operations Officer",
      "Audits and Compliance",
      "Customer Support Supervisor",
      "Customer Support Representative",
    ];
    if (noErrors) {
      setLoggedIn(false);
      let url = "/api/Auth/Login";
      if (loginOption === "") {
        notify("Please select the login option Mobile/Email", "error");
        return;
      }

      let data = {
        Email: values.email.trim(),
        Password: values.pswd,
      };
      setLoading(true);
      loginMethod(url, data, "post")
        .then((res) => {
          setLoading(false);
          if (res.data) {
            if (res.data.responseCode === "200") {
              if (res.data.result) {
                window.localStorage.setItem("token", res.data.result);
                window.localStorage.setItem("validUser", true);
                // setPswd(values.pswd);
                var decoded = jwt_decode(res.data.result);
                if (decoded && decoded.UserProfile) {
                  var userProfile = JSON.parse(decoded.UserProfile);
                  if (userProfile.hasOwnProperty("UserRole")) {
                    setLoginType(userProfile.UserRole);
                    // if (userProfile.UserRole === "Subscriber") {
                    //   fetchSubs();
                    // }
                  }

                  if (
                    userProfile.hasOwnProperty("UserName") &&
                    userProfile.UserName.length
                  ) {
                    setUserName(userProfile.UserName);
                  }

                  // if (
                  //   userProfile.hasOwnProperty('UserProfileImage') &&
                  //   userProfile.UserProfileImage &&
                  //   userProfile.UserProfileImage.length
                  // ) {
                  //   setUserB64(userProfile.UserProfileImage);
                  // } else {
                  //   setUserB64(null);
                  // }

                  if (
                    userProfile.hasOwnProperty("UserID") &&
                    userProfile.UserID.length
                  ) {
                    setUserId(userProfile.UserID);
                  }
                }
                setUserEmail(values.email);
                setLoggedIn(true);
                fetchUserInfo(userProfile.UserID);
                if (noDashboardUsers.includes(userProfile.UserRole)) {
                  history.push("/user-list");
                } else {
                  history.push("/index");
                }
              } else {
                errorMsg = "Token not found";
                notify(errorMsg, "error");
              }
            } else if (res.data.responseCode === "400") {
              if (
                res.data.responseMessage ===
                "first time login found so check email/SMS and use OTP to set your PIN"
              ) {
                history.push("/forgot-password", {
                  otpSent: true,
                  data,
                });
              }
              errorMsg = res.data.responseMessage || "Invalid credentials";
              notify(errorMsg, "error");
            } else {
              errorMsg = "Error in login";
              notify(errorMsg, "error");
            }
          } else {
            errorMsg = "Unknown Error";
            notify(errorMsg, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notify(errorMsg, "error");
        });
    } else {
    }
  };

  const fetchSubs = () => {
    props.fetchSubsInfo();
  };

  const fetchUserInfo = (id) => {
    let errorMsg = "";
    // var id = 'c8a24293-7b55-4f6c-a930-5431947c25cd';
    loginMethod("/api/User/profileImage/" + id, {}, "get")
      .then((res) => {
        if (res && res.data && res.data.length) {
          setUserB64(res.data);
          props.setImg(true);
        } else {
          setUserB64(null);
          props.setImg(false);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values,
        email: value,
      });
    }
    return;
  };

  return (
    <Row className="w-100">
      <Col>
        <Card className="card-stats ">
          <CardBody className=" d-flex w-100">
            <div className="px-lg-5 py-lg-5  w-100 d-md-flex d-none justify-content-center align-items-center border-right">
              {/* <div className="text-center text-white mb-2">
              <small>Sign in with credentials</small>
            </div> */}{" "}
              {/* <img
              alt="..."
              src={
                require("../../assets/img/brand/voucher-white.png").default
              }
              style={{width: '160px', height: '40px'}}
            /> */}
              <img
                alt="..."
                src={require("../../assets/img/brand/branchGif.gif").default}
                style={{ width: "50%", height: "50%" }}
              />
            </div>
            <div className="px-lg-5 py-lg-5 w-100 w-md-50">
              {/* <div className="text-center text-white mb-2">
              <small>Sign in with credentials</small>
            </div> */}
              <Form role="form" onSubmit={handleSubmit} className="w-100">
                <div className="w-100">
                  <h1 className="text-center mb-4">Login &nbsp;</h1>

                  <FormGroup className="mb-3 mt-3">
                    <p className="text-left font-weight-500 mb-2">
                      Login using
                    </p>

                    <label htmlFor="mobileOption">
                      <input
                        type="radio"
                        id="mobileOption"
                        name="mobileOption"
                        value="mobile"
                        checked={loginOption === "mobile"}
                        onChange={handleLoginOptionChange}
                      />
                      &nbsp;Mobile number &nbsp;{" "}
                    </label>

                    <label htmlFor="emailOption">
                      <input
                        type="radio"
                        id="emailOption"
                        name="emailOption"
                        value="email"
                        className="ml-2"
                        checked={loginOption === "email"}
                        onChange={handleLoginOptionChange}
                      />
                      &nbsp;Email
                    </label>
                  </FormGroup>
                </div>
                <FormGroup className="mb-3 mt-3">
                  {loginOption === "mobile" ? (
                    <PhoneInput
                      className="form-control-alternative"
                      country={"sl"}
                      enableSearch
                      value={values.phone}
                      countryCodeEditable={false}
                      onChange={phoneChange}
                    />
                  ) : (
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        name="email"
                        value={values.email}
                        onChange={handleInputChange}
                        disabled={loginOption === ""}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  )}

                  {errors.email && (
                    <div className="text-center text-danger mb-1">
                      <small>{errors.email}</small>
                    </div>
                  )}
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Password"
                      type={showPswd ? "text" : "password"}
                      autoComplete="new-password"
                      name="pswd"
                      value={values.pswd}
                      disabled={loginOption === ""}
                      onChange={handleInputChange}
                    />
                    {!showPswd && (
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="fa fa-eye"
                            onClick={() => setShowPswd(true)}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    )}

                    {showPswd && (
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="fa fa-eye-slash"
                            onClick={() => setShowPswd(false)}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                  {errors.pswd && (
                    <div className="text-center text-danger mb-1">
                      <small>{errors.pswd}</small>
                    </div>
                  )}
                  <br />
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4 text-white w-100 sign-up-btn mt--3"
                    type="submit"
                    disabled={loading}
                  >
                    Sign In {loading && <i class="fa fa-spinner fa-spin"></i>}
                  </Button>
                </div>

                <Row>
                  {/* <Col lg-6 class="text-left"> */}
                  {/* <small>
                    <a
                      className="fill-dark-green link-cls"
                      onClick={(e) => signUp(e)}
                      id="tag"
                      href="#"
                    >
                      <strong>Sign up</strong>
                    </a> */}
                  {/* </small> */}
                  {/* </Col> */}

                  <Col lg="12" className="text-center w-100">
                    {/* <a
                        className="fill-dark-green d-block link-cls float-right"
                        onClick={(e) => forgotPswd(e)}
                        id="tag"
                        href="#"
                      >
                        <strong>Forgot Password ?</strong>
                      </a> */}
                    <small className="d-flex justify-content-center align-items-center">
                      <a
                        className="fill-dark-green d-block link-cls"
                        onClick={(e) => forgotPswd(e)}
                        id="tag"
                        href="#"
                        style={{
                          textWrap: "nowrap",
                        }}
                      >
                        <strong>Forgot Password ? |</strong>
                      </a>
                      <div
                        style={{
                          border: "1px solid #ddd",
                          width: "2px",
                          height: "2px",
                          margin: "0px 4px",
                        }}
                      ></div>
                      <a
                        className="fill-dark-green link-cls"
                        onClick={(e) => privacyPolicy(e)}
                        id="tag"
                        href="#"
                        style={{
                          textWrap: "nowrap",
                        }}
                      >
                        <strong>Privacy Policy</strong>
                      </a>
                    </small>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubsInfo: () => dispatch(fetchSubs()),
    setImg: (data) => dispatch(setUserImage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
