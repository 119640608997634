import React, { useEffect, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import { loginMethod } from "../../services/httpServices";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

var FormData = require("form-data");

const ForgotPassword = (props) => {
  const initialValue = {
    email: null,
    pswd: null,
    otp: null,
    newpswd: null,
    confirmpswd: null,
  };

  const history = useHistory();
  const location = useLocation();

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState(initialValue);
  const [showPswd, setShowPswd] = React.useState(false);
  const [showOtp, setOtpCard] = React.useState(location?.state?.otpSent);
  const [showPswdCard, setPswdCard] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showNewPswd, setShowNewPswd] = React.useState(false);
  const [showConfirmPswd, setShowConfirmPswd] = React.useState(false);
  const [showEmailCard, setEmailCard] = React.useState(
    !location?.state?.otpSent
  );
  const { addToast } = useToasts();

  const [minutes, setMinutes] = useState(10);
  const [seconds, setSeconds] = useState(0);
  const [isTimerStart, setIsTimerStart] = useState(location?.state?.otpSent);
  const timerRef = useRef(null);

  useEffect(() => {
    if (location?.state?.otpSent) {
      setValues({ ...values, email: location.state.data.Email });
    }
  }, [location?.state?.otpSent]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "otp") {
      if (checkOtp(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === "email") {
      setValues({ ...values, [name]: value.trim() });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.email) {
      errors.email = "Email or Mobile Number is Required";
    }
    // else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
    //   errors.email = 'Email address is invalid';
    // }
    return errors;
  };

  const notify = (text, type) => {
    if (!text) {
      text = "Save successfully";
    }
    if (!type) {
      type = "success";
    }
    addToast(text, {
      appearance: type,
      autoDismiss: true,
    });
  };

  useEffect(() => {
    if (isTimerStart) {
      timerRef.current = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          setIsTimerStart(false);
          setMinutes(0);
          setSeconds(0);
        } else if (seconds === 0) {
          setSeconds(59);
          setMinutes((mins) => mins - 1);
        } else {
          setSeconds((s) => s - 1);
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isTimerStart, seconds, minutes]);

  const handleSubmit = (event) => {
    let errorMsg = "";
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const noErrors = Object.values(validationErrors).every((x) => x === "");
    setErrors(validationErrors);
    if (noErrors) {
      let email = values.email.trim();
      let url = "/api/auth/sendotp/" + email;
      let data = {};
      setLoading(true);
      loginMethod(url, data, "post")
        .then((res) => {
          setLoading(false);
          if (res.data) {
            if (res.data.responseCode === "200") {
              setOtpCard(true);
              setIsTimerStart(true);
              setEmailCard(false);
            } else if (res.data.responseCode === "400") {
              errorMsg = res.data.responseMessage || "Invalid credentials";
              notify(errorMsg, "error");
            } else {
              errorMsg = "Error in login";
              notify(errorMsg, "error");
            }
          } else {
            errorMsg = "Unknown Error";
            notify(errorMsg, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notify(errorMsg, "error");
        });
    } else {
    }
  };

  const otpValidate = (inputs) => {
    let errors = {};
    if (!inputs.email) {
      errors.email = "Email or Mobile Number is required";
    }
    // else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
    //   errors.email = 'Email address is invalid';
    // }
    if (!inputs.otp) {
      errors.otp = "Otp is required";
    } else if (inputs.otp.length !== 6) {
      errors.otp = "Otp should have 6 digits";
    }
    return errors;
  };

  const checkOtp = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const otpSubmit = (event) => {
    let errorMsg = "";
    event.preventDefault();
    const validationErrors = otpValidate(values);
    setErrors(validationErrors);
    const noErrors = Object.values(validationErrors).every((x) => x === "");
    setErrors(validationErrors);
    if (noErrors) {
      let url = "/api/auth/VerifyOtp/";
      let data = new FormData();
      data.append("Email", values.email.trim());
      data.append("Otp", values.otp);
      setLoading(true);
      loginMethod(url, data, "post")
        .then((res) => {
          setLoading(false);
          if (res.data) {
            if (res.data.responseCode === "200") {
              setOtpCard(false);
              setPswdCard(true);
            } else if (res.data.responseCode === "400") {
              errorMsg = res.data.responseMessage || "Invalid Otp";
              notify(errorMsg, "error");
            } else {
              errorMsg = "Error in verifying otp";
              notify(errorMsg, "error");
            }
          } else {
            errorMsg = "Unknown Error";
            notify(errorMsg, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notify(errorMsg, "error");
        });
    } else {
    }
  };

  const pswdValidate = (inputs) => {
    let errors = {};
    if (!inputs.email) {
      errors.email = "Email or Mobile Number is Required";
    }
    // else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
    //   errors.email = 'Email address is invalid';
    // }
    if (!inputs.newpswd) {
      errors.newpswd = "Password is required";
    } else if (!inputs.confirmpswd) {
      errors.confirmpswd = "Confirm Password is required";
    } else if (inputs.newpswd !== inputs.confirmpswd) {
      errors.confirmpswd = "Confirm Password is not matched";
    }
    return errors;
  };

  const resetPswdSubmit = (event) => {
    let errorMsg = "";
    event.preventDefault();
    const validationErrors = pswdValidate(values);
    setErrors(validationErrors);
    const noErrors = Object.values(validationErrors).every((x) => x === "");
    setErrors(validationErrors);
    if (noErrors) {
      let url = "/api/auth/resetpassword";
      let data = new FormData();
      data.append("Email", values.email);
      data.append("Password", values.newpswd);
      data.append("ConfirmPassword", values.confirmpswd);
      setLoading(true);
      loginMethod(url, data, "post")
        .then((res) => {
          setLoading(false);
          if (res.data) {
            if (res.data.responseCode === "200") {
              notify("Password reset successfuly", "success");
              history.push("/login");
            } else if (res.data.responseCode === "400") {
              errorMsg =
                res.data.responseMessage || "Error in reseting password";
              notify(errorMsg, "error");
            } else {
              errorMsg = "Error while reseting otp";
              notify(errorMsg, "error");
            }
          } else {
            errorMsg = "Unknown Error";
            notify(errorMsg, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notify(errorMsg, "error");
        });
    } else {
    }
  };

  function getDigits(num) {
    if (num >= 0 && num <= 9) {
      return 0;
    }
    return null;
  }

  return (
    <Row className="w-100 d-flex justify-content-center align-content-center">
      {showEmailCard && (
        <Col lg="5" md="7">
          <Card className=" bg-white-original shadow  border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center mb-4">
                <h1 className="text-center mb-4">Forgot Password &nbsp;</h1>
              </div>
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-name">
                    Enter Email or Mobile Number
                  </label>{" "}
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Email/mobile number"
                      type="text"
                      autoComplete="new-email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  {errors.email && (
                    <div className="text-center text-danger mb-1">
                      <small>* {errors.email}</small>
                    </div>
                  )}
                </FormGroup>
                <div className="text-right">
                  <Button
                    className="my-4 text-white sign-up-btn w-100"
                    color="primary"
                    type="submit"
                  >
                    Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      )}

      {showOtp && (
        <Col lg="5" md="7">
          <Card className=" bg-white-original shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-2">
                <h3 className="dark-color">Verify Otp</h3>
              </div>
              <Form role="form" onSubmit={otpSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      id="input-otp"
                      placeholder="Enter Otp"
                      type="text"
                      name="otp"
                      value={values.otp}
                      onChange={handleInputChange}
                    />

                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i class="far fa-clock mr-1"></i>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            background: "white",
                            height: "100%",
                          }}
                        >
                          {getDigits(minutes)}
                          {minutes}:{getDigits(seconds)}
                          {seconds}
                        </span>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {errors.otp && (
                    <div className="text-center text-danger mb-1">
                      <small>* {errors.otp}</small>
                    </div>
                  )}
                  {seconds === 0 && minutes === 0 && (
                    <p
                      style={{
                        marginTop: "4px",
                        fontWeight: "normal",
                        fontSize: "14px",
                        color: "red",
                      }}
                    >
                      OTP Expired
                    </p>
                  )}
                </FormGroup>
                <div className="text-right">
                  <Button
                    className="my-4 text-white sign-up-btn"
                    color="primary"
                    type="submit"
                  >
                    Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      )}

      {showPswdCard && (
        <Col lg="5" md="7">
          <Card className=" bg-white-original shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-2">
                <h3 className="dark-color">Reset Password</h3>
              </div>
              <Form role="form" onSubmit={resetPswdSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="New Password"
                      type={showNewPswd ? "text" : "password"}
                      name="newpswd"
                      value={values.newpswd}
                      onChange={handleInputChange}
                    />
                    {!showNewPswd && (
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="fa fa-eye"
                            onClick={() => setShowNewPswd(true)}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    )}

                    {showNewPswd && (
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="fa fa-eye-slash"
                            onClick={() => setShowNewPswd(false)}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                  {errors.newpswd && (
                    <div className="text-center text-danger mb-1">
                      <small>* {errors.newpswd}</small>
                    </div>
                  )}
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Confirm Password"
                      type={showConfirmPswd ? "text" : "password"}
                      name="confirmpswd"
                      value={values.confirmpswd}
                      onChange={handleInputChange}
                    />
                    {!showConfirmPswd && (
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="fa fa-eye"
                            onClick={() => setShowConfirmPswd(true)}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    )}

                    {showConfirmPswd && (
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="fa fa-eye-slash"
                            onClick={() => setShowConfirmPswd(false)}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                  {errors.confirmpswd && (
                    <div className="text-center text-danger mb-1">
                      <small>* {errors.confirmpswd}</small>
                    </div>
                  )}
                </FormGroup>

                <div className="text-right">
                  <Button
                    className="login-btn my-4 text-white sign-up-btn"
                    color="primary"
                    type="submit"
                  >
                    Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default ForgotPassword;
