import { combineReducers } from "redux";
import { 
  makePaymentReducer,
  payableAmtReducer,
  topUpReducer,
  voucherReducer 
} from "../../views/subscriber/reducer";

export default combineReducers({ 
  subsInfo: topUpReducer, 
  voucherInfo: voucherReducer,
  makePaymentInfo: makePaymentReducer,
  amountInfo: payableAmtReducer 
});
